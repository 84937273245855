import React, { useState } from 'react';
import Button, { ButtonStyles } from 'components/shared/Button';
import Dialog from 'components/shared/Dialog';

import NumberKeyPad from 'components/shared/NumberKeyPad';
import { isCashValueValid } from 'lib/validation';

export default function CashFloatDialog({ onRequestClose, onEnterCashValue }) {
  const [cashValue, setCashValue] = useState('');

  const handleClickKeyPadNumber = value => {
    let newCashValue;
    if (value === '<') {
      newCashValue = cashValue.slice(0, -1);
    } else {
      newCashValue = cashValue + value;
    }

    setCashValue(newCashValue);
  };

  const handleClick = () => {
    onEnterCashValue(cashValue);
    onRequestClose();
  };

  return (
    <Dialog height="32rem" width="25rem" simple onRequestClose={onRequestClose}>
      <div className="bg-white relative flex flex-column items-center justify-center">
        <div className="pb3">Enter Amount</div>
        <div className="f2 pb4 gray-2">
          {cashValue ? `$${cashValue}` : '$0.00'}
        </div>
        <NumberKeyPad onClickNumber={handleClickKeyPadNumber} />
        <Button
          buttonStyle={ButtonStyles.SECONDARY}
          size="medium"
          className="f4 mt3"
          disabled={!isCashValueValid(cashValue)}
          onClick={handleClick}
        >
          Done
        </Button>
      </div>
    </Dialog>
  );
}
