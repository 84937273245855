import { useEffect, useState } from 'react';
import TapToPayIcon from 'components/shared/TapToPayIcon';
import { getConnectedReader } from 'lib/stripe';

const ConnectStatus = {
  CONNECTING: 'connecting',
  FAILED: 'failed',
  CONNECTED: 'connected',
  HIDDEN: 'hidden',
};

let lastStatus = null;

export default function StripeConnectionIndicator({ onClick }) {
  const [status, setStatus] = useState(lastStatus || ConnectStatus.CONNECTING);

  useEffect(() => {
    async function connect() {
      setStatus(ConnectStatus.CONNECTING);

      try {
        await getConnectedReader();
        setStatus(ConnectStatus.CONNECTED);

        setTimeout(() => setStatus(ConnectStatus.HIDDEN), 4000);
      } catch (e) {
        setStatus(ConnectStatus.FAILED);
      }
    }

    if (![ConnectStatus.CONNECTED, ConnectStatus.HIDDEN].includes(lastStatus)) {
      connect();
    }
  }, []);

  useEffect(() => {
    lastStatus = status;
  }, [status]);

  if (status === ConnectStatus.HIDDEN) {
    return null;
  }

  return (
    <div
      className="absolute z-4 bg-gray-9 br-100 gray-2 pa1 w8 h8 f3 left-1 bottom-1"
      onClick={onClick}
    >
      <TapToPayIcon
        className={`w-100 h-100 connecting-color-pulse--${status}`}
      />
    </div>
  );
}
