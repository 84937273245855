export default function activeRequests(state = 0, action) {
  if (action.type === 'START_REQUEST') {
    return state + 1;
  }

  if (action.type === 'FINISH_REQUEST') {
    return state - 1;
  }

  return state;
}

export function getIsLoading(state) {
  return state > 0;
}
