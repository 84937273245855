import React from 'react';
import Dialog from 'components/shared/Dialog';
import CustomerDetailsForm from 'components/customers/CustomerDetailsForm';

export default function EditDetailsDialog(props) {
  return (
    <Dialog
      height="auto"
      title="Update Customer Profile"
      onRequestClose={props.onRequestClose}
    >
      <div className="center w-100">
        <CustomerDetailsForm
          initialEmail={props.customer.email}
          initialFirstName={props.customer.first_name}
          initialLastName={props.customer.last_name}
          emailOptout={props.customer.emailOptout}
          isEmailEditable={true}
          isEmailOptOutEditable={false}
          onFormSubmit={props.onFormSubmit}
        />
      </div>
    </Dialog>
  );
}
