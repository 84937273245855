import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getQueryParam } from 'lib/url';
import { pubWithSale, pubWithReturn, Event } from 'lib/events';
import { startNewSaleWithCustomer } from 'actions/asynchronous';
import { removeReturnReceipt } from 'actions/synchronous';
import { startRequest, finishRequest } from 'actions/synchronous';
import { getReturnReceiptById } from 'reducers/returnReceipts';
import { getSaleByNumber } from 'reducers/sales';
import PageWithHeader from 'components/shared/PageWithHeader';
import IconWithLabel from 'components/shared/IconWithLabel';
import mail from 'svg/mail.svg';
import SendReceiptDialog from 'components/sales/SendReceiptDialog';
import { post } from 'lib/network';
import { retailApiUrl } from 'lib/url';
import Button, { ButtonStyles } from 'components/shared/Button';

class Success extends Component {
  saleNumber = getQueryParam('saleNumber');
  returnNumber = getQueryParam('returnNumber');
  orderId = getQueryParam('orderId');

  state = {
    showReceiptDialog: false,
  };

  componentWillUnmount() {
    this.props.removeReturnReceipt(this.returnNumber);
  }

  async componentDidMount() {
    if (this.saleNumber) {
      pubWithSale(Event.SaleComplete.PAGE_VIEW);
    } else {
      pubWithReturn(Event.ReturnComplete.PAGE_VIEW);
    }
  }

  handleStartNewSaleWithCustomer = async () => {
    pubWithReturn(Event.ReturnComplete.START_SALE);

    this.props.startRequest();
    const sale = await this.props.startNewSaleWithCustomer(this.props.email);
    this.props.finishRequest();

    if (sale) {
      this.props.history.push(
        `/storefronts/${this.props.match.params.storefrontId}/sales/${sale.number}/product`,
      );
    }
  };

  handleSendReceipt = async (email, type) => {
    this.props.startRequest();
    await post(retailApiUrl(`orders/${this.orderId}/send_receipt`), {
      email,
      type,
    });
    this.props.finishRequest();
    this.setState({
      showReceiptDialog: false,
    });
  };

  renderBody() {
    return (
      <div className="flex flex-column items-center justify-center h-100 bg-blue-2 pb9">
        <div
          className={classNames('serif white tc lh-title', {
            f2: this.props.isMobile,
            f1: !this.props.isMobile,
          })}
        >
          Thanks for shopping <br />
          at Everlane!
        </div>
        <div className="mv4">
          <img
            src={mail}
            alt="mail"
            className={classNames({
              w6: this.props.isMobile,
              w9: !this.props.isMobile,
            })}
          />
        </div>
        {this.props.email && (
          <div className="tc white">
            Your receipt has been emailed to
            <p className="ma0">{this.props.email}.</p>
          </div>
        )}

        {this.orderId && (
          <Button
            className="fr f5 mb2 ml1 mt2"
            size="medium"
            buttonStyle={ButtonStyles.QUATERNARY}
            onClick={() => this.setState({ showReceiptDialog: true })}
          >
            Send Gift Receipt
          </Button>
        )}

        {this.state.showReceiptDialog && (
          <SendReceiptDialog
            onRequestClose={() => this.setState({ showReceiptDialog: false })}
            onSendReceipt={(email, type) => {
              this.handleSendReceipt(email, type);
            }}
            type="gift"
            initialEmail={this.props.email}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <PageWithHeader
        textColor="black"
        shouldShowLogo
        onBackArrowClick={() => {
          if (this.saleNumber) {
            pubWithSale(Event.SaleComplete.BACK_TO_SALES);
          } else {
            pubWithReturn(Event.ReturnComplete.BACK_TO_RETURNS);
          }

          this.props.history.push(
            this.saleNumber
              ? `/storefronts/${this.props.match.params.storefrontId}/sales`
              : `/storefronts/${this.props.match.params.storefrontId}/returns`,
          );
        }}
        backArrowLabel={this.saleNumber ? 'Sales' : 'Returns'}
        right={
          this.returnNumber &&
          this.props.email && (
            <IconWithLabel
              color="black"
              onClick={this.handleStartNewSaleWithCustomer}
              icon="arrow-right"
              iconClassName="f4"
              labelClassName="pr1"
              label={
                this.props.isMobile ? 'Start Sale' : 'Start Sale With Customer'
              }
              className="flex-row-reverse"
            />
          )
        }
      >
        {this.renderBody()}
      </PageWithHeader>
    );
  }
}

function mapStateToProps(state) {
  const returnReceipt = getReturnReceiptById(
    state.returnReceipts,
    getQueryParam('returnNumber'),
  );

  const sale = getSaleByNumber(state.sales, getQueryParam('saleNumber'));

  return {
    isMobile: state.ui.isMobile,
    email: returnReceipt?.customer?.email || sale?.customer?.email,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    removeReturnReceipt: returnReceiptId =>
      dispatch(removeReturnReceipt(returnReceiptId)),
    startNewSaleWithCustomer: customerEmail =>
      dispatch(
        startNewSaleWithCustomer(
          ownProps.match.params.storefrontId,
          customerEmail,
        ),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Success);
