import React from 'react';
import Scrollable from 'components/shared/Scrollable';
import FormattedDateTime from 'components/shared/FormattedDateTime';
import FadeAndHighlightGroup from 'components/shared/transitions/FadeAndHighlight';
import Icon from 'components/shared/Icon';

export default function InventoryTransferItems({
  items,
  onDeleteClick,
  canDelete,
}) {
  return (
    <Scrollable>
      <ul className="list ma0 pl0">
        {items
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          .map(item => (
            <li className="bb b--gray-9 flex pa3 w-100" key={item.upc}>
              <div className="fl w-80">
                <h2 className="f3 fw5 mt0 mb2 lh-title">{item.title}</h2>

                <p className="f5 mv1 lh-solid">
                  <span className="fw5">UPC:</span> {item.upc}
                </p>
                <p className="f5 mv1 lh-solid">
                  <span className="fw5">Added At:</span> <FormattedDateTime>{item.created_at}</FormattedDateTime>
                </p>
              </div>

              <div className="fl w-20 tc flex-column flex justify-between relative">
                <FadeAndHighlightGroup
                  className="br-pill bg-gray-9 h3 absolute-center"
                  startColor="#e0e0e0"
                  highlightColor="#ff9100"
                  endColor="#e0e0e0"
                  duration="fast"
                >
                  <p
                    key={item.quantity}
                    className="f5 ma0 dib ph2 tc center self-start"
                  >
                    {item.quantity}
                  </p>
                </FadeAndHighlightGroup>

                {canDelete && (
                  <Icon
                    glyph="trash-can"
                    color="gray-6"
                    className="f3"
                    onClick={() => onDeleteClick(item.upc)}
                  />
                )}
              </div>
            </li>
          ))}
      </ul>
    </Scrollable>
  );
}
