import React from 'react';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import logo from 'svg/logo.svg';

export default function PageWithHeader({
  title,
  right,
  left,
  onBackArrowClick,
  backArrowLabel,
  children,
  color,
  textColor,
  shouldShowLogo,
}) {
  const center = shouldShowLogo ? (
    <img src={logo} alt="Welcome to Everlane" style={{ width: '140px' }} />
  ) : (
    title
  );
  const height = document.documentElement.clientHeight;

  return (
    <div className="z-4 fixed w-100 vh-100 bg-white top-0" style={{ height }}>
      <TopBar
        color={color}
        textColor={textColor}
        left={
          typeof left === 'undefined' ? (
            <IconWithLabel
              color={textColor}
              onClick={onBackArrowClick}
              icon="arrow-left"
              iconClassName="f5 pr1"
              label={backArrowLabel}
            />
          ) : (
            left
          )
        }
        center={center}
        right={right}
      />
      {children}
    </div>
  );
}
