import React from 'react';
import classNames from 'classnames';
import CreditCard from 'components/shared/CreditCard';

export default function RefundMethod({
  refundMethod,
  onClick,
  isActive,
  isRemovable,
  onRemove,
  canShowCashOption,
}) {
  const displayName =
    {
      credit_card: 'this card',
      paypal_account: 'PayPal',
      alipay_account: 'Alipay',
      apple_pay_checkout: 'Apple Pay',
      store_credit: 'Store Credit',
      catch_checkout: 'Catch',
      cash: 'Cash',
    }[refundMethod.type] || 'Payment Method';

  const content = (() => {
    if (refundMethod.type === 'credit_card') {
      return (
        <CreditCard
          type={refundMethod.type}
          fullName={refundMethod.customer_name}
          brand={refundMethod.brand}
          lastDigits={refundMethod.last_digits}
          expirationDate={refundMethod.expiration_date}
          onClick={onClick}
          selectedBorderColor="gray-2"
          isActive={isActive}
          isRemovable={isRemovable}
          onRemove={onRemove}
        />
      );
    } else {
      return (
        (canShowCashOption || refundMethod.type !== 'cash') && (
          <div
            className={classNames(
              'ba ma1 h14 flex items-center pointer justify-center',
              { 'bg-gold-9 b--gray-2 bw1': isActive },
            )}
            style={{ width: '13rem' }}
            onClick={onClick}
          >
            {displayName}
          </div>
        )
      );
    }
  })();

  return <div>{content}</div>;
}
