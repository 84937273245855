import React from 'react';
import classNames from 'classnames';

export default function TinyPill({
  children,
  color,
  textColor = 'white',
  className,
}) {
  return (
    <div
      className={classNames(
        'ph1 inline-flex items-center br-pill f6 tc',
        `bg-${color}`,
        textColor,
        className,
      )}
    >
      {children}
    </div>
  );
}
