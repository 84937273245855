import React, { Component, Fragment } from 'react';
import { shape, string } from 'prop-types';
import { connect } from 'react-redux';
import {
  clearOrderSearchResults,
  showDialog,
  hideDialog,
  showNotification,
} from 'actions/synchronous';
import { NotificationTypes } from 'reducers/notifications';
import {
  createReturnReceipt,
  createNewStorefrontTicket,
  fetchOrderSearchResults,
  addItemToReturn,
  createReturnReceiptFromOrder,
} from 'actions/asynchronous';
import { pub, pubWithReturn, Event } from 'lib/events';
import { getStorefrontById } from 'reducers/storefronts';
import BigSearchField from 'components/shared/BigSearchField';
import Header from 'containers/Header';
import Button, { ButtonStyles } from 'components/shared/Button';
import NoItemsMessage from 'components/shared/NoItemsMessage';
import StorefrontTicketCTA from 'components/shared/StorefrontTicketCTA';
import StorefrontTicketDialog from 'components/shared/StorefrontTicketDialog';
import Scrollable from 'components/shared/Scrollable';
import { staticImageUrl } from 'lib/url';
import { UPCScanner } from 'lib/keyboard';
import { handleSearchOrders } from 'lib/searchOrders';
import CustomerEmailScreen from 'components/shared/CustomerEmailScreen';

class SearchForOrders extends Component {
  constructor(props) {
    super(props);

    this.state = { isAddingCustomer: false };

    this.scanner = new UPCScanner({
      callback: scanResult => this.handleScan(scanResult),
    });
  }

  static propTypes = {
    match: shape({ params: shape({ storefrontId: string.isRequired }) }),
    storefrontName: string.isRequired,
  };

  componentDidMount() {
    this.scanner.listen();
    pub(Event.ReturnsHome.PAGE_VIEW);
  }

  componentWillUnmount() {
    this.scanner.stopListening();
  }

  async componentDidUpdate(prevProps, prevState) {
    let isTypeRma = this.state.queryType === 'rma';
    let rmaMatch = this.props.orders[0]?.return_authorizations.find(
      rma => rma.number === this.state.query,
    );
    let queryChanged = this.state.query !== prevState.query;

    if (isTypeRma && rmaMatch && queryChanged) {
      const { return_receipt } = await this.props.createReturnReceiptFromOrder(
        this.props.orders[0],
        this.state.query,
      );

      if (return_receipt) {
        this.props.history.push(`${this.props.match.url}/${return_receipt.id}`);
      }
    }
  }

  handleToggleAddCustomer = () => {
    this.setState(prevState => {
      return { isAddingCustomer: !prevState.isAddingCustomer };
    });
  };

  handleScan = async upc => {
    if (!upc || this.props.orders.length === 0) {
      return;
    }

    let orderNumber, matchingItem;
    this.props.orders.forEach(order => {
      order.items.forEach(item => {
        if (item.upc === upc) {
          matchingItem = item;
          orderNumber = order.order_number;
        }
      });
    });

    if (!matchingItem) {
      this.props.showError(
        'The scanned item was not found in this list of orders.',
      );
      return;
    }

    const { return_receipt } = await this.props.createReturnReceipt(
      orderNumber,
      { isGiftReturn: false },
    );

    if (!return_receipt) {
      return;
    }

    pub(Event.ReturnsHome.START_STANDARD_RETURN);
    this.props.history.push(`${this.props.match.url}/${return_receipt.id}`);

    const returnItem = return_receipt.items.find(
      item => item.variant_upc === upc,
    );

    if (!returnItem) {
      // item is in the returned items array
      this.props.showError('This item is not returnable.');
      return;
    }

    if (returnItem.single_return_variant && !returnItem.first_try_allowed) {
      this.props.showError(
        'This customer has already used their First Try. Please add the item manually.',
      );
      return;
    }

    this.props.addItemToReturn(returnItem, return_receipt.id).then(() =>
      pubWithReturn(Event.Return.ADD_ITEM_TO_RETURN, {
        product_id: returnItem.product_id,
      }),
    );
  };

  handleSearchForOrders = async (label, query, queryType) => {
    pub(Event.ReturnsHome.ENTER_SEARCH, { text: query });
    await this.props.fetchOrderSearchResults(query, queryType);
    await this.setState({ query, queryType, isAddingCustomer: false });
  };

  handleCreateReturnReceipt = (orderNumber, isGiftReturn) => {
    if (isGiftReturn) {
      pub(Event.ReturnsHome.START_GIFT_RETURN);
    } else {
      pub(Event.ReturnsHome.START_STANDARD_RETURN);
    }

    this.props
      .createReturnReceipt(orderNumber, { isGiftReturn })
      .then(({ return_receipt }) => {
        this.props.history.push(`${this.props.match.url}/${return_receipt.id}`);
      });
  };

  handleClearResults = () => {
    pub(Event.ReturnsHome.CLEAR_SEARCH);
    this.props.clearOrderSearchResults();
  };

  renderOrdersTable() {
    const rows = this.props.orders.map((order, index) => {
      return (
        <div className="bb b--gray-9 pa2" key={index}>
          <div className="mb1">{`Order ${order.order_number} · `}</div>
          <div className="flex flex-row flex-wrap mb1">
            {order.items.map(item => {
              const itemTitle = item.title.split(' - ');

              return (
                <div key={item.id} style={{ flex: '0 45%' }} className="ma1">
                  <img
                    alt={item.title}
                    src={staticImageUrl(item.image_url, { size: 140 })}
                  />
                  <div className="lh-title">
                    <div className="f5 pb1">{itemTitle[0]}</div>
                    <div className="gray-6 f6">{itemTitle[1]}</div>
                    <div className="gray-6 f6">Size: {item.size}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end items-center">
            <Fragment>
              <Button
                onClick={() =>
                  this.handleCreateReturnReceipt(order.order_number, false)
                }
                size="full-width"
                buttonStyle={ButtonStyles.SECONDARY}
                disabled={this.props.isLoading}
                style={{ fontSize: '1rem' }}
              >
                Start Standard Return
              </Button>
              <Button
                outlined
                onClick={() =>
                  this.handleCreateReturnReceipt(order.order_number, true)
                }
                className="ml2"
                size="full-width"
                buttonStyle={ButtonStyles.QUATERNARY}
                disabled={this.props.isLoading}
                style={{ fontSize: '1rem' }}
              >
                Start Gift Return
              </Button>
            </Fragment>
          </div>
        </div>
      );
    });

    return <Scrollable>{rows}</Scrollable>;
  }

  renderStorefrontTicketCTA() {
    return (
      <StorefrontTicketCTA
        onClick={() => {
          this.props.showDialog(
            <StorefrontTicketDialog
              storefrontName={this.props.storefrontName}
              onRequestClose={this.props.hideDialog}
              onFormSubmit={formData =>
                this.props.createNewStorefrontTicket(formData)
              }
            />,
          );
        }}
      />
    );
  }

  renderCustomerMetadata() {
    return this.props.customerMetadata.map((customerData, index) => {
      return (
        <div key={index} className="ml2 mv2 fw5">
          {`${customerData.full_name} · `}
          {customerData.email}
        </div>
      );
    });
  }

  render() {
    if (this.state.isAddingCustomer) {
      return (
        <CustomerEmailScreen
          handleBackClick={this.handleToggleAddCustomer}
          headerText="Let's get your return started."
          subheaderText="Please enter your email."
          backgroundColor="red-5"
          handleSubmit={query =>
            handleSearchOrders(
              query,
              this.props.showError,
              this.handleSearchForOrders,
            )
          }
          handleClear={this.handleClearResults}
        />
      );
    }

    return (
      <div>
        <Header
          textColor="white"
          ctaGlyph
          onCtaClick={this.handleToggleAddCustomer}
        />

        <div className="flex-date-picker w-100 h-100">
          <BigSearchField
            className="b--none"
            initialInput={this.props.query}
            placeholderText="Search by order number or full name"
            onSubmit={query =>
              handleSearchOrders(
                query,
                this.props.showError,
                this.handleSearchForOrders,
              )
            }
            onClearClick={this.handleClearResults}
            isMobile
          />
        </div>

        {this.renderStorefrontTicketCTA()}

        {this.props.customerMetadata.length > 0 && (
          <div className="flex bb b--gray-9 overflow-scroll">
            {this.renderCustomerMetadata()}
            {/* Required to pad the last element. */}
            <div className="pl2" />
          </div>
        )}

        {this.props.orders.length > 0
          ? this.renderOrdersTable()
          : this.props.query && (
              <NoItemsMessage>
                No orders were found. Please enter a customer's name, email, or
                order number (e.g. r12312312)
              </NoItemsMessage>
            )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    orders: state.orderSearch.results,
    customerMetadata: state.orderSearch.customerMetadata,
    query: state.orderSearch.query,
    storefrontName: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ).name,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createReturnReceipt: (orderNumber, { isGiftReturn }) =>
      dispatch(
        createReturnReceipt(
          orderNumber,
          { isGiftReturn },
          ownProps.match.params.storefrontId,
        ),
      ),
    createNewStorefrontTicket: data =>
      dispatch(
        createNewStorefrontTicket(ownProps.match.params.storefrontId, data),
      ),
    fetchOrderSearchResults: (query, queryType) =>
      dispatch(fetchOrderSearchResults(query, queryType)),
    clearOrderSearchResults: () => dispatch(clearOrderSearchResults()),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    showError: errorMessage =>
      dispatch(showNotification(NotificationTypes.ERROR, errorMessage)),
    addItemToReturn: (item, returnReceiptId) =>
      dispatch(
        addItemToReturn(
          ownProps.match.params.storefrontId,
          returnReceiptId,
          item,
        ),
      ),
    createReturnReceiptFromOrder: (order, rmaNumber) =>
      dispatch(
        createReturnReceiptFromOrder(
          order,
          rmaNumber,
          ownProps.match.params.storefrontId,
        ),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForOrders);
