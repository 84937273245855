const ROLE_GROUP_ID_TO_NAME = {
  2: 'retail_store_lead',
  3: 'retail_jr_mngr',
  6: 'retail_operations_lead',
  7: 'retail_hq',
  9: 'retail_ambassador',
  10: 'retail_hq_field',
};

export default function associate(state = null, action) {
  if (action.type === 'RECEIVE_ASSOCIATE') {
    return action.payload;
  } else if (action.type === 'SET_ASSOCIATE_EXPIRED') {
    if (state) {
      return { ...state, expired: action.payload };
    }
  }

  return state;
}

export function getCanAssociateAccessAllStorefronts(state) {
  if (!state) {
    return false;
  }

  for (const role of ['super_user', 'retail_anywhere']) {
    if (state.roles.includes(role)) {
      return true;
    }
  }

  return false;
}

export function getDoesAssociateHaveAccessToStorefront(state, storefrontId) {
  if (!state) {
    return false;
  }

  if (getCanAssociateAccessAllStorefronts(state)) {
    return true;
  }

  return state.primary_storefront_id === Number(storefrontId);
}

export function getCanAssociateApplyCreditToSale(state) {
  if (!state) {
    return false;
  }

  for (const role of ['super_user', 'retail_credits']) {
    if (state.roles.includes(role)) {
      return true;
    }
  }

  return false;
}

export function getCanAssociateUnlock(state) {
  if (!state) {
    return false;
  }

  return ['super_user', 'retail_config'].some(role =>
    state.roles.includes(role),
  );
}

export function getIsAssociateAllowedToAcceptCash(state) {
  const allowedRoleGroups = [
    'retail_store_lead',
    'retail_jr_mngr',
    'retail_hq',
  ];

  return allowedRoleGroups.includes(ROLE_GROUP_ID_TO_NAME[state.role_group_id]);
}
