import React, { Component } from 'react';
import Header from 'containers/Header';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReturnsAndSalesHistory from 'containers/ReturnsAndSalesHistory';
import { connect } from 'react-redux';
import TabBar from 'components/shared/TabBar';

export class Reports extends Component {
  state = {
    date: null,
  };

  render() {
    return (
      <div>
        <Header textColor="white" />
        <TabBar
          borderColor="blue-1"
          tabs={[
            {
              title: 'Sales History',
              to: `${this.props.match.url}/sales_history`,
              notificationCount: 0,
              showNotificationCount: false,
            },
            {
              title: 'Returns History',
              to: `${this.props.match.url}/returns_history`,
              notificationCount: 0,
              showNotificationCount: false,
            },
          ]}
        />
        <Switch>
          <Route
            path={`${this.props.match.path}/sales_history`}
            component={ReturnsAndSalesHistory}
          />
          <Route
            path={`${this.props.match.path}/returns_history`}
            component={ReturnsAndSalesHistory}
          />
          <Redirect to={`${this.props.match.url}/sales_history`} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps)(Reports);
