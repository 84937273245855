import React, { Component, Fragment } from 'react';
import { array, bool, func, number, string } from 'prop-types';
import NoItemsMessage from 'components/shared/NoItemsMessage';
import ScrollableTable from 'components/shared/ScrollableTable';
import FormattedDateTime from 'components/shared/FormattedDateTime';
import Button from 'components/shared/Button';

export default class TransfersTable extends Component {
  static propTypes = {
    currentPage: number.isRequired,
    items: array.isRequired,
    onCTAClick: func,
    onUndoProcessClick: func,
    height: string,
    processedInventoryId: bool,
  };

  renderTable() {
    const transfers = this.props.items.map(item => [
      <Fragment>
        {item.number} <br />
        {item.published_at ? (
          <FormattedDateTime>{item.published_at}</FormattedDateTime>
        ) : (
          'not published'
        )}
      </Fragment>,
      <Fragment>
        {item.shipped_at ? (
          <FormattedDateTime>{item.shipped_at}</FormattedDateTime>
        ) : (
          'Not yet shipped'
        )}
      </Fragment>,
      item.tracking_number,
      item.container_identifier,
      item.reason,
      <Fragment>
        {item.units} Units
        <br />
        {item.skus} SKUs
      </Fragment>,
      <div className="flex flex-column justify-end items-center">
        {this.props.processedInventoryId === item.id ? (
          <Button className="fr f5 ml1" size="full-width" color="green-5">
            Processed
          </Button>
        ) : (
          <Button
            className="fr f5 ml1"
            size="full-width"
            outlined={!item.processable}
            color="black"
            onClick={() => this.props.onCTAClick(item)}
          >
            {item.processable ? 'Process' : 'View Manifest'}
          </Button>
        )}

        {item.processed && (
          <Button
            className="fr f5 ml1 mt2"
            size="full-width"
            color="red"
            onClick={() => this.props.onUndoProcessClick(item)}
          >
            Undo Processsing
          </Button>
        )}
      </div>,
    ]);

    // Columns percentages should add up to 100%
    const columns = [
      { heading: 'Transfer Number, Time', width: '20%' },
      { heading: 'Shipped at', width: '20%' },
      { heading: 'Tracking Number', width: '15%' },
      { heading: 'Container Identifier', width: '15%' },
      { heading: 'Reason', width: '10%' },
      { heading: 'SKUs & Units', width: '10%' },
      { heading: '', width: '10%' }, // space for the cancel button
    ];

    return (
      <ScrollableTable
        columns={columns}
        rows={transfers}
        keys={this.props.items.map(item => item.number)}
      />
    );
  }

  render() {
    return (
      <div
        className="w-100 pb5"
        style={{ height: this.props.height }}
        key={this.props.currentPage}
      >
        {this.props.items.length === 0 ? (
          <NoItemsMessage>
            There are no inbound transfers at this time.
          </NoItemsMessage>
        ) : (
          this.renderTable()
        )}
      </div>
    );
  }
}
