import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { retailApiUrl } from 'lib/url';
import { get } from 'lib/network';
import { startRequest, finishRequest } from 'actions/synchronous';
import Icon from 'components/shared/Icon';
import ProductInventory from 'components/product-search/ProductInventory';

const ProductSearchHit = ({
  product,
  index,
  storefront,
  sale,
  startRequest,
  finishRequest,
  isExpanded,
  setExpanded,
  isMobile,
  isUPCSearch,
  matchingVariant,
}) => {
  const [productInventory, setProductInventory] = useState({});
  const imageRef = useRef(null);
  const inventoryRef = useRef(null);
  const productId = product.objectID || product.id;

  useEffect(() => {
    if (matchingVariant && product.variants) {
      setProductInventory({
        id: product.id,
        name: product.name,
        price: product.price,
        variants: product.variants,
      });
    }
  }, [isUPCSearch, matchingVariant, product]);

  const imageHeight =
    (imageRef && imageRef.current && imageRef.current.clientHeight) || 0;
  const imageWidth =
    (imageRef && imageRef.current && imageRef.current.clientWidth) || 0;

  async function fetchProductInventory() {
    startRequest();
    const { response, responseJson } = await get(
      retailApiUrl(
        `storefronts/${storefront.id}/storefront_products/${productId}`,
      ),
    );
    finishRequest();

    if (response.ok) {
      setProductInventory(responseJson.product);
      setExpanded(productId);

      return inventoryRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }

  const gender = { female: "Women's", male: "Men's", unisex: 'Unisex' }[
    product.gender
  ];

  let tilesPerRow = isMobile ? 2 : 3;
  let leftInventoryPosition = isMobile ? '3%' : '6%';
  if (index % tilesPerRow === 1) {
    // if second in the row (100% - leftInventoryPosition)
    leftInventoryPosition = isMobile ? '-97%' : '-94%';
  } else if (index % tilesPerRow === 2) {
    // if last in the row (100% - leftInventoryPosition)
    leftInventoryPosition = '-194%';
  }

  const getInventoryStyle = () => {
    if (isUPCSearch) {
      if (isMobile) {
        return;
      }

      return {
        width: '190%',
        top: '0',
        left: '105%',
        height: '92%',
        overflowY: 'scroll',
      };
    }

    return {
      width: isMobile ? '195%' : '296%',
      top: imageHeight,
      left: leftInventoryPosition,
    };
  };

  const original_price = product.original_price || product.price;
  const adjusted_price = product.adjusted_price || product.price;

  return (
    <div
      className={classNames('flex relative lh-title', {
        'w-50 f5 pa1': isMobile && !isUPCSearch,
        'w-100 f5 pa2 flex-column': isMobile && isUPCSearch,
        'w-33 pl3 pv2': !isMobile,
        f5: sale,
      })}
    >
      <div>
        <div ref={imageRef}>
          <img
            src={product.flat_or_primary_image_url || product.primary_image_url}
            alt={product.name}
            onClick={isUPCSearch ? () => {} : fetchProductInventory}
            className={isExpanded ? 'ba b--gray-6' : ''}
          />
          <div>
            <div className="pt1">{product.name}</div>
            <div className="pt1 gray-6 flex justify-between">
              <div>{gender}</div>
              <div className="fw6">
                {original_price && original_price !== adjusted_price && (
                  <span className="strike">{`$${original_price} `}</span>
                )}
                {`$${adjusted_price}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(isExpanded || isUPCSearch) && (
        <Fragment>
          {!isUPCSearch && (
            <div
              className="z-2 absolute bg-white"
              style={{
                content: '',
                width: '20px',
                height: '20px',
                border: '1px solid #9e9e9e',
                borderRight: 'none',
                borderBottom: 'none',
                top: imageHeight + 14,
                transform: 'rotate(45deg)',
                left: imageWidth / 2 + 16,
              }}
            />
          )}
          <div
            className={classNames('ba b--gray-6 bg-white z-1', {
              'absolute mv2 pa2': !isMobile && isUPCSearch,
              'absolute pa2 mv3': !isMobile && !isUPCSearch,
              'mv4 pa2': isMobile && isUPCSearch,
              'absolute mv3 pv2 ph1': isMobile && !isUPCSearch,
            })}
            style={getInventoryStyle()}
            ref={inventoryRef}
          >
            <ProductInventory
              inventory={productInventory}
              product={product}
              storefront={storefront}
              sale={sale}
              isMobile={isMobile}
              isUPCSearch={isUPCSearch}
              matchingVariant={matchingVariant}
            />
            {!isUPCSearch && (
              <Icon
                className="absolute right-0 z-3 h2 w2"
                style={{ top: '8px', right: '8px' }}
                color="gray-2"
                glyph="x"
                onClick={setExpanded}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return { isMobile: state.ui.isMobile };
}

function mapDispatchToProps(dispatch) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearchHit);
