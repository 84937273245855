import 'normalize.css';
import 'tachyons-pos';
import 'css/colors.css';
import 'css/app.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import Root from 'containers/Root';
import store from 'store';
import { isProduction } from 'lib/env';
import { getAssociateAuthToken } from 'lib/tokens';
import configureBugsnag from 'lib/bugsnag';
import { fetchConstantsFromServer } from 'lib/constants';
import { fetchAssociate, fetchStorefronts } from 'actions/asynchronous';
import { detectMobile } from 'actions/synchronous';

function renderApplication() {
  if (isProduction()) {
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
    ReactDOM.render(
      <ErrorBoundary>
        <Root store={store} />
      </ErrorBoundary>,
      document.getElementById('root'),
    );
  } else {
    ReactDOM.render(<Root store={store} />, document.getElementById('root'));
  }
}

function isMobile() {
  const mobileDevices = /iPhone/i;
  return (
    mobileDevices.test(navigator.userAgent) || window.screen.availHeight < 700
  );
}

function bootstrapApplicationState() {
  const associateAuthToken = getAssociateAuthToken();

  return Promise.all([
    fetchConstantsFromServer(),
    associateAuthToken && store.dispatch(fetchAssociate()),
    store.dispatch(fetchStorefronts()),
    store.dispatch(detectMobile(isMobile())),
  ]);
}

if (isProduction()) {
  configureBugsnag();
}

bootstrapApplicationState().then(renderApplication);
