import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { isValidTrackingNumber, isValidTransferNumber } from 'lib/validation';
import Button, { ButtonStyles } from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import { UPCScanner } from 'lib/keyboard';

export default class SearchField extends Component {
  static propTypes = {
    value: string,
    buttonColor: string,
    className: string,
    initialInput: string,
    placeholderText: string,
    onSubmit: func.isRequired,
    onClearClick: func,
    isLoading: bool,
    searchOnKeyUp: bool,
    onScan: func,
    isMobile: bool,
    buttonText: string,
  };

  static defaultProps = {
    buttonColor: 'green-2',
    placeholderText: 'Search by IT Parent, IT Child or Tracking ID…',
    isLoading: false,
    submitOnChange: false,
    buttonText: 'Search',
  };

  state = {
    input: this.props.initialInput || '',
    isFocused: false,
    hasSubmitted: false,
  };

  constructor(props) {
    super(props);

    this.scanner = new UPCScanner({
      callback: scanResult => {
        this.setState({
          input: scanResult,
          hasSubmitted: true,
        });

        if (this.props.onScan) {
          this.props.onScan(scanResult);
        } else {
          this.props.onSubmit(scanResult);
        }
      },
    });

    this.inputElement = React.createRef();
  }

  componentDidMount() {
    this.scanner.listen();
  }

  componentWillUnmount() {
    this.scanner.stopListening();
  }

  isSpecialInput(string) {
    return isValidTransferNumber(string) || isValidTrackingNumber(string);
  }

  handleInput = event => {
    const newInput = event.target.value;

    this.setState({
      input: newInput,
      hasSubmitted: this.props.submitOnChange,
    });

    if (this.props.submitOnChange) {
      this.props.onSubmit(newInput);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // If the text field was just focused or submitted, we want to select the
    // contents of the text field, to make editing the text faster, as you will
    // blow away the old query as soon as you start typing.

    // If a UPC was enterered, we want to select it in the input to make editing
    // the text faster, as you will blow away the old UPC as soon as you type.
    if (
      this.isSpecialInput(this.state.input) &&
      ((this.state.isFocused && !prevState.isFocused) ||
        (this.state.hasSubmitted && !prevState.hasSubmitted))
    ) {
      this.inputElement.current.setSelectionRange(0, 9999);
    }
  }

  handleFocus = () => {
    this.scanner.stopListening();
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.scanner.listen();
    this.setState({ isFocused: false });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ hasSubmitted: true });
    this.props.onSubmit(this.state.input);

    if (this.props.clearOnSubmit) {
      this.setState({
        input: '',
        hasSubmitted: false,
      });
    } else {
      this.setState({ hasSubmitted: true });
    }
  };

  handleClearClick = event => {
    event.preventDefault();

    this.setState({
      input: '',
      hasSubmitted: false,
    });

    if (this.props.onClearClick) {
      this.props.onClearClick();
    }
  };

  // The button for the search field changes its display based on the current state
  //
  // Whilst waiting for XHR request: no button needed!
  // User has submitted their search: an "x" icon that clears the search upon click
  // User has started typing a query: a "search" button that will submit the request
  // User has not yet typed a query: a magnifying glass icon
  renderButton() {
    const iconClassName = 'absolute center-v right-0 pa4';

    if (this.state.hasSubmitted) {
      return (
        <Icon
          glyph="x"
          color="gray-5"
          className={iconClassName}
          onClick={this.handleClearClick}
        />
      );
    } else if (this.state.input.length) {
      return (
        <Button
          onClick={this.handleSubmit}
          color={this.props.buttonColor}
          buttonStyle={!this.props.isMobile && ButtonStyles.TERTIARY}
          size="medium"
          style={{ height: '40px' }}
          className={classNames('absolute center-v right-0', {
            'gray-6 bg-transparent': this.props.isMobile,
            mr3: !this.props.isMobile,
          })}
        >
          {this.props.isMobile ? (
            <Icon
              glyph="arrow-right"
              color="gray-5"
              className={iconClassName}
            />
          ) : (
            this.props.buttonText
          )}
        </Button>
      );
    }

    return (
      <Icon
        glyph="magnify"
        color="gray-5"
        className={classNames(iconClassName, 'f3')}
      />
    );
  }

  render() {
    const placeholderText = this.props.isMobile
      ? 'Scan barcode'
      : this.props.placeholderText;

    return (
      <form
        onSubmit={this.handleSubmit}
        className={classNames('bb b--gray-9 relative', this.props.className)}
      >
        {!this.props.isLoading && this.renderButton()}
        <input
          autoCapitalize="off"
          autoCorrect="off"
          className={classNames(
            'input-reset db bn sans-serif input-invisible outline-0 w-90 ph3 f4',
            { h6: this.props.isMobile, h9: !this.props.isMobile },
          )}
          type="text"
          placeholder={placeholderText}
          value={this.state.input}
          onChange={this.handleInput}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          ref={this.inputElement}
        />
      </form>
    );
  }
}
