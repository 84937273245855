import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { func, object, number, bool } from 'prop-types';

import { getGiftCard } from 'actions/asynchronous';
import { isCashValueValid } from 'lib/validation';
import Button, { ButtonStyles } from 'components/shared/Button';
import Dialog from 'components/shared/Dialog';
import NumberKeyPad from 'components/shared/NumberKeyPad';

class GiftCardPurchaseDialog extends Component {
  static propTypes = {
    variant: object,
    warehouseId: number,
    onAddItem: func,
    onRequestClose: func,
    getGiftCard: func,
    isVariable: bool,
  };

  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  componentDidMount() {
    this.input && this.input.current.focus();
  }

  state = {
    cashValue: '',
    codeValue: '',
    step: 1,
    error: '',
    giftCardId: '',
  };

  handleClickKeyPadNumber = value => {
    this.setState(prevState => {
      let newCashValue;

      if (value === '<') {
        newCashValue = prevState.cashValue.slice(0, -1);
      } else {
        newCashValue = prevState.cashValue + value;
      }

      return { cashValue: newCashValue };
    });
  };

  handleAssignValue = () => {
    const { variant, warehouseId } = this.props;

    this.props.onAddItem({
      warehouseId,
      variantId: variant.variant_id,
      productId: variant.product_id,
      price: this.state.cashValue,
      unitId: this.state.giftCardId,
      unitType: 'Giftcard',
    });
    this.props.onRequestClose();
  };

  handleChangeCode = event => {
    this.setState({ codeValue: event.target.value });
  };

  handleSubmitCode = async event => {
    event.preventDefault();

    const { variant, warehouseId } = this.props;
    this.props.getGiftCard(this.state.codeValue).then(giftCard => {
      if (!giftCard) {
        this.setState({ error: 'Gift card not found.' });
        return;
      }

      if (this.props.isVariable) {
        this.setState({ step: 2, giftCardId: giftCard.id });
      } else {
        this.props.onAddItem({
          warehouseId,
          variantId: variant.variant_id,
          productId: variant.product_id,
          price: giftCard.amount,
          unitId: giftCard.id,
          unitType: 'Giftcard',
        });
        this.props.onRequestClose();
      }
    });
  };

  renderStep1 = () => {
    return (
      <Fragment>
        <div>Scan Gift Card</div>
        <div className="w-60 tc pt2 pb4 gray-6">
          Scan bar code on the back of the gift card or enter it into the field
          below.
        </div>
        <form className="flex w-70" onSubmit={this.handleSubmitCode}>
          <input
            type="text"
            className="flex-auto ba b--gray-6 f4 h6 ph1 w-100 sans-serif"
            value={this.state.codeValue}
            ref={this.input}
            placeholder="Enter Gift or Promo Code"
            onChange={this.handleChangeCode}
          />
          <Button
            disabled={!this.state.codeValue}
            buttonStyle={ButtonStyles.SECONDARY}
          >
            Submit
          </Button>
        </form>
      </Fragment>
    );
  };

  renderStep2 = () => {
    return (
      <Fragment>
        <div className="pb3">Enter Gift Card Value</div>
        <div className="f2 pb4 gray-2">
          {this.state.cashValue ? `$${this.state.cashValue}` : '$0.00'}
        </div>
        <NumberKeyPad onClickNumber={this.handleClickKeyPadNumber} />
        <Button
          buttonStyle={ButtonStyles.SECONDARY}
          size="medium"
          className="f4 mt3"
          disabled={!isCashValueValid(this.state.cashValue)}
          onClick={this.handleAssignValue}
        >
          Done
        </Button>
      </Fragment>
    );
  };

  renderBubbles = () => {
    if (!this.props.isVariable) {
      return null;
    }

    return (
      <div className="flex justify-center items-center pb4">
        <span
          className={classNames('br-100 ba mr2 tc f5 flex justify-center w3', {
            'b--gray-9 gray-9': this.state.step !== 1,
            'white bg-gray-5': this.state.step === 1,
          })}
        >
          1
        </span>
        <span
          className={classNames('br-100 ba tc f5 flex justify-center w3', {
            'b--gray-9 gray-9': this.state.step !== 2,
            'white bg-gray-5': this.state.step === 2,
          })}
        >
          2
        </span>
      </div>
    );
  };

  renderError = () => {
    return <div className="red pb2">{this.state.error}</div>;
  };

  render() {
    const dimensions =
      this.state.step === 1
        ? { height: '21rem', width: '32rem' }
        : { height: '37rem', width: '25rem' };

    return (
      <Dialog {...dimensions} simple onRequestClose={this.props.onRequestClose}>
        <div className="bg-white relative flex flex-column items-center justify-center">
          {this.renderError()}
          {this.renderBubbles()}
          {this.state.step === 1 ? this.renderStep1() : this.renderStep2()}
        </div>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getGiftCard: code => dispatch(getGiftCard(code)),
  };
}

export default connect(null, mapDispatchToProps)(GiftCardPurchaseDialog);
