import React from 'react';
import classNames from 'classnames';
import FormattedDateTime from 'components/shared/FormattedDateTime';
import Icon from 'components/shared/Icon';
import { staticImageUrl } from 'lib/url';
import { pubWithSale, Event } from 'lib/events';

const Triangle = () => {
  return (
    <div
      style={{
        content: '',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '8px 8px 0 0',
        borderColor: '#ffc107 transparent transparent transparent',
        position: 'absolute',
      }}
    />
  );
};

function SourceSelector({
  value,
  options,
  onChange,
  id,
  label,
  error,
  className,
}) {
  return (
    <div className="relative mr2 w-100">
      <span
        className="absolute f3 fw1 gray-6"
        style={{ top: '1rem', right: '0.2rem' }}
      >
        ⌄
      </span>
      <label
        className="db f6 gray-6 tl lh-solid mb0"
        htmlFor={id}
        style={{ marginBottom: '0.3rem' }}
      >
        {label}:
      </label>
      <select
        type="text"
        className={classNames(
          'ba b--gray-9 br0 input-reset bg-white f6 ph1 w-100 h3 sans-serif',
          className,
        )}
        name={id}
        value={value}
        onChange={onChange}
        onClick={() => pubWithSale(Event.Cart.OPEN_SALE_ITEM_SOURCE_DROPDOWN)}
      >
        {options &&
          options.map(option => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
      </select>
    </div>
  );
}

function SaleItem({
  id,
  title,
  imageUrl,
  price,
  originalPrice,
  size,
  source,
  upc,
  warehouseId,
  restockDate,
  orderableState,
  availableSources,
  allowEditing,
  displayPrice,
  isLastItem,
  isFinalSale,
  onDelete,
  onChangeSource,
}) {
  function handleClickDelete() {
    if (
      window.confirm('Are you sure you want to remove this item from the cart?')
    ) {
      onDelete();
    }
  }

  function handleChangeSource(event) {
    event.preventDefault();
    onChangeSource(id, event.target.value);
  }

  const onlineColor = {
    shippable: 'blue-5',
    preorderable: 'gold-7',
  }[orderableState];

  const shipDate =
    orderableState === 'preorderable' ? (
      <FormattedDateTime format="short">{restockDate}</FormattedDateTime>
    ) : (
      'Now'
    );

  const formattedPrice = (() => {
    if (originalPrice && originalPrice !== price) {
      return (
        <span>
          <span className="strike gray-6">{originalPrice}</span>
          <br />
          <span className="red">{price}</span>
        </span>
      );
    } else {
      return price;
    }
  })();

  return (
    <div
      className={classNames('pa1 relative', {
        'bb b--gray-9': !isLastItem && allowEditing,
      })}
    >
      {allowEditing && (
        <Icon
          color="gray-5"
          glyph="x"
          className="absolute f6"
          style={{ right: '4px' }}
          onClick={handleClickDelete}
        />
      )}
      <div className="f5 tl pr2 lh-title pb1">{title}</div>
      <div className="flex relative">
        <div className="mr1" style={{ flexShrink: 0, width: '112px' }}>
          {orderableState === 'preorderable' && <Triangle />}
          <img alt={title} src={staticImageUrl(imageUrl, { size: 112 })} />
        </div>
        <div>
          {(displayPrice || allowEditing) && <p className="ma0 f4 tl">{formattedPrice}</p>}
          <p className="ma0 f6 gray-6 tl lh-copy">Size: {size}</p>
          {upc && <p className="ma0 f6 gray-6 tl lh-copy">UPC: {upc}</p>}
          {allowEditing && (
            <SourceSelector
              value={warehouseId}
              className={source === 'Online' ? onlineColor : 'violet-7'}
              options={availableSources.map(source => ({
                title: `• ${source.source}`,
                value: source.warehouse_id,
              }))}
              onChange={handleChangeSource}
              id="source"
              label="Source"
            />
          )}
          {source === 'Online' && (
            <p className={classNames('ma0 tl f6', onlineColor)}>
              Ships {shipDate}
            </p>
          )}
          {isFinalSale && <div className="red mt1 tl">FINAL SALE</div>}
        </div>
      </div>
    </div>
  );
}

export default SaleItem;
