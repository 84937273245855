import React from 'react';
import { Link } from 'react-router-dom';
import IconWithLabel from 'components/shared/IconWithLabel';

export default function StorefrontMenu({
  storefronts,
  activeStorefront,
  onSelectStorefront,
  onBack,
  isMobile,
}) {
  return (
    <div className="flex flex-column justify-center bb b--gray-9">
      <IconWithLabel
        onClick={onBack}
        color="gray-6"
        icon="arrow-left"
        iconClassName="f5 pr1"
        label="Back"
        className="pa2"
      />
      {storefronts.map(storefront => (
        <Link
          key={storefront.id}
          className="no-underline"
          onClick={onSelectStorefront}
          to={`/storefronts/${storefront.id}/sales`}
        >
          <IconWithLabel
            iconPosition="right"
            color={storefront === activeStorefront ? 'gray-2' : 'gray-6'}
            icon={storefront === activeStorefront ? 'circle-checkmark' : null}
            iconClassName="f3"
            labelClassName="pr2"
            label={isMobile ? storefront.display_name : storefront.name}
            className="justify-between pa2"
          />
        </Link>
      ))}
    </div>
  );
}
