import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { get, patch } from 'lib/network';
import { retailApiUrl } from 'lib/url';
import { getAmountOfHoursStoreHasBeenOpen } from 'lib/time';
import {
  showNotification,
  startRequest,
  finishRequest,
} from 'actions/synchronous';
import { NotificationTypes } from 'reducers/notifications';
import { getStorefrontById } from 'reducers/storefronts';
import Pagination from 'components/shared/Pagination';
import Scrollable from 'components/shared/Scrollable';
import ScrollableTable from 'components/shared/ScrollableTable';
import Button, { ButtonStyles } from 'components/shared/Button';
import RestockRequestItem from 'components/restock-queue/RestockRequestItem';
import CategoryNav from 'components/restock-queue/CategoryNav';

class PendingRestocksQueue extends Component {
  limit = 40;

  state = {
    currentSubcategory: null,
    pendingRestockRequests: [],
    page: 1,
    tableView: 'category',
    totalPendingRestockRequestCount: 0,
    categoryCounts: {},
  };

  async componentDidMount() {
    this.fetchPendingRestockRequests();

    this.pollingInterval = setInterval(() => {
      this.fetchPendingRestockRequests();
    }, 30000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.fetchPendingRestockRequests();
    }

    if (
      prevState.totalPendingRestockRequestCount !==
      this.state.totalPendingRestockRequestCount
    ) {
      this.props.onCountChange(this.state.totalPendingRestockRequestCount);
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  fetchPendingRestockRequests = async () => {
    this.props.startRequest();

    try {
      const { response, responseJson } = await get(
        retailApiUrl(
          `storefronts/${this.props.storefront.id}/storefront_restock_requests`,
        ),
        {
          page: this.state.page,
          limit: this.limit,
          restocked: false,
          cutoff_period: getAmountOfHoursStoreHasBeenOpen(
            this.props.storefront,
          ),
        },
      );

      this.props.finishRequest();

      if (response.ok) {
        this.setState(
          {
            categoryCounts: responseJson.metadata.category_counts,
            totalPendingRestockRequestCount: responseJson.metadata.count,
            pendingRestockRequests: responseJson.storefront_restock_requests,
            categorizedPendingRestockRequests:
              responseJson.categorized_restock_requests,
          },
          () => {
            if (this.state.currentSubcategory) {
              const updatedSubcategory =
                this.state.categorizedPendingRestockRequests.find(
                  subcategory =>
                    subcategory.subcategory_title ===
                      this.state.currentSubcategory.subcategory_title &&
                    subcategory.subcategory_category ===
                      this.state.currentSubcategory.subcategory_category,
                );

              if (!updatedSubcategory) {
                this.setState({ currentSubcategory: null });
              } else {
                this.setState({ currentSubcategory: updatedSubcategory });
              }
            }
          },
        );
      } else {
        this.props.showError(responseJson.message);
      }
    } catch (err) {
      this.props.finishRequest();
      this.props.showError(err.message);
    }
  };

  handleRestock = async restockRequests => {
    this.props.startRequest();
    const ids = restockRequests.map(r => r.item_restock_id);

    try {
      const { response, responseJson } = await patch(
        retailApiUrl(
          `storefronts/${this.props.storefront.id}/storefront_restock_requests/${ids}`,
        ),
        { restocked: true },
      );

      if (response.ok) {
        this.fetchPendingRestockRequests();
        this.props.onRestock();
        this.props.finishRequest();
      } else {
        this.props.finishRequest();
        this.props.showError(responseJson.message);
      }
    } catch (err) {
      this.props.finishRequest();
      this.props.showError(err.message);
    }
  };

  handleIncompleteRestock = async restockRequests => {
    this.props.startRequest();
    const ids = restockRequests.map(r => r.item_restock_id);

    try {
      const { response, responseJson } = await patch(
        retailApiUrl(
          `storefronts/${this.props.storefront.id}/storefront_restock_requests/${ids}`,
        ),
        { incomplete: true },
      );

      if (response.ok) {
        this.fetchPendingRestockRequests();
        this.props.onRestock();
        this.props.finishRequest();
      } else {
        this.props.finishRequest();
        this.props.showError(responseJson.message);
      }
    } catch (err) {
      this.props.finishRequest();
      this.props.showError(err.message);
    }
  };

  handlePageChange = ({ page }) => {
    this.setState({ page });
  };

  handleSubcategoryChange = ({ subcategory }) => {
    this.setState({ currentSubcategory: subcategory });
  };

  getGroupedRequests = () => {
    const restockRequests = this.state.currentSubcategory
      ? this.state.currentSubcategory.restock_requests
      : this.state.pendingRestockRequests;

    restockRequests.sort((a, b) => {
      if (a.item_sku > b.item_sku) {
        return -1;
      } else {
        return 1;
      }
    });

    const groupedRequests = restockRequests.reduce(
      (grouped, restockRequest) => {
        const groupIndex = grouped.findIndex(
          group => group.product === restockRequest.item_title,
        );
        if (groupIndex === -1) {
          const newGroup = {
            product: restockRequest.item_title,
            items: [
              {
                sku: restockRequest.item_sku,
                requests: [restockRequest],
                count: 1,
              },
            ],
          };

          return [...grouped, newGroup];
        } else {
          const groupCopy = Object.assign({}, grouped[groupIndex]);
          const itemIndex = groupCopy.items.findIndex(
            item => restockRequest.item_sku === item.sku,
          );
          if (itemIndex === -1) {
            const newItem = {
              sku: restockRequest.item_sku,
              requests: [restockRequest],
              count: 1,
            };
            groupCopy.items = [...groupCopy.items, newItem];
            grouped[groupIndex] = groupCopy;
            return grouped;
          } else {
            const itemCopy = Object.assign({}, groupCopy.items[itemIndex]);
            itemCopy.requests = [...itemCopy.requests, restockRequest];
            itemCopy.count = itemCopy.count + 1;

            groupCopy.items[itemIndex] = itemCopy;
            grouped[groupIndex] = groupCopy;
            return grouped;
          }
        }
      },
      [],
    );

    return groupedRequests;
  };

  renderChronologicalRows = () => {
    const restockRequests = this.state.currentSubcategory
      ? this.state.currentSubcategory.restock_requests
      : this.state.pendingRestockRequests;

    restockRequests.sort((a, b) => a.id - b.id);

    const restockRequestRows = restockRequests.map(restockRequest => [
      <RestockRequestItem
        title={restockRequest.item_title}
        color={restockRequest.item_color}
        imageUrl={restockRequest.item_image_url}
        size={restockRequest.item_size}
        sku={restockRequest.item_sku}
        quantityOnHand={restockRequest.item_quantity_on_hand}
      />,
      <div>
        <Button
          buttonStyle={ButtonStyles.SECONDARY}
          className="w-100 mb1"
          onClick={() => this.handleRestock([restockRequest])}
        >
          Mark as Complete
        </Button>
        <Button
          buttonStyle={ButtonStyles.QUATERNARY}
          outlined={true}
          className="w-100"
          onClick={() => this.handleIncompleteRestock([restockRequest])}
        >
          Mark as Incomplete
        </Button>
      </div>,
    ]);

    return restockRequestRows;
  };

  renderCategoryRows = () => {
    const restockRequestRowGroups = this.getGroupedRequests().flatMap(group => {
      const grouped = group.items.map(item => {
        return (
          <CSSTransition
            key={'transition-' + item.requests[0].item_sku}
            timeout={420}
            classNames="scrollable-table__row"
          >
            <div className="flex items-center">
              <div className="w-70">
                <RestockRequestItem
                  key={item.requests[0].item_title}
                  title={item.requests[0].item_title}
                  color={item.requests[0].item_color}
                  imageUrl={item.requests[0].item_image_url}
                  size={item.requests[0].item_size}
                  sku={item.requests[0].item_sku}
                  quantityOnHand={item.requests[0].item_quantity_on_hand}
                  restockCount={item.count}
                />
              </div>
              <div className="w-30" key={item.requests[0].item_title + '2'}>
                <Button
                  buttonStyle={ButtonStyles.SECONDARY}
                  className="w-100 mb1"
                  onClick={() => this.handleRestock(item.requests)}
                >
                  Mark as Complete
                </Button>
                <Button
                  buttonStyle={ButtonStyles.QUATERNARY}
                  outlined={true}
                  className="w-100"
                  onClick={() => this.handleIncompleteRestock(item.requests)}
                >
                  Mark as Incomplete
                </Button>
              </div>
            </div>
          </CSSTransition>
        );
      });

      return [
        <CSSTransition
          timeout={420}
          classNames="scrollable-table__row"
          key={group.product}
        >
          <div className="overflow-hidden">
            <h1 className="fw5 f4 pa2 bg-gray-9 mv0">{group.product}</h1>
            <TransitionGroup component={null}>{grouped}</TransitionGroup>
          </div>
        </CSSTransition>,
      ];
    });

    return restockRequestRowGroups;
  };

  renderChronologicalView() {
    const rows = this.renderChronologicalRows();
    const columns = [
      { heading: 'Item', width: '70%' },
      { heading: '', width: '30%' },
    ];

    return (
      <ScrollableTable
        columns={columns}
        rows={rows}
        style={{ width: '75%' }}
        key={this.state.tableView}
        keys={this.state.pendingRestockRequests.map(
          rr => `${this.state.tableView}-${rr.item_restock_id}`,
        )}
      />
    );
  }

  renderCategoryView() {
    return (
      <Scrollable className="w-75 pa3" key={this.state.tableView}>
        <TransitionGroup component={null}>
          {this.renderCategoryRows()}
        </TransitionGroup>
      </Scrollable>
    );
  }

  render() {
    let content = null;

    if (this.state.tableView === 'chronological') {
      content = this.renderChronologicalView();
    } else {
      content = this.renderCategoryView();
    }

    return (
      <div className="fl w-100 h-100">
        <CategoryNav
          categoryCounts={{
            ...this.state.categoryCounts,
            total: this.state.totalPendingRestockRequestCount,
          }}
          pendingRestockRequests={this.state.pendingRestockRequests}
          categorizedPendingRestockRequests={
            this.state.categorizedPendingRestockRequests
          }
          onSubcategoryClick={this.handleSubcategoryChange}
          onTableViewClick={tableView => this.setState({ tableView })}
        />
        <Fragment>
          {content}
          {this.state.totalPendingRestockRequestCount > this.limit && (
            <Pagination
              width="full"
              totalCount={this.state.totalPendingRestockRequestCount}
              onPageChange={this.handlePageChange}
              limit={this.limit}
            />
          )}
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    storefront: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showError: errorMessage =>
      dispatch(showNotification(NotificationTypes.ERROR, errorMessage)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingRestocksQueue);
