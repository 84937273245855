import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import classNames from 'classnames';
import { isUpc } from 'lib/validation';
import Icon from 'components/shared/Icon';

let query = null;

const ProductSearchField = ({
  isMobile,
  refine,
  currentRefinement,
  onQueryChange,
}) => {
  const handleInput = event => {
    event.preventDefault();
    query = event.target.value;

    if (!isUpc(query)) {
      refine(query);
    }

    onQueryChange(query);
  };

  const handleClearClick = event => {
    event.preventDefault();
    query = null;
    refine(query);
    onQueryChange(query);
  };

  const placeholderText = isMobile
    ? 'Search for products or scan barcode...'
    : 'Search for products by name or UPC, or scan barcode...';

  return (
    <div className="bb b--gray-9 relative">
      <input
        type="search"
        maxLength="50"
        autoCapitalize="none"
        autoCorrect="off"
        className={classNames(
          'algolia-search input-reset db bn sans-serif input-invisible outline-0 w-100 bg-white ph3 f4',
          { h6: isMobile, h9: !isMobile },
        )}
        placeholder={placeholderText}
        value={query || currentRefinement}
        onChange={handleInput}
      />
      {(query || currentRefinement) ? (
        <Icon
          color="gray-2"
          glyph="x"
          className={classNames('absolute', {
            f4: !isMobile,
            f5: isMobile,
          })}
          style={
            isMobile
              ? { right: '16px', top: '16px' }
              : { right: '32px', top: '27px' }
          }
          onClick={handleClearClick}
        />
      ) : 
      <Icon 
        color="gray-2"
        glyph="magnify"
        className={classNames('absolute', {
          f4: !isMobile,
          f5: isMobile,
        })}
        style={
          isMobile
            ? { right: '16px', top: '16px' }
            : { right: '32px', top: '27px', fontSize: '1.2em' }
        } 
        onClick={() => {}}
      />
    }
    </div>
  );
};

export default connectSearchBox(ProductSearchField);
