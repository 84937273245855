import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MenuItem from 'components/navigation/MenuItem';
import { getAllowedPathsForRoles, getAllowedPathsForGroup } from 'lib/url';
import { getStorefrontById } from 'reducers/storefronts';
import Scrollable from 'components/shared/Scrollable';

export const menuItems = [
  {
    title: 'Sales',
    partialPath: 'sales',
    glyph: 'weekender',
    color: 'blue-4',
  },
  {
    title: 'Stock Check',
    partialPath: 'stock-check',
    glyph: 'box-face',
    color: 'teal-5',
  },
  {
    title: 'Returns',
    partialPath: 'returns',
    glyph: 'receipt',
    color: 'red-5',
  },
  {
    title: 'Cycle Counts',
    partialPath: 'cycle-counts',
    glyph: 'hanger',
    color: 'teal-5',
  },
  {
    title: 'Damages',
    partialPath: 'damages',
    glyph: 'circle-x',
    color: 'teal-5',
  },
  {
    title: 'Reports',
    partialPath: 'history',
    glyph: 'bullet-list',
  },
  {
    title: 'Restock Queue',
    partialPath: 'restocks',
    glyph: 'clock',
    color: 'gold-6',
  },
  {
    title: 'Returns Queue',
    partialPath: 'back-of-house',
    glyph: 'hangtag',
    color: 'red-6',
  },
  {
    title: 'Transfers',
    partialPath: 'inventory-transfers',
    glyph: 'box-top',
    color: 'blue-4',
  },
  {
    title: 'Request to Try',
    partialPath: 'request-to-try',
    glyph: 'bell',
    color: 'blue-4',
  },
  {
    title: 'Feature Control',
    partialPath: 'feature-control',
    glyph: 'lock-locked',
  },
  {
    title: 'Cash Float',
    partialPath: 'float',
    glyph: 'stacks',
    color: 'green-5',
  },
  {
    title: 'Stripe Readers',
    partialPath: 'stripe',
    glyph: 'credit-card',
    color: 'green-5',
  },
];

function MenuItems({
  associateRoles,
  associateGroupName,
  match,
  isMobile,
  acceptsCash,
  requestToTryEnabled,
}) {
  const allowedPathsForRoles = getAllowedPathsForRoles(associateRoles);
  const allowedPathsForGroup = getAllowedPathsForGroup(associateGroupName, {
    acceptsCash,
  });
  const paths = [...allowedPathsForRoles, ...allowedPathsForGroup];

  return (
    <Scrollable className="pa0 ma0 list" key={match.url}>
      {menuItems
        .filter(menuItem => {
          const { partialPath } = menuItem;
          if (partialPath === 'request-to-try' && !requestToTryEnabled) {
            return false;
          }
          if (
            isMobile &&
            ![
              'Cycle Counts',
              'Damages',
              'Feature Control',
              'Request to Try',
              'Returns',
              'Sales',
              'Stock Check',
              'Transfers',
            ].includes(menuItem.title)
          ) {
            return false;
          }

          return paths.some(path => path.includes(menuItem.partialPath));
        })
        .map(menuItem => (
          <MenuItem key={menuItem.partialPath} menuItem={menuItem} />
        ))}
    </Scrollable>
  );
}

function mapStateToProps(state, ownProps) {
  const storefront = getStorefrontById(
    state.storefronts,
    ownProps.match.params.storefrontId,
  );

  return {
    associateRoles: state.associate.roles,
    associateGroupName: state.associate.role_group_name,
    isMobile: state.ui.isMobile,
    acceptsCash: storefront && storefront.accepts_cash,
    requestToTryEnabled: storefront && storefront.request_to_try_enabled,
  };
}

export default withRouter(connect(mapStateToProps)(MenuItems));
