import React from 'react';
import classNames from 'classnames';

export default function SubHeader({ className, children }) {
  return (
    <h2
      className={classNames(
        className,
        'h2 pv2 ph3 mv0 f4 fw5 bb b--gray-9 flex justify-between items-center',
      )}
    >
      {children}
    </h2>
  );
}
