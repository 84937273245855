import React from 'react';
import classNames from 'classnames';

export default function FirstTryUseMessage({ willBeUsed = true }) {
  return (
    <div
      className={classNames(
        'ma1 mb3 pa1 f5 flex items-center justify-center tc',
        {
          'bg-red-5 white': willBeUsed,
          'bg-gray-9 gray-5': !willBeUsed,
        },
      )}
    >
      This item will {!willBeUsed && 'not '}count as the First Try
    </div>
  );
}
