export default function cashFloats(state = [], action) {
  switch (action.type) {
    case 'RECEIVE_CASH_FLOATS': {
      return action.payload;
    }

    case 'RECEIVE_CASH_FLOAT': {
      return [
        action.payload,
        ...state.filter(float => float.id !== action.payload.id),
      ];
    }

    default:
      return state;
  }
}

export function getcashFloatById(cashFloats, cashFloatId) {
  return cashFloats.find(float => float.id === Number(cashFloatId));
}
