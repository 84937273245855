export function openCashDrawer(printerIpAddress) {
  if (!window.epson || !printerIpAddress) {
    return;
  }

  // initialize printer
  new window.epson.ePOSBuilder();

  // connect to printer using specified IP address
  const address = `https://${printerIpAddress}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
  const epos = new window.epson.ePOSPrint(address);

  epos.send(epos.addPulse());
}
