import React from 'react';
import { array, func, number } from 'prop-types';

import ScrollableTable from 'components/shared/ScrollableTable';
import NoItemsMessage from 'components/shared/NoItemsMessage';
import FormattedDateTime from 'components/shared/FormattedDateTime';
import Button, { ButtonStyles } from 'components/shared/Button';

export default class CashFloatTable extends React.Component {
  static defaultProps = {
    cashFloats: [],
    netCash: 0,
  };

  renderTable() {
    const floats = this.props.cashFloats.map(float => {
      const onClickEndDay = () => {
        this.props.onClickEndDay(float.id);
      };

      const isToday =
        new Intl.DateTimeFormat('en').format(new Date()) ===
        new Intl.DateTimeFormat('en').format(new Date(float.created_at));

      const expectedFloat =
        parseFloat(float.starting_amount) + this.props.netCash;

      return [
        <FormattedDateTime>{float.created_at}</FormattedDateTime>,
        <div>{`$${parseFloat(float.starting_amount).toFixed(2)}`}</div>,
        <div>{isToday ? `$${expectedFloat.toFixed(2)}` : '--'}</div>,
        <div>
          {float.ending_amount
            ? `$${parseFloat(float.ending_amount).toFixed(2)}`
            : '--'}
        </div>,
        <div>
          {float.ending_amount
            ? `$${float.ending_amount - float.starting_amount}`
            : '--'}
        </div>,
        <div className="flex justify-end items-center">
          {!float.ending_amount && (
            <Button
              className="w-100"
              size="medium"
              buttonStyle={ButtonStyles.TERTIARY}
              onClick={onClickEndDay}
            >
              End Day
            </Button>
          )}
        </div>,
      ];
    });

    const columns = [
      { heading: 'Date and Time', width: '20%' },
      { heading: 'Starting', width: '15%' },
      { heading: 'Expected', width: '15%' },
      { heading: 'End of Day', width: '15%' },
      { heading: 'Amount to Deposit', width: '15%' },
      { heading: '', width: '20%' },
    ];
    return (
      <ScrollableTable
        columns={columns}
        rows={floats}
        keys={this.props.cashFloats.map(cashFloat => cashFloat.id)}
      />
    );
  }

  render() {
    return (
      <div>
        {this.props.cashFloats.length ? (
          this.renderTable()
        ) : (
          <NoItemsMessage>There are no cash floats recorded.</NoItemsMessage>
        )}
      </div>
    );
  }
}

CashFloatTable.propTypes = {
  cashFloats: array,
  onClickEndDay: func,
  netCash: number,
};
