import React, { Component, Fragment } from 'react';
import { array, func, bool } from 'prop-types';
import { pub, Event } from 'lib/events';
import Button, { ButtonStyles } from 'components/shared/Button';
import ScrollableTable from 'components/shared/ScrollableTable';
import Icon from 'components/shared/Icon';
import FormattedDateTime from 'components/shared/FormattedDateTime';
import LineItem from 'components/shared/LineItem';
import TinyPill from 'components/shared/TinyPill';

export default class SalesTable extends Component {
  static propTypes = {
    sales: array.isRequired,
    onTrashCanClick: func.isRequired,
    isLoading: bool.isRequired,
  };

  getCustomerNameFromSale = sale => {
    if (!sale.customer) {
      return 'N/A';
    } else if (sale.customer.full_name.trim().length) {
      return sale.customer.full_name;
    } else {
      return sale.customer.email;
    }
  };

  render() {
    const sales = this.props.sales.map(sale => [
      <div>
        {sale.associate_full_name}
        <br />
        <FormattedDateTime>{sale.created_at}</FormattedDateTime>
      </div>,
      <Fragment>
        {this.getCustomerNameFromSale(sale)}
        {sale.from_endless && (
          <TinyPill color="red-4" className="db mt1">
            Endless Aisle
          </TinyPill>
        )}
      </Fragment>,
      <div className="flex flex-column">
        {sale.sale_items.map((item, index) => (
          <LineItem
            key={item.id}
            fulfillmentMethodKey={item.fulfillment_method_key}
            source={item.source}
            imageUrl={item.image_url}
            title={item.title}
            size={item.size}
            color={item.color}
            upc={item.upc}
            quantity={1}
            orderableState={item.variant_orderable_state}
            restockDate={item.variant_restock_date}
            isFirstItem={index === 0}
            isLastItem={index === sale.sale_items.length - 1}
          />
        ))}
      </div>,
      <div className="flex justify-end items-center">
        <Icon
          glyph="trash-can"
          color="gray-6"
          className="icon"
          size="large"
          onClick={() => this.props.onTrashCanClick(sale.number)}
        />

        <Button
          to={`/storefronts/${this.props.storefrontId}/sales/${sale.number}/product`}
          className="fr"
          size="medium"
          buttonStyle={ButtonStyles.QUATERNARY}
          disabled={this.props.isLoading}
          onClick={() => {
            pub(Event.SalesHome.CONTINUE_SALE, { sale_number: sale.number });
          }}
        >
          Continue Sale
        </Button>
      </div>,
    ]);

    const columns = [
      { heading: 'Ambassador, Time', width: '20%' },
      { heading: 'Customer', width: '18%' },
      { heading: 'Items in Cart', width: '37%' },
      { heading: '', width: '25%' },
    ];

    return (
      <ScrollableTable
        columns={columns}
        rows={sales}
        keys={this.props.sales.map(sale => sale.id)}
      />
    );
  }
}
