import React, { Children } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

function SlideRightLeft({ children, in: inProp, duration }) {
  const defaultStyle = {
    transition: `transform ${duration}ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    transform: 'translateX(-100%)',
  };

  const transitionStyles = {
    entering: { transform: 'translateX(-100%)' },
    entered: { transform: 'translateX(0)' },
  };

  return (
    <Transition appear unmountOnExit in={inProp} timeout={duration}>
      {state =>
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>}
    </Transition>
  );
}

export default function SlideRightLeftGroup({
  children,
  className,
  speed = 'average',
}) {
  const duration = {
    slow: 375,
    average: 250,
    fast: 125,
  }[speed];

  return (
    <TransitionGroup className={className}>
      {Children.map(children, child =>
        <SlideRightLeft duration={duration}>
          {child}
        </SlideRightLeft>,
      )}
    </TransitionGroup>
  );
}
