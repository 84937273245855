export default function storefronts(state = [], action) {
  switch (action.type) {
    case 'RECEIVE_STOREFRONTS': {
      return action.payload;
    }

    case 'RECEIVE_STOREFRONT': {
      return [
        ...state.filter(storefront => storefront.id !== action.payload.id),
        action.payload,
      ];
    }

    default:
      return state;
  }
}

export function getStorefrontById(storefronts, storefrontId) {
  return storefronts.find(storefront => storefront.id === Number(storefrontId));
}
