export function getGenderOfReturnUnit(returnUnit) {
  return returnUnit.sku[0];
}

export function isReturnUnitOfGender(returnUnit, gender) {
  return returnUnit.sku[0] === gender;
}

export function isInFirstTryProgram(returnUnit) {
  return returnUnit.single_return_variant;
}

export function hasAlreadyUsedFirstTryForGender(returnUnits, gender) {
  return returnUnits.some(
    unit =>
      isReturnUnitOfGender(unit, gender) &&
      isInFirstTryProgram(unit) &&
      !unit.first_try_allowed,
  );
}

export function isSpecialCase(returnUnit) {
  return (
    returnUnit.selected_return_reasons.length > 0 &&
    ['Incorrect Product', 'Damaged or Defective', 'Changed My Mind'].includes(
      returnUnit.selected_return_reasons[0].heading,
    )
  );
}

export function isEligibleForFirstTry(returnUnit, returnUnits, gender) {
  if (hasAlreadyUsedFirstTryForGender(returnUnits, gender)) {
    return false;
  }

  return (
    isInFirstTryProgram(returnUnit, gender) &&
    !isSpecialCase(returnUnit) &&
    // We only want to mark this item once they select a reason
    returnUnit.selected_return_reasons.length > 0
  );
}

export function isFirstEligibleFirstTryUnit(
  returnUnit,
  allReturnUnits,
  gender,
) {
  const eligibleUnits = allReturnUnits.filter(unit =>
    isEligibleForFirstTry(unit, allReturnUnits, gender),
  );

  if (eligibleUnits.length === 0) {
    return false;
  } else {
    return returnUnit === eligibleUnits[0];
  }
}

export function isExtraFirstTry(returnUnit, allReturnUnits, gender) {
  if (hasAlreadyUsedFirstTryForGender(allReturnUnits, gender)) {
    return false;
  }

  return (
    isEligibleForFirstTry(returnUnit, allReturnUnits, gender) &&
    !isFirstEligibleFirstTryUnit(returnUnit, allReturnUnits, gender)
  );
}

export function isOpened(returnUnit, lineItemsMarkedAsOpened) {
  return lineItemsMarkedAsOpened.includes(returnUnit.line_item_id);
}

export function hasLimitedReturnReasons(returnUnit, allReturnUnits) {
  if (!isInFirstTryProgram(returnUnit)) {
    return false;
  }

  // if every item in the return is special case, but they are all also eligible for
  // first try, then we should allow the associate to select other reasons (since they
  // may have selected a special case reason by mistake)
  if (allReturnUnits.every(isSpecialCase)) {
    return false;
  }

  // however, if there are some that are not special case, then we should still restrict reasons.
  if (isSpecialCase(returnUnit)) {
    return true;
  }

  if (
    hasAlreadyUsedFirstTryForGender(
      allReturnUnits,
      getGenderOfReturnUnit(returnUnit),
    )
  ) {
    return true;
  }

  if (
    isExtraFirstTry(
      returnUnit,
      allReturnUnits,
      getGenderOfReturnUnit(returnUnit),
    )
  ) {
    return true;
  }

  return false;
}

export function getReturnReasons(
  returnUnit,
  allReturnUnits,
  lineItemsMarkedAsOpened,
) {
  let reasonsByName = hasLimitedReturnReasons(returnUnit, allReturnUnits)
    ? ['incorrect', 'defect']
    : returnUnit.available_return_reasons.map(reason => reason.name);

  if (!isOpened(returnUnit, lineItemsMarkedAsOpened)) {
    reasonsByName.push('changed_my_mind');
  }

  return returnUnit.available_return_reasons.filter(reason =>
    reasonsByName.includes(reason.name),
  );
}

export const TestHelpers = {
  getGenderOfReturnUnit,
  isReturnUnitOfGender,
  isInFirstTryProgram,
  hasAlreadyUsedFirstTryForGender,
  isSpecialCase,
  isEligibleForFirstTry,
  isFirstEligibleFirstTryUnit,
  isExtraFirstTry,
  isOpened,
  hasLimitedReturnReasons,
};
