import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import AnimatedSwitch from 'components/shared/AnimatedSwitch';
import EmailAvailability from 'containers/customers/EmailAvailability';
import SignUpCard from 'containers/customers/SignUpCard';
import SignUpSuccess from 'components/customers/SignUpSuccess';
import Waitlist from 'containers/customers/Waitlist';
import WaitlistSuccess from 'containers/customers/WaitlistSuccess';

export default class CustomersInterface extends Component {
  state = { isNewCustomer: false };

  handleSetNewCustomer = () => {
    this.setState({ isNewCustomer: true });
  };

  render() {
    return (
      <AnimatedSwitch>
        <Route
          path={`${this.props.match.path}/waitlist`}
          component={Waitlist}
        />

        <Route
          path={`${this.props.match.path}/waitlist-success`}
          component={WaitlistSuccess}
        />

        <Route
          path={`${this.props.match.path}/availability`}
          component={EmailAvailability}
        />

        <Route
          path={`${this.props.match.path}/new`}
          render={routeProps => (
            <SignUpCard
              {...routeProps}
              onSetNewCustomer={this.handleSetNewCustomer}
            />
          )}
        />

        <Route
          path={`${this.props.match.path}/success`}
          render={routeProps => (
            <SignUpSuccess
              {...routeProps}
              isNewCustomer={this.state.isNewCustomer}
            />
          )}
        />
      </AnimatedSwitch>
    );
  }
}
