import React from 'react';
import classNames from 'classnames';

export default function CreditToggle({
  onClick,
  isApplied,
  availableCredit, // `45`
  appliedCredit, // `"$45"`
  discountItemsRemaining,
  type,
  disabled,
}) {
  const amountOfAppliedCredit = (() => {
    if (appliedCredit.startsWith('-')) {
      return parseFloat(appliedCredit.slice(2), 10);
    } else {
      return parseFloat(appliedCredit.slice(1), 10);
    }
  })();

  const amountOfRemainingCredit = availableCredit - amountOfAppliedCredit;

  let title, description

  if (type === 'credits') {
    title = `$${availableCredit.toFixed(2)} Total Credits`
    description = (
      <span>
        <strong>Applied:</strong> {appliedCredit}
        <br /> <strong>Remaining:</strong> ${amountOfRemainingCredit.toFixed(2)}
      </span>
    )
  }
  if (type === 'retailDiscount') {
    title = 'Additional Retail Staff Credit'
    description = (
      `60% off \n ${discountItemsRemaining} items remaining this month`
    )
  }
  if (type === 'hqDiscount') {
    title = 'Additional HQ Staff Credit'
    description = (
      `50% off \n ${discountItemsRemaining} items remaining this quarter`
    )
  }

  return (
    <div
      onClick={!disabled && onClick}
      className={classNames('col3 ba pa2 ml3 mv2 ', {
        'bg-blue-9 b--blue-2': isApplied,
        'bg-white b--gray-9': !isApplied,
        'bg-gray-8 b--gray-8': disabled,
      })}
    >
      <p className="fw5 ma0 mb1">{title}</p>
      <p className="f5 ma0">{description}</p>
    </div>
  );
}
