import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

import Button, { ButtonStyles } from 'components/shared/Button'

const StripeCreditCardForm = ({ customer, onSubmit, isMobile }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = useState()

  const handleSubmit = async e => {
    e.preventDefault()
    setError(null)

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
        payment_method_data: {
          billing_details: {
            email: customer.email,
            name: customer?.full_name,
          },
        },
      },
      redirect: 'if_required',
    })

    if (error) {
      setError(error.message)
    } else {
      onSubmit(setupIntent?.payment_method)
    }
  }

  // Early return if stripe.js hasn't loaded yet
  if (!stripe || !elements) {
    return null
  }

  return (
    <div className={isMobile ? 'w-100' : 'w-75'}>
      <form onSubmit={handleSubmit} className="auto">
        <PaymentElement
          options={{
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: true,
            },
            wallets: { applePay: 'never', googlePay: 'never' },
          }}
        />
        <div className="mt2">
          {error && (
            <p className="red-5 tc">{error}</p>
          )}
          <Button type="submit" buttonStyle={ButtonStyles.SECONDARY} disabled={!stripe} className="w-100">
            Add Credit Card
          </Button>
        </div>
      </form>
    </div>
  )
}

export default StripeCreditCardForm
