import React from 'react';
import classNames from 'classnames';
import { pubWithReturn, Event } from 'lib/events';
import { staticImageUrl } from 'lib/url';
import Icon from 'components/shared/Icon';

function ReasonSelector({
  reasons,
  selectedReason,
  onSelectReturnReason,
  className,
  allowEditing = true,
  availableInStore,
}) {
  const shouldReturnToFloor = selectedReason => {
    if (!selectedReason || !availableInStore) {
      return false;
    }

    return !['Damaged or Defective', 'Incorrect Product'].includes(
      selectedReason.heading,
    );
  };

  return (
    <div
      className="relative mr2 w-100"
      onClick={() => pubWithReturn(Event.Cart.OPEN_RETURN_REASON_DROPDOWN)}
    >
      {allowEditing && (
        <span
          className="absolute f3 fw1 gray-6"
          style={{ top: '1.1rem', right: '0.2rem' }}
        >
          ⌄
        </span>
      )}
      <label
        className="db f6 gray-6 tl lh-solid mb0 h2 flex items-center"
        htmlFor="reason-selector"
        style={{ marginBottom: '0.3rem' }}
      >
        Return Reason*:
      </label>
      {allowEditing ? (
        <select
          type="text"
          className={classNames(
            'ba b--gray-9 br0 input-reset bg-white f6 ph1 w-100 h3 sans-serif',
            className,
          )}
          name="reason-selector"
          value={selectedReason ? selectedReason.id : ''}
          onChange={onSelectReturnReason}
        >
          <option value="" />
          {reasons.map(({ heading, id }) => (
            <option key={id} value={id}>
              {heading}
            </option>
          ))}
        </select>
      ) : (
        <span className="db f6 gray-6 tl lh-solid mb0 h2 flex items-center">
          {selectedReason.heading}
        </span>
      )}

      {shouldReturnToFloor(selectedReason) && (
        <span className="mt1 br4 bg-red-5 white f6 h3 flex justify-center content-center w-70">
          In Assortment
        </span>
      )}
    </div>
  );
}

export default function ReceivedReturnUnit({
  id,
  title,
  price,
  originalPrice,
  size,
  upc,
  color,
  source,
  imageUrl,
  isLastItem,
  availableReturnReasons,
  availableInStore,
  selectedReturnReasons,
  allowEditing = true,
  onSelectReturnReason,
  onDelete,
}) {
  const toNumber = string => parseFloat(string.replace('$', ''));

  let discountPercent = null;
  if (price !== originalPrice) {
    discountPercent =
      100 - Math.floor((toNumber(price) / toNumber(originalPrice)) * 100);
  }

  return (
    <div
      className={classNames('pa1 relative', {
        'bb b--gray-9': !isLastItem,
      })}
    >
      {allowEditing && (
        <Icon
          color="gray-2"
          glyph="x"
          className="f6 absolute"
          style={{ right: '4px' }}
          onClick={onDelete}
        />
      )}
      <div className="f5 tl pr2 lh-copy pb1">{title}</div>
      <div className="flex relative">
        <div className="mr1" style={{ flexShrink: 0, width: '128px' }}>
          <img alt={title} src={staticImageUrl(imageUrl, { size: 128 })} />
        </div>
        <div>
          <p className="ma0 f4 tl">Refund: {price}</p>
          {discountPercent !== null && (
            <p className="ma0 f6 gray-6 tl lh-copy">
              Purchased at {discountPercent}% off,
              <br />
              originally {originalPrice}
            </p>
          )}
          <p className="ma0 f6 gray-6 tl lh-copy">
            Size: {size === 'ONE SIZE' ? 'OS' : size}
          </p>
          <p className="ma0 f6 gray-6 tl lh-copy">UPC: {upc}</p>
          <p className="f6 h2 gray-6 tl ma0 flex items-center">
            Source:&nbsp;
            <span className={source === 'Online' ? 'blue-5' : 'violet-7'}>
              {source}
            </span>
          </p>

          {allowEditing && (
            <ReasonSelector
              availableInStore={availableInStore}
              reasons={availableReturnReasons}
              selectedReason={selectedReturnReasons[0]}
              allowEditing={allowEditing}
              onSelectReturnReason={event =>
                onSelectReturnReason(id, event.target.value)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
