import { objectToQueryParams } from 'lib/url';

function stringHasher(str) {
  var i, j, ref, result;
  result = 0;
  for (
    i = j = 0, ref = str.length;
    0 <= ref ? j < ref : j > ref;
    i = 0 <= ref ? ++j : --j
  ) {
    result = 31 * result + str.charCodeAt(i);
    result %= 0x100000000;
  }
  return result;
}

export default function imageUrl(filename = '', opts = {}) {
  const hosts = ['everlane.imgix.net', 'everlane-2.imgix.net'];
  const isSvg = filename.split('.').pop() === 'svg';

  let options = {
    ...{
      dpr: 2,
      quality: 85,
    },
    ...opts,
  };

  if (options.size) {
    options.width || (options.width = options.size);
    options.height || (options.height = options.size);
    delete options.size;
  }

  if (options.width) {
    options.w = options.width;
    delete options.width;
  }

  if (options.height) {
    options.h = options.height;
    delete options.height;
  }

  if (options.quality) {
    options.q = options.quality;
    delete options.quality;
  }

  if (options.smartCrop) {
    options.fit = 'crop';
    options.crop = 'faces';
    delete options.smartCrop;
  }

  if (filename.indexOf('i/') !== 0) {
    filename = 'static/' + filename;
  }

  const url = `//${hosts[stringHasher(filename) % hosts.length]}/${filename}`;

  if (options.original || isSvg) {
    return url;
  } else {
    return `${url}${objectToQueryParams(options)}`;
  }
}
