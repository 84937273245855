import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pubWithSale, Event } from 'lib/events';
import { getSaleByNumber } from 'reducers/sales';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import RotateTablet from 'components/customers/RotateTablet';
import logo from 'svg/logo.svg';

export class ShippingConfirmation extends Component {
  componentDidMount() {
    pubWithSale(Event.SaleShippingSuccess.PAGE_VIEW);
  }

  sendCustomerToMostAppropriateStep = () => {
    const { saleNumber, storefrontId } = this.props.match.params;
    this.props.history.push(`/storefronts/${storefrontId}/sales/${saleNumber}`);
  };

  render() {
    const { params } = this.props.match;
    return (
      <div>
        <TopBar
          center={<img src={logo} alt="Welcome to Everlane" className="w16" />}
          left={
            <Link
              to={`/storefronts/${params.storefrontId}/sales/${params.saleNumber}/shipping`}
              className="no-underline"
              onClick={() =>
                pubWithSale(Event.SaleShippingSuccess.BACK_TO_SHIPPING_OPTIONS)
              }
            >
              <IconWithLabel
                icon="arrow-left"
                iconClassName="f5 pr1"
                label={
                  this.props.isMobile ? 'Back' : 'Select a Shipping Option'
                }
                color="black"
              />
            </Link>
          }
          right={
            <Link
              to={`/storefronts/${params.storefrontId}/sales/${params.saleNumber}`}
              className="no-underline flex justify-end"
              onClick={() =>
                pubWithSale(Event.SaleShippingSuccess.RETURN_TO_SALE)
              }
            >
              <IconWithLabel
                color="black"
                icon="arrow-right"
                className="flex-row-reverse"
                iconClassName="f5 pl1"
                label={this.props.isMobile ? 'Continue' : 'Return To Sale'}
              />
            </Link>
          }
        />
        <div className="tc center-vh flex flex-column items-center">
          {!this.props.isMobile && <RotateTablet size="230px" />}
          <p className="ma0 mb2 f4 col4 tc mt5 pl2 lh-double">
            Great, thanks!
            <br />
            {`Please return the ${
              this.props.isMobile ? 'phone' : 'screen'
            } to the associate to complete the sale.`}
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params;

  return {
    sale: getSaleByNumber(state.sales, saleNumber),
    isMobile: state.ui.isMobile,
  };
}

export default connect(mapStateToProps)(ShippingConfirmation);
