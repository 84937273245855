import React, { Component } from 'react';
import { func, bool } from 'prop-types';
import Button, { ButtonStyles } from 'components/shared/Button';
import classNames from 'classnames';

export default class DiscountCodeForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    isMobile: bool,
  };

  state = {
    input: '',
    error: '',
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state.input).then(response => {
      this.setState({ input: '' });
    });
  };

  render() {
    return (
      <form
        className={classNames('flex mh3 mt2 mb6 pb6', {
          col5: this.props.isMobile,
          col7: !this.props.isMobile,
        })}
      >
        <input
          type="text"
          className="ba b--gray-6 f4 h6 ph1 col5 mr2 sans-serif"
          placeholder="Enter Gift Card Code or Promo Code"
          value={this.state.input}
          onChange={event => this.setState({ input: event.target.value })}
        />
        <Button
          size="medium"
          onClick={this.handleSubmit}
          buttonStyle={ButtonStyles.SECONDARY}
          disabled={!this.state.input}
        >
          Apply
        </Button>
      </form>
    );
  }
}
