import React, { Component } from 'react';
import { getQueryParam } from 'lib/url';
import { pub, Event } from 'lib/events';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import Icon from 'components/shared/Icon';

class WaitlistSuccess extends Component {
  saleNumber = getQueryParam('saleNumber');

  componentDidMount() {
    if (this.saleNumber) {
      pub(Event.SaleWaitlistSuccess.PAGE_VIEW);
    } else {
      pub(Event.InventoryWaitlistSuccess.PAGE_VIEW);
    }
  }

  handleContinue = () => {
    if (this.saleNumber) {
      pub(Event.SaleWaitlistSuccess.RETURN_TO_SALE);
    } else {
      pub(Event.InventoryWaitlistSuccess.RETURN_TO_INVENTORY);
    }

    this.props.history.push(
      this.saleNumber
        ? `/storefronts/${this.props.match.params.storefrontId}/sales/${this.saleNumber}/product`
        : `/storefronts/${this.props.match.params.storefrontId}/stock-check`,
    );
  };

  render() {
    const topBarHeight = 72;
    const marginSize = 32;

    return (
      <div>
        <TopBar
          center="Waitlist Success"
          right={
            <IconWithLabel
              color="black"
              onClick={this.handleContinue}
              icon="arrow-right"
              iconClassName="f5"
              labelClassName="pr1"
              label={
                this.saleNumber ? 'Return to Sale' : 'Return to Stock Check'
              }
              className="flex-row-reverse"
            />
          }
        />
        <div
          className="flex flex-column items-center justify-center h-100 ma4"
          style={{
            backgroundColor: '#FAFAFA',
            height:
              document.documentElement.clientHeight -
              topBarHeight -
              marginSize * 2,
          }}
        >
          <div
            style={{ width: '104px', height: '104px' }}
            className="flex bg-gray-2 br-100"
          >
            <Icon glyph="checkmark" className="f2 white self-center center" />
          </div>

          <p className="ma0 mt6 f4 col6 tc">
            You’re on the waitlist! We’ll email you when this item is back in
            stock.
          </p>
        </div>
      </div>
    );
  }
}

export default WaitlistSuccess;
