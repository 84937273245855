export class UPCScanner {
  scanBuffer = '';
  isScanning = false;
  isListening = false;

  constructor({ callback }) {
    this.callback = callback;
  }

  // When a UPC barcode gets scanned with a scan gun, the scan gun emulates a keyboard and will
  // "type" the contents of the barcode ("0-00000-12258-0"), followed by a carriage return. Since we
  // have no good way to tell when the input is coming from the scan gun or just a normal keyboard,
  // we assume that a string of keyboard input that looks exactly like this is indeed a scan.
  handleKeyDown = event => {
    if (event.key === '0' || event.key === 'I' || event.key === '1') {
      this.isScanning = true;
    }

    if (event.key === 'Enter') {
      // 15 is the minimum length of a complete UPC string, an IT number, and a tracking code
      if (this.scanBuffer.length >= 12) {
        this.callback(this.scanBuffer);
      }
      this.isScanning = false;
      this.scanBuffer = '';
    }

    if (this.isScanning && event.key !== 'Shift') {
      this.scanBuffer += event.key;
    }
  };

  listen() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  stopListening() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }
}

export class CreditCardSwiper {
  scanBuffer = '';
  isScanning = false;
  isListening = false;

  constructor({ callback }) {
    this.callback = callback;
  }

  // REFERENCE: http://www.gae.ucm.es/~padilla/extrawork/tracks.html
  // When a credit card is swiped with a USB credit card swiper, the device emulates a keyboard and will
  // "type" the credit card information in a kind of confusing way, with three sections.
  //
  // 1. It starts with "%B" and then types the full credit card number, followed by a "^".
  //
  // 2. After that, it types the last and first name in all caps respectively, seperated by a "/",
  //    and then types another "^".
  //
  // 3. Lastly, it types the expiration year ("16") followed by the expiration month ("07"), followed by
  //    a lot of nonsense that I don't really understand.
  //
  // Finally, it terminates its input with a ";".
  parseSwipeInput = () => {
    // It's possible that some credit card readers (or maybe the adapters) input "Dead" characters
    // between certtain characters. It's bizzare but this cleans those up.
    const formattedBuffer = this.scanBuffer.replace(/Dead/g, '');

    const [numberString, nameString, expirationString] =
      formattedBuffer.split('^');

    try {
      const exipirationYear = expirationString.slice(0, 2);
      const expirationMonth = expirationString.slice(2, 4);
      const [lastName, firstName] = nameString.split('/');

      let ccInfo = {
        number: numberString.slice(2),
        expirationDate: `${expirationMonth}/${exipirationYear}`,
      };

      if (firstName.trim().length > 0 && lastName.trim().length > 0) {
        ccInfo.fullName = `${firstName.trim()} ${lastName}`;
      }

      return ccInfo;
    } catch (error) {
      return null;
    }
  };

  handleKeyDown = event => {
    // Ignore shift key inputs
    if (event.key === 'Shift') {
      return;
    }

    if (event.key === '%') {
      this.isScanning = true;
    }

    if (event.key === ';') {
      this.callback(this.parseSwipeInput());
      this.isScanning = false;
      this.scanBuffer = '';
    }

    if (this.isScanning) {
      this.scanBuffer += event.key;
    }
  };

  listen() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  stopListening() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }
}
