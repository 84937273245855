import React, { Component, Fragment } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { get, post } from 'lib/network';
import { retailApiUrl } from 'lib/url';
import { isUpc } from 'lib/validation';
import { getIsLoading } from 'reducers/activeRequests';
import { getSaleByNumber } from 'reducers/sales';
import { getStorefrontById } from 'reducers/storefronts';
import { showNotification } from 'actions/synchronous';
import { NotificationTypes } from 'reducers/notifications';
import {
  showDialog,
  hideDialog,
  startRequest,
  finishRequest,
} from 'actions/synchronous';
import Scrollable from 'components/shared/Scrollable';
import ProductSearchFieldV2 from 'components/product-search/ProductSearchFieldV2';
import ItemSummary from 'components/sales/mobile/ItemSummary';
import InstantSearchWrapper from 'components/product-search/algolia';
import ProductSearchResults from 'components/product-search/ProductSearchResults';
import { UPCScanner } from 'lib/keyboard';
import ProductSearchHit from 'components/product-search/ProductSearchHit';
import ProductSearchHits from 'components/product-search/ProductSearchHits';

class ProductSearch extends Component {
  static propTypes = {
    onContinueMobile: func,
    isMobile: bool,
    addToSaleOnExactMatch: bool,
  };

  static defaultProps = {
    addToSaleOnExactMatch: true,
  };

  state = {
    products: [],
    hasSearchedForProducts: false,
    onlyShowVisibleProducts: true,
    page: 1,
    requestedItems: [],
    isMobileSummaryExpanded: false,
    upc: null,
    exactMatchingVariant: null,
    exactMatchingProduct: null,
    searchText: null,
  };

  rootPath = `/storefronts/${this.props.match.params.storefrontId}`;

  constructor(props) {
    super(props);

    this.scanner = new UPCScanner({
      callback: scanResult => {
        this.handleQueryChange(scanResult);
      },
    });
  }

  componentDidMount() {
    this.scanner.listen();
  }

  componentWillUnmount() {
    this.scanner.stopListening();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.searchText !== this.state.searchText &&
      isUpc(this.state.searchText)
    ) {
      this.executeUPCSearch();
    }
  }

  async executeUPCSearch() {
    this.props.startRequest();
    const { response, responseJson } = await get(
      retailApiUrl(
        `storefronts/${this.props.storefront.id}/storefront_products`,
      ),
      {
        query: this.state.searchText,
        page: 1,
        limit: 100,
        only_show_visible_products: false,
      },
    );
    this.props.finishRequest();

    if (response.ok) {
      const { products, exact_matching_variant } = responseJson;
      let exactMatchingProduct;
      let exactMatchingVariant;
      products.forEach(product => {
        product.variants.forEach(variant => {
          if (
            exact_matching_variant &&
            exact_matching_variant.upc === variant.upc
          ) {
            exactMatchingProduct = this.convertProductFormat(product);
            exactMatchingVariant = variant;
          }
        });
      });

      this.setState({
        products,
        exactMatchingVariant,
        exactMatchingProduct,
      });
    }
  }

  handleQueryChange = query => {
    if (isUpc(query)) {
      this.setState({ searchText: query });
    } else {
      this.setState({
        searchText: query,
        exactMatchingProduct: null,
        exactMatchingVariant: null,
      });
    }
  };

  handleOnlyShowVisibleProductsChange = event => {
    this.setState({
      onlyShowVisibleProducts: !event.target.checked,
      page: 1,
    });
  };

  handleRequestToTry = async variant => {
    this.setState({ isLoading: true });
    try {
      await post(
        retailApiUrl(
          `/storefronts/${this.props.storefront.id}/storefront_tryon_requests`,
        ),
        {
          variant_id: variant.variant_id,
          product_id: variant.product_id,
        },
      );

      this.setState(prevState => {
        return {
          requestedItems: [...prevState.requestedItems, variant.variant_id],
          isLoading: false,
        };
      });
    } catch (err) {
      showNotification(NotificationTypes.ERROR, err.message);
    }
  };

  isSale() {
    return this.props.match.params.saleNumber;
  }

  toggleMobileSummary = () => {
    this.setState(prevState => ({
      isMobileSummaryExpanded: !prevState.isMobileSummaryExpanded,
    }));
  };

  // adjusting products to match format of products from algolia
  convertProductFormat = product => {
    return {
      ...product,
      primary_image_url: product.image_url,
      price: product.price.split('$')[1],
      original_price: product.original_price.split('$')[1],
      gender: { "Women's": 'female', "Men's": 'male', Unisex: 'unisex' }[
        product.category
      ],
      objectID: product.id,
    };
  };

  renderUPCResults = () => {
    const hasExactMatchingVariant =
      this.state.exactMatchingProduct && this.state.exactMatchingVariant;

    const upcProducts = this.state.products.map(this.convertProductFormat);

    return (
      <Scrollable>
        {hasExactMatchingVariant && (
          <ProductSearchHit
            product={this.state.exactMatchingProduct}
            matchingVariant={this.state.exactMatchingVariant}
            index={0}
            storefront={this.props.storefront}
            sale={this.props.sale}
            key="exact-matching-product"
            isUPCSearch
          />
        )}
        {upcProducts && upcProducts.length > 0 && (
          <div
            className={classNames('pv2 bt b--gray-9', {
              ph1: this.props.isMobile,
              'pr2 mt1': !this.props.isMobile,
            })}
          >
            <div
              className={classNames('fw5', {
                pl1: this.props.isMobile,
                pl3: !this.props.isMobile,
              })}
            >
              Similar Items
            </div>
            <ProductSearchHits
              upcProducts={upcProducts}
              storefront={this.props.storefront}
              sale={this.props.sale}
            />
          </div>
        )}
      </Scrollable>
    );
  };

  renderSearchBody = ({
    shouldShowInactiveProducts,
    setShowInactiveProducts,
  }) => {
    return (
      <Fragment>
        <ProductSearchFieldV2
          isMobile={this.props.isMobile}
          //handleClearUPC={this.handleClearUPC}
          onQueryChange={this.handleQueryChange}
        />

        {isUpc(this.state.searchText) ? (
          this.renderUPCResults()
        ) : (
          <Scrollable
            key={
              this.state.searchText +
              this.state.onlyShowVisibleProducts +
              this.state.page +
              this.state.isMobileSummaryExpanded
            }
          >
            <ProductSearchResults
              isMobile={this.props.isMobile}
              storefront={this.props.storefront}
              sale={this.props.sale}
              shouldShowInactiveProducts={shouldShowInactiveProducts}
              setShowInactiveProducts={setShowInactiveProducts}
            />
          </Scrollable>
        )}

        {this.props.isMobile && this.isSale() && (
          <ItemSummary
            sale={this.props.sale}
            onContinue={this.props.onContinueMobile}
            toggleMobileSummary={this.toggleMobileSummary}
            isExpanded={this.state.isMobileSummaryExpanded}
          />
        )}
      </Fragment>
    );
  };

  render() {
    return <InstantSearchWrapper>{this.renderSearchBody}</InstantSearchWrapper>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoading: getIsLoading(state.activeRequests),
    sale: getSaleByNumber(state.sales, ownProps.match.params.saleNumber),
    storefront: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ),
    isMobile: state.ui.isMobile,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    showNotification: (key, message) =>
      dispatch(showNotification(key, message)),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductSearch),
);
