import React, { Component } from 'react';
import { pub, Event } from 'lib/events';
import Header from 'containers/Header';
import NoItemsMessage from 'components/shared/NoItemsMessage';
import SubHeader from 'components/shared/SubHeader';
import FormattedDateTime from 'components/shared/FormattedDateTime';
import { getIncompleteSales } from 'reducers/sales';
import { staticImageUrl } from 'lib/url';
import { connect } from 'react-redux';
import { getIsLoading } from 'reducers/activeRequests';
import { fetchSales, startNewSale, deleteSale } from 'actions/asynchronous';
import Button, { ButtonStyles } from 'components/shared/Button';
import Scrollable from 'components/shared/Scrollable';
import Icon from 'components/shared/Icon';

class IncompleteSales extends Component {
  componentDidMount() {
    this.props.fetchSales();
    pub(Event.SalesHome.PAGE_VIEW);

    this.pollingInterval = setInterval(this.props.fetchSales, 30000);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.storefrontId !==
      this.props.match.params.storefrontId
    ) {
      this.props.fetchSales();
      clearInterval(this.pollingInterval);
      this.pollingInterval = setInterval(this.props.fetchSales, 30000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  getCustomerNameFromSale = sale => {
    if (!sale.customer) {
      return 'N/A';
    } else if (sale.customer.full_name.trim().length) {
      return sale.customer.full_name;
    } else {
      return sale.customer.email;
    }
  };

  handleDeleteSale = saleNumber => {
    pub(Event.SalesHome.TRASH_SALE);
    if (window.confirm('Are you sure you want to cancel this sale?')) {
      this.props.deleteSale(saleNumber);
    }
  };

  handleStartNewSale = () => {
    pub(Event.SalesHome.START_SALE);

    this.props.startNewSale().then(response => {
      if (response?.sale) {
        this.props.history.push(
          `${this.props.match.url}/${response.sale.number}/product`,
        );
      }
    });
  };

  renderSales = () => {
    return this.props.sales.map((sale, index) => {
      const onDelete = () => this.handleDeleteSale(sale.number);
      return (
        <div className="bb b--gray-9 pa2" key={index}>
          <div className="mb1 relative">
            {`Sale ${sale.number} · `}
            <span>
              <FormattedDateTime format="short">
                {sale.created_at}
              </FormattedDateTime>
            </span>
            <div className="gray-6 pt1 f5">
              Associate:{' '}
              <span className="gray-2">{sale.associate_full_name}</span>
              <span className="ph1 black">|</span>
              Customer:{' '}
              <span className="gray-2">
                {this.getCustomerNameFromSale(sale)}
              </span>
            </div>
            <div className="absolute f3 h3 w3 top-0 right-0">
              <Icon
                glyph="trash-can"
                color="gray-6"
                className="h-inherit pa0"
                onClick={onDelete}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap mb1">
            {sale.sale_items.length > 0 ? (
              sale.sale_items.map(item => {
                const itemTitle = item.title.split(' - ');

                return (
                  <div key={item.id} style={{ flex: '0 45%' }} className="ma1">
                    <img
                      alt={item.title}
                      src={staticImageUrl(item.image_url, { size: 140 })}
                    />
                    <div className="lh-title">
                      <div className="f5 pb1">{itemTitle[0]}</div>
                      <div className="gray-6 f6">{itemTitle[1]}</div>
                      <div className="gray-6 f6">Size: {item.size}</div>
                    </div>
                  </div>
                );
              })
            ) : (
              <span className="gray-6">No items</span>
            )}
          </div>
          <Button
            className="w-100 border-box"
            to={`/storefronts/${this.props.match.params.storefrontId}/sales/${sale.number}/product`}
            buttonStyle={ButtonStyles.QUATERNARY}
            disabled={this.props.isLoading}
            onClick={() => {
              pub(Event.SalesHome.CONTINUE_SALE, {
                sale_number: sale.number,
              });
            }}
          >
            Continue Sale
          </Button>
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <Header
          ctaGlyph="plus"
          onCtaClick={this.handleStartNewSale}
          ctaDisabled={this.props.isLoading}
          textColor="white"
        />
        <SubHeader>Incomplete Sales</SubHeader>

        {this.props.sales.length === 0 ? (
          <NoItemsMessage>
            There are no incomplete sales. You can start a new sale above.
          </NoItemsMessage>
        ) : (
          <Scrollable>{this.renderSales()}</Scrollable>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sales: getIncompleteSales(state.sales),
    isLoading: getIsLoading(state.activeRequests),
    uniqueId: state.uniqueId,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const storefrontId = ownProps.match.params.storefrontId;

  return {
    fetchSales: () => dispatch(fetchSales(storefrontId)),
    startNewSale: () => dispatch(startNewSale(storefrontId)),
    deleteSale: saleNumber => dispatch(deleteSale(saleNumber)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteSales);
