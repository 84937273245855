import React from 'react';
import IconWithLabel from 'components/shared/IconWithLabel';

export default function SidebarMainMenu({
  showSwitchStorefront,
  onSwitchStorefront,
  onSwitchEmployee,
  onLogOut,
  onReload,
}) {
  return (
    <div className="flex flex-column justify-center bb b--gray-9">
      {showSwitchStorefront && (
        <IconWithLabel
          onClick={onSwitchStorefront}
          iconPosition="right"
          color="gray-2"
          icon="arrow-right"
          iconClassName="f4"
          labelClassName="pr2"
          label="Change Storefront"
          className="justify-between pa2"
        />
      )}
      <IconWithLabel
        iconPosition="right"
        color="gray-2"
        onClick={onSwitchEmployee}
        iconClassName="f4"
        labelClassName="pr2"
        label="Change Employee"
        className="justify-between pa2"
      />
      <IconWithLabel
        iconPosition="right"
        color="gray-2"
        onClick={onReload}
        iconClassName="f4"
        labelClassName="pr2"
        label="Reload"
        className="justify-between pa2"
      />
      <IconWithLabel
        iconPosition="right"
        color="gray-2"
        onClick={onLogOut}
        iconClassName="f4"
        labelClassName="pr2"
        label="Log Out"
        className="justify-between pa2"
      />
    </div>
  );
}
