import React from 'react';
import { staticImageUrl } from 'lib/url';

import Button from 'components/shared/Button';

export default function InventoryChangesetTile({
  inventoryChangeset,
  storefront,
  onDelete,
  reason = 'Cycle Count',
}) {
  const { id: changeset_id, inventory_changes } = inventoryChangeset || {}
  const { display_name, image_url } = inventory_changes[0] || {}

  let currentCount = 0
  let expectedAmount = 0
  // Iterate through each changeset in the IT to get a sum of counts + expected
  inventory_changes.forEach(itc => {
    currentCount += itc.amount
    expectedAmount += itc.expected
  })

  const scannedLabel = reason == 'Cycle Count' ? 'Scanned' : 'Damaged items scanned'
  const expectedLabel = reason == 'Cycle Count' ? 'Expected' : 'Available'
  const action = reason == 'Cycle Count' ? 'cycle-counts' : 'damages'

  return (
    <div className="bb pt3 pb2 center" style={{ maxWidth: '500px' }}>
      <div className="flex mb2 justify-between">
        <img
          className="w15 h15 mr1"
          src={staticImageUrl(image_url, {
            size: 100,
          })}
          alt={display_name}
        />
        <div>
          <p className="mt0">{display_name}</p>
          <p className="mv0 f5 lh-copy">Sizes: {inventory_changes?.length || 0}</p>
          <p className="mv0 f5 lh-copy">{scannedLabel}: {Math.abs(currentCount)}</p>
          <p className="mv0 f5 lh-copy">{expectedLabel}: {expectedAmount}</p>
        </div>
      </div>
      <div className="flex justify-around mb1">
        <Button
          outlined
          className="fr f5 mr2 b--black"
          size="small"
          color="black"
          onClick={() => {
            window.location = `/storefronts/${storefront.id}/${action}/${changeset_id}`
          }}
        >
          Continue Count
        </Button>
        <Button
          outlined
          className="fr f5 ml2 b--red-4 bg-red-5"
          size="small"
          color="white"
          onClick={() => {
            if (window.confirm('Are you sure you want to delete the current inventory count?')) {
              onDelete(changeset_id)
            }
          }}
        >
          Delete Count
        </Button>
      </div>
    </div>
  );
}
