import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FadeIn from 'components/shared/transitions/FadeIn';

function AnimatedSwitch({ children }) {
  return (
    <Route
      render={({ location }) => (
        <FadeIn>
          <Switch key={location.key} location={location}>
            {children}
          </Switch>
        </FadeIn>
      )}
    />
  );
}

export default AnimatedSwitch;
