const successSound = new Audio(
  'https://media.everlane.com/video/upload/v1659933307/Complete_3_adeg4e.m4a',
);

const errorSound = new Audio(
  'https://media.everlane.com/video/upload/v1659933283/Error_3_hnlouz.m4a',
);

export const playErrorSound = () => {
  try {
    const soundPromise = errorSound.play();
    if (typeof soundPromise !== 'undefined') {
      soundPromise.catch(() => {});
    }
  } catch (err) {
    return null;
  }
}

export const playSuccessSound = () => {
  try {
    const soundPromise = successSound.play();
    if (typeof soundPromise !== 'undefined') {
      soundPromise.catch(() => {});
    }
  } catch (err) {
    return null;
  }
}

