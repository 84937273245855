import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isUpc } from 'lib/validation';
import { get, post } from 'lib/network';
import { retailApiUrl } from 'lib/url';
import { playErrorSound, playSuccessSound } from 'lib/sound';
import {
  startRequest,
  finishRequest,
  showNotification,
} from 'actions/synchronous';
import { getStorefrontById } from 'reducers/storefronts';
import { NotificationTypes } from 'reducers/notifications';
import Button, { ButtonStyles } from 'components/shared/Button';
import Scrollable from 'components/shared/Scrollable';
import SearchField from 'components/inventory-transfers/SearchField';
import { staticImageUrl } from 'lib/url';

export class AuditTransfer extends Component {
  state = {
    inventoryTransfer: {},
    receipt: {
      inventory_transfer_receipt_items: [],
    },
    shouldShowScanSuccess: false,
    shouldShowScanError: false,
    shouldShowConfirmationSuccess: false,
  };

  
  scanSuccessTimeout = null;
  scanErrorTimeout = null;

  async componentDidMount() {
    this.props.startRequest();
    await this.fetchTransfer();
    await this.fetchReceipt();
    this.props.finishRequest();
  }

  componentWillUnmount() {
    clearTimeout(this.scanErrorTimeout);
    clearTimeout(this.scanSuccessTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.shouldShowScanError && this.state.shouldShowScanError) {
      playErrorSound()
      clearTimeout(this.scanErrorTimeout);
      this.scanErrorTimeout = setTimeout(() => {
        this.setState({ shouldShowScanError: false });
      }, 700);
    }
  }

  fetchReceipt = async () => {
    const { responseJson } = await get(
      retailApiUrl(
        `storefronts/${this.props.match.params.storefrontId}/inventory_transfer_receipts/${this.props.match.params.receiptId}`,
      ),
    );

    this.setState({
      receipt: responseJson,
    });
  };

  fetchTransfer = async () => {
    try {
      const { response, responseJson } = await get(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${this.props.match.params.id}`,
        ),
      );

      if (response.ok) {
        this.setState({ inventoryTransfer: responseJson });
      } else {
        this.props.showNotification(
          NotificationTypes.ERROR,
          responseJson.message,
        );
      }
    } catch (e) {
      this.props.showNotification(NotificationTypes.ERROR, e.message);
    }
  };

  confirmReceipt = async () => {
    this.props.startRequest();
    try {
      const { response, responseJson } = await post(
        retailApiUrl(
          `/storefronts/${this.props.match.params.storefrontId}/inventory_transfer_receipts/${this.state.receipt.id}/confirm`,
        ),
      );

      if (response.ok) {
        this.setState({ shouldShowConfirmationSuccess: true });
      } else {
        this.props.showNotification(
          NotificationTypes.ERROR,
          responseJson.message,
        );
      }
    } catch (e) {
      this.props.showNotification(NotificationTypes.ERROR, e.message);
    } finally {
      this.props.finishRequest();
    }
  };

  increment = async (upc, { shouldNotify = false } = {}) => {
    this.props.startRequest();

    await post(
      retailApiUrl(
        `storefronts/${this.props.storefront.id}/inventory_transfer_receipts/${this.state.receipt.id}/inventory_transfer_receipt_items/increment`,
      ),
      { upc },
    );

    // only do this if the above call works
    if (shouldNotify) {
      playSuccessSound()

      this.setState({ shouldShowScanSuccess: true }, () => {
        clearTimeout(this.scanSuccessTimeout);
        this.scanSuccessTimeout = setTimeout(() => {
          this.setState({ shouldShowScanSuccess: false });
        }, 700);
      });
    }

    await this.fetchReceipt();
    this.props.finishRequest();
  };

  decrement = async upc => {
    this.props.startRequest();

    await post(
      retailApiUrl(
        `storefronts/${this.props.storefront.id}/inventory_transfer_receipts/${this.state.receipt.id}/inventory_transfer_receipt_items/decrement`,
      ),
      { upc },
    );

    await this.fetchReceipt();
    this.props.finishRequest();
  };

  renderReceiptItem(receiptItem) {
    return (
      <div className="flex pa1 bb b--gray-8">
        <img
          className="w15 h15 mr1"
          src={staticImageUrl(receiptItem.image_url, {
            size: 100,
          })}
          alt={receiptItem.display_name}
        />
        <div className="w-100">
          <h2 className="mv0 f6 lh-copy">{receiptItem.display_name}</h2>
          <div className="grey">
            <dl className="f6 mv0 lh-copy">
              <dt className="gray-7 di">Size:</dt>{' '}
              <dd className="di mh0">{receiptItem.size}</dd>
              <br />
              <dt className="gray-7 di">UPC:</dt>{' '}
              <dd className="di mh0">{receiptItem.upc}</dd>
              <dd className="db mh0 mt1 ba br3 b--gray-8 flex justify-between items-center bg-white">
                <Button
                  className="w-20 bg-transparent"
                  buttonStyle={ButtonStyles.PRIMARY}
                  onClick={() => this.decrement(receiptItem.upc)}
                >
                  <span className="pl2 pr2 pt0 br-pill ba bw" style={{ paddingBottom: '3px' }}>
                    -
                  </span>
                </Button>
                <span
                  className={classNames('w-60 fw6 f6 h-100 tc br-pill pv1', {
                    'bg-red-9 red-3':
                      receiptItem.received_quantity >
                      receiptItem.expected_quantity,
                    'bg-gray-9 gray-3':
                      receiptItem.received_quantity <
                      receiptItem.expected_quantity,
                    'bg-green-4 white':
                      receiptItem.received_quantity ===
                      receiptItem.expected_quantity,
                  })}
                >
                  {`${receiptItem.received_quantity || 0} of ${receiptItem.expected_quantity} expected`}
                </span>
                <div className="w-10" />
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }


  render() {
    const mismatch = this.state.receipt.inventory_transfer_receipt_items.filter(
      item => item.received_quantity !== item.expected_quantity,
    );

    const complete = this.state.receipt.inventory_transfer_receipt_items.filter(
      item => item.received_quantity === item.expected_quantity,
    );

    return (
      <Fragment>
        <div className="pa1 bb b--gray-8 f5 b tc">
          Auditing {this.state.inventoryTransfer.number}
        </div>

        {this.state.shouldShowScanSuccess && (
          <div className="flash absolute f1 w-100 z-1 vh-100 bg-green-7 o-90 top-0 left-0 flex items-center justify-center"></div>
        )}

        {this.state.shouldShowScanError && (
          <div className="flash absolute f1 w-100 z-1 vh-100 bg-red-4 o-90 top-0 left-0 flex items-center justify-center"></div>
        )}

        {this.state.shouldShowConfirmationSuccess && (
          <div className="absolute w-100 vh-100 bg-white-80 green-3 justify-center items-center flex z-1 fw5 f3">
            <div className="bg-white ph3 pv2 br3 ba tc b--gray-7">
              <span className="db mb2">Successfully Processed Inventory</span>

              <Button
                color="gray-2"
                onClick={() =>
                  window.location = `/storefronts/${this.props.match.params.storefrontId}/inventory-transfers/inbound_transfers`
                }
              >
                View all Inbound Transfers
              </Button>
            </div>
          </div>
        )}

        <div className="bb b--gray-8 w-100">
          <SearchField
            autoFocus
            clearOnSubmit
            className="b--none"
            placeholderText="Scan item UPC..."
            buttonText="Add"
            isLoading={this.props.isLoading}
            onSubmit={upc => {
              const trimmedUpc = upc.trim();
              if (isUpc(trimmedUpc)) {
                this.increment(trimmedUpc, { shouldNotify: true });
              } else {
                this.setState({ shouldShowScanError: true });
              }
            }}
          />
        </div>

        <Scrollable>
          <h3 className="pa1 f6 b ma0 gray-3 bg-gray-9 bb b--gray-8">
            Mismatched Quantities
          </h3>
          {mismatch.reverse().map(item => this.renderReceiptItem(item))}

          <h3 className="pa1 f6 b ma0 gray-3 bg-gray-9 bb b--gray-8">
            Complete Quantities
          </h3>
          {complete.reverse().map(item => this.renderReceiptItem(item))}
        </Scrollable>

        {this.state.receipt?.inventory_transfer_receipt_items?.length ? (
          <div className="pa3 shadow-1">
            <Button
              onClick={() => {
              if (complete.length + mismatch.length !==  this.state.inventoryTransfer.skus) {
                const scannedItemUPCs = this.state.receipt.inventory_transfer_receipt_items.map(item => item.upc)
                const missingExpectedItems = this.state.inventoryTransfer.items.filter(item => (
                  !scannedItemUPCs.includes(item.upc)
                ))
                const missingExpectedItemsNames = missingExpectedItems.map(item => (item.title))

                if (window.confirm(
                  `Are you sure you want to update these quantities and process this Inventory Transfer? You are missing the following expected ${missingExpectedItemsNames.length > 1 ? 'products' : 'product'}: ${missingExpectedItemsNames.join(', ')}`,
                )) {
                  this.confirmReceipt();
                }
              } else if (
                  window.confirm(
                    'Are you sure you want to update these quantities and process this Inventory Transfer?',
                  )
                ) {
                  this.confirmReceipt();
                }
              }}
              buttonStyle={ButtonStyles.SECONDARY}
              size="full-width"
              className="f5"
            >
              Update Quantites and Process Inventory
            </Button>
          </div>
        ) : (
          <div className="flex flex-column bg-gray-9 pb12 vh-100 tc grey flex-column justify-center">
            Scan your first item.
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    storefront: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showNotification: (type, errorMessage) =>
      dispatch(showNotification(type, errorMessage)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditTransfer);
