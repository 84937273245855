import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Header from 'containers/Header';
import IconWithLabel from 'components/shared/IconWithLabel';
import InventoryChangesetIndex from 'components/inventory-changesets/InventoryChangesetIndex';
import InventoryChangesetShow from 'components/inventory-changesets/InventoryChangesetShow';

export default function CycleCounts({ history, location, match }) {
  const isViewingChangeset = location.pathname.includes('/change-set/');

  return (
    <div>
      <Header
        textColor="white"
        left={
          isViewingChangeset && (
            <IconWithLabel
              icon="arrow-left"
              iconClassName="f5 pr1"
              onClick={() => history.goBack()}
            />
          )
        }
      />
    
      <Switch>
        <Route
          path={`${match.path}/index`}
          component={InventoryChangesetIndex}
        />
        <Route
          path={`${match.path}/:changesetId`}
          component={InventoryChangesetShow}
        />
        <Redirect to={`${match.url}/index`} />
      </Switch>
    </div>
  );
}
