import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pubWithReturn, Event } from 'lib/events';
import {
  getReturnReceiptById,
  getIsReturnableForCash,
} from 'reducers/returnReceipts';
import { getIsAssociateAllowedToAcceptCash } from 'reducers/associate';
import { addItemToReturn, deleteReturnReceipt } from 'actions/asynchronous';
import { setRefundMethodForReturnReceipt } from 'actions/synchronous';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import RefundMethod from 'components/returns/RefundMethod';
import OrderInfo from 'components/returns/OrderInfo';

class AddRefundMethodToReturn extends Component {
  componentDidMount() {
    pubWithReturn(Event.ReturnRefundMethod.PAGE_VIEW);
  }

  handleClickRefundMethod = refundMethod => {
    if (refundMethod.type === 'store_credit') {
      pubWithReturn(Event.ReturnRefundMethod.SELECT_STORE_CREDIT);
    } else {
      pubWithReturn(Event.ReturnRefundMethod.SELECT_ORIGINAL_PAYMENT);
    }

    this.props.setRefundMethodForReturnReceipt(refundMethod);
  };

  render() {
    const { params } = this.props.match;
    const { returnReceipt } = this.props;
    const refundText =
      this.props.isReturnableForCash &&
      !this.props.isAssociateAllowedToAcceptCash
        ? 'This is a cash refund and needs a lead to continue.'
        : 'Select a Refund Method';

    return (
      <div>
        <TopBar
          color="red-5"
          textColor="white"
          left={
            <Link
              to={`/storefronts/${params.storefrontId}/returns/${params.returnReceiptId}/items`}
              className="no-underline"
              onClick={() =>
                pubWithReturn(Event.ReturnRefundMethod.BACK_TO_ADD_ITEMS)
              }
            >
              <IconWithLabel
                icon="arrow-left"
                iconClassName="f5 pr1"
                label="Add Items"
              />
            </Link>
          }
          center="Select Refund Method"
        />
        <OrderInfo
          orderNumber={returnReceipt.order_number}
          completedAt={returnReceipt.completed_at}
          locationName={returnReceipt.location_name}
          orderPayoutSummary={returnReceipt.order_payout_summary}
        />
        <h3 className="h2 fw3 pt3 ph3 mv0 f4 flex justify-between items-center">
          {refundText}
        </h3>

        <div className="flex flex-wrap ma2">
          {returnReceipt.refund_methods.map(refundMethod => (
            <RefundMethod
              key={refundMethod.type}
              refundMethod={refundMethod}
              isActive={refundMethod === returnReceipt.refundMethod}
              onClick={() => this.handleClickRefundMethod(refundMethod)}
              canShowCashOption={
                this.props.isAssociateAllowedToAcceptCash &&
                refundMethod.type === 'cash'
              }
            />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    returnReceipt: getReturnReceiptById(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
    isReturnableForCash: getIsReturnableForCash(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
    isAssociateAllowedToAcceptCash: getIsAssociateAllowedToAcceptCash(
      state.associate,
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { params } = ownProps.match;
  return {
    setRefundMethodForReturnReceipt: refundMethod =>
      dispatch(
        setRefundMethodForReturnReceipt(params.returnReceiptId, refundMethod),
      ),
    deleteReturnReceipt: () =>
      dispatch(deleteReturnReceipt(params.returnReceiptId)),
    addItemToReturn: item =>
      dispatch(
        addItemToReturn(params.storefrontId, params.returnReceiptId, item),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddRefundMethodToReturn);
