import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useCurrentBreakpointName } from 'react-socks';
import { showNotification } from 'actions/synchronous';
import { updateStorefrontConfiguration } from 'actions/asynchronous';
import { getStorefrontById } from 'reducers/storefronts';

import Header from 'containers/Header';

import { post } from 'lib/network';
import { retailApiUrl } from 'lib/url';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import ReaderList from 'components/shared/ReaderList';

function Stripe({ storefront }) {
  const [readerCode, setReaderCode] = useState('');
  const [newlyAddedReaderCode, setNewlyAddedReaderCode] = useState(false);
  const breakpointName = useCurrentBreakpointName();
  const isMobile = breakpointName.includes('phone');

  const handleAddReader = async () => {
    const { response, responseJson } = await post(
      retailApiUrl('stripe/create_reader'),
      {
        storefront_id: storefront.id,
        reader: {
          registration_code: readerCode,
        },
      },
    );

    if (response.ok) {
      setNewlyAddedReaderCode(readerCode);
      return responseJson;
    }
  };

  useEffect(() => {
    setReaderCode('');
  }, [newlyAddedReaderCode]);

  return (
    <>
      <Header textColor="white" color="green-5" />
      <div
        className={classNames('center', {
          'pa3 w-50': !isMobile,
          'pa1 w-100': isMobile,
        })}
      >
        <h2 className="">Nearby readers</h2>
        <p className="">
          Make sure your reader is online and on the same network as your
          device.
        </p>

        <ReaderList
          key={newlyAddedReaderCode}
          stripeLocationId={storefront.stripe_location_id}
          buttonColor="green-5"
        />

        <hr className="mv4 block" />

        <h3 className="mb1">Add a new Stripe reader for {storefront.name}</h3>

        <div>
          <p className="f4 mt0 mb3">
            When you set up a new reader, you will be presented with a
            three-word phrase (e.g. “<code>loved-old-film</code>”). If you are
            not shown one after turning on a new reader, go to the settings by
            swiping right on the home screen then tap “Generate pairing code.”
            (PIN: 07139)
          </p>

          <Input
            required={false}
            className=""
            value={readerCode}
            placeholder="three-word-phrase"
            onChange={event => setReaderCode(event.target.value)}
          />

          <Button
            color="green-5"
            className="mt1 w-100"
            onClick={handleAddReader}
          >
            + Add Reader
          </Button>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    storefront: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    updateStorefrontConfiguration: configuration =>
      dispatch(
        updateStorefrontConfiguration(
          ownProps.match.params.storefrontId,
          configuration,
        ),
      ),
    showNotification: (key, message) =>
      dispatch(showNotification(key, message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Stripe);
