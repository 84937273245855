import React from 'react';

export default function LimitedReturnReasonMessage({ returnReasonsByName }) {
  const message = returnReasonsByName.includes('changed_my_mind')
    ? 'Return is only possible if the reason is “Damaged or Defective,” “Incorrect Product,” or "Changed My Mind"'
    : 'Return is only possible if the reason is “Damaged or Defective” or “Incorrect Product”';

  return (
    <div
      className="ma1 mt2 mb3 pa1 gray-5 f5 relative flex items-center justify-center tc"
      style={{ backgroundColor: '#f5f5f5' }}
    >
      {message}
    </div>
  );
}
