import runtimeEnv from '@mars/heroku-js-runtime-env';

export default function getEnv() {
  return runtimeEnv();
}

/*
  NOTE: We use `REACT_APP_ENV` to manage our environment specific logic because
  create-react-app forces NODE_ENV to be 'production' when the slug is built
  using `npm run build`.
*/

export function isProduction() {
  return getEnv().REACT_APP_ENV === 'production';
}

export function isDevelopment() {
  return getEnv().REACT_APP_ENV === 'development';
}

export function areWebSocketsEnabled() {
  return [true, 'true'].includes(getEnv().REACT_APP_WEB_SOCKETS_ENABLED);
}
