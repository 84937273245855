import React, { Component, Fragment } from 'react';
import { array, string } from 'prop-types';
import visa from 'svg/visa.svg';
import americanexpress from 'svg/amex.svg';
import discover from 'svg/discover.svg';
import mastercard from 'svg/mastercard.svg';
import paypal from 'svg/paypal.svg';
import apple_pay from 'svg/apple_pay.svg';
import catchLogo from 'svg/catch.svg';
import FormattedDateTime from 'components/shared/FormattedDateTime';

const BrandToLogo = {
  VISA: visa,
  MASTERCARD: mastercard,
  'AMERICAN EXPRESS': americanexpress,
  DISCOVER: discover,
  PAYPAL: paypal,
  APPLE_PAY: apple_pay,
  CATCH: catchLogo,
};

export default class OrderInfo extends Component {
  static propTypes = {
    orderNumber: string.isRequired,
    completedAt: string.isRequired,
    locationName: string.isRequired,
    orderPayoutSummary: array.isRequired,
  };

  renderCreditCard(paymentMethod) {
    return (
      <span className="db mb1">
        {paymentMethod.customer_name}
        <br />
        <img
          src={BrandToLogo[paymentMethod.brand.toUpperCase()]}
          alt={paymentMethod.brand}
          className="mr1 w4"
          style={{ verticalAlign: 'text-top' }}
        />
        <span className="gray-6">
          (&hellip;{paymentMethod.last_digits}) {paymentMethod.expiration_date}
        </span>
      </span>
    );
  }

  renderStoreCredit(paymentMethod) {
    return <span className="db mb1">{paymentMethod.type}</span>;
  }

  renderPayPal(paymentMethod) {
    return (
      <span className="db mb1">
        <img
          src={BrandToLogo.PAYPAL}
          alt="PayPal"
          className="mr1 w4"
          style={{ verticalAlign: 'text-top' }}
        />
        {paymentMethod.email}
      </span>
    );
  }

  renderApplePay(paymentMethod) {
    return (
      <span className="db mb1">
        <img
          src={BrandToLogo.APPLE_PAY}
          alt="PayPal"
          className="mr1 w4"
          style={{ verticalAlign: 'text-top' }}
        />
        {paymentMethod.customer_name}
        {paymentMethod.email}
      </span>
    );
  }

  renderAfterpay() {
    return <span className="db mb1">Installments by Afterpay</span>;
  }

  renderCatch() {
    return (
      <span className="db mb1">
        <img
          src={BrandToLogo.CATCH}
          alt="Catch"
          className="mr1 w7"
          style={{ verticalAlign: 'text-top' }}
        />
      </span>
    );
  }

  renderPaymentMethods(payoutSummary) {
    if (payoutSummary.length) {
      return payoutSummary
        .sort((a, b) => {
          if (a.type < b.type) {
            return -1;
          }
          if (a.type > b.type) {
            return 1;
          }
          return 0;
        })
        .map(paymentMethod => (
          <Fragment key={paymentMethod.type}>
            {paymentMethod.type === 'cash' && <div>Cash</div>}
            {paymentMethod.type === 'credit_card' &&
              this.renderCreditCard(paymentMethod)}
            {['paypal_account', 'reach_paypal_account'].includes(
              paymentMethod.type,
            ) && this.renderPayPal(paymentMethod)}
            {paymentMethod.type === 'afterpay_token' &&
              this.renderAfterpay(paymentMethod)}
            {paymentMethod.type === 'apple_pay_checkout' &&
              this.renderApplePay(paymentMethod)}
            {paymentMethod.type === 'store_credit' &&
              this.renderStoreCredit(paymentMethod)}
            {paymentMethod.type === 'catch_checkout' &&
              this.renderCatch(paymentMethod)}
          </Fragment>
        ));
    } else {
      return <span className="db mv1">Free Order</span>;
    }
  }

  render() {
    const columns = [
      { heading: 'Order Info', width: '25%' },
      { heading: 'Order Number', width: '25%' },
      { heading: 'Payment Method', width: '25%' },
    ];

    const cells = [
      <Fragment>
        <FormattedDateTime>{this.props.completedAt}</FormattedDateTime>
        <br />
        {this.props.locationName}
      </Fragment>,
      this.props.orderNumber,
      this.renderPaymentMethods(this.props.orderPayoutSummary),
    ];

    return (
      <table
        style={{ minHeight: 130, display: 'block' }}
        className="bb b--gray-9 ph2"
      >
        <thead className="w-100 relative z-1 dt dt--fixed bb b--gray-9 border-box">
          <tr>
            {columns.map(column => (
              <th key={column.heading} className="h6 fw5 f4 w-33 tl">
                {column.heading}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="flex-auto db">
          <tr className="w-100 border-box ba b--gray-6 bn dt dt--fixed ">
            {cells.map((cell, index) => (
              <td
                key={index}
                className="w-33 pv2"
                style={{ wordWrap: 'break-word' }}
              >
                {cell}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  }
}
