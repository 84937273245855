import React, { Component } from 'react';
import { func } from 'prop-types';
import classNames from 'classnames';

export default class NumberKeyPad extends Component {
  static propTypes = {
    onClickNumber: func.isRequired,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = event => {
    event.preventDefault();

    if (
      ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].includes(
        event.key,
      )
    ) {
      this.props.onClickNumber(event.key);
    }

    if (event.key === 'Backspace') {
      this.props.onClickNumber('<');
    }
  };

  render() {
    return (
      <div className="flex flex-column gray-6">
        <div>
          <div className="flex">
            {this.renderNumber({ value: 1 })}
            {this.renderNumber({ value: 2 })}
            {this.renderNumber({ value: 3 })}
          </div>
          <div className="flex">
            {this.renderNumber({ value: 4 })}
            {this.renderNumber({ value: 5 })}
            {this.renderNumber({ value: 6 })}
          </div>
          <div className="flex">
            {this.renderNumber({ value: 7 })}
            {this.renderNumber({ value: 8 })}
            {this.renderNumber({ value: 9 })}
          </div>
          <div className="flex">
            {this.renderNumber({ value: '.', className: 'f1' })}
            {this.renderNumber({ value: 0 })}
            {this.renderNumber({ value: '<', className: 'bg-gray-6 white f3' })}
          </div>
        </div>
      </div>
    );
  }

  renderNumber = ({ className, value }) => {
    const handleClick = () => {
      this.props.onClickNumber(value);
    };

    return (
      <span
        className={classNames(
          'flex justify-center items-center w8 h7 ba br2 mr1 mb1',
          className,
        )}
        onClick={handleClick}
      >
        {value}
      </span>
    );
  };
}
