export default function sales(state = {}, action) {
  switch (action.type) {
    case 'RECEIVE_SALES': {
      return action.payload.reduce(
        (sales, sale) => ({
          ...sales,
          [sale.number]: sale,
        }),
        {},
      );
    }

    case 'RECEIVE_SALE': {
      const sale = getSaleByNumber(state, action.payload.number);
      const cash_amount = sale && sale.cash_amount;

      return {
        ...state,
        [action.payload.number]: {
          ...action.payload,
          cash_amount,
        },
      };
    }

    case 'REMOVE_SALE': {
      const { [action.payload]: _, ...withoutSale } = state;
      return withoutSale;
    }

    case 'REMOVE_SALE_ITEM': {
      const sale = getSaleByNumber(state, action.payload.saleNumber);

      if (sale) {
        return {
          ...state,
          [sale.number]: {
            ...sale,
            sale_items: sale.sale_items.filter(
              saleItem => saleItem.id !== action.payload.saleItemId,
            ),
          },
        };
      } else {
        return state;
      }
    }

    case 'RECEIVE_ORDER_PREVIEW': {
      const sale = getSaleByNumber(state, action.payload.sale_number);

      if (sale) {
        const newItems = sale.sale_items.map(item => {
          const newItem = action.payload.line_item_prices.find(li => {
            return li.line_item_id === item.id;
          });

          const newPrice = newItem?.discounted_price
            ? newItem.discounted_price
            : item.price;

          const newLabels = newItem?.discount_labels
            ? newItem.discount_labels
            : item.discountLabels;

          return { ...item, price: newPrice, discountLabels: newLabels };
        });

        return {
          ...state,
          [sale.number]: {
            ...sale,
            from_order_preview: true,
            sale_items: newItems,
          },
        };
      }

      return state;
    }

    case 'GRANT_FREE_SHIPPING': {
      const sale = getSaleByNumber(state, action.payload);
      const freeShipping = !state.freeShipping;

      if (sale) {
        return {
          ...state,
          [sale.number]: {
            ...sale,
            free_shipping: freeShipping,
          },
        };
      } else {
        return state;
      }
    }

    case 'SET_CASH_AMOUNT': {
      const sale = getSaleByNumber(state, action.payload.saleNumber);

      if (sale) {
        return {
          ...state,
          [sale.number]: {
            ...sale,
            cash_amount: action.payload.amount,
          },
        };
      } else {
        return state;
      }
    }

    case 'SET_CHANGE_ISSUED': {
      const sale = getSaleByNumber(state, action.payload);

      if (sale) {
        return {
          ...state,
          [sale.number]: {
            ...sale,
            change_issued: true,
          },
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}

export function getIncompleteSales(state) {
  return Object.values(state).filter(sale => sale.state !== 'completed');
}

export function getSaleByNumber(state, saleNumber) {
  return state[saleNumber];
}

export function getSaleItemsBeingShipped(state, saleNumber) {
  if (!(saleNumber in state)) {
    return [];
  }

  return state[saleNumber].sale_items.filter(item => item.source === 'Online');
}

export const Status = {
  NO_CUSTOMER: 'NO_CUSTOMER',
  NO_ITEMS: 'NO_ITEMS',
  NO_PAYMENT: 'NO_PAYMENT',
  NO_ADDRESS: 'NO_ADDRESS',
  NO_SHIPPING_OPTION: 'NO_SHIPPING_OPTION',
  READY_FOR_COMPLETION: 'READY_FOR_COMPLETION',
  IN_PROGRESS_CASH_TRANSACTION: 'IN_PROGRESS_CASH_TRANSACTION',
};

export function getSaleStatus(state, saleNumber, pathname, saleTotal) {
  const sale = getSaleByNumber(state, saleNumber);
  const isSaleTotalZero =
    saleTotal && parseFloat(saleTotal.replace(/[^\d.]/, '')) === 0;
  const itemsBeingShipped = getSaleItemsBeingShipped(state, saleNumber);

  if (!sale) {
    return null;
  } else if (!sale.customer) {
    return Status.NO_CUSTOMER;
  } else if (sale.sale_items.length === 0) {
    return Status.NO_ITEMS;
  } else if (itemsBeingShipped.length > 0 && !sale.shipping_address) {
    return Status.NO_ADDRESS;
  } else if (
    // if an item has a different shipping option than the others, we need to resolve
    // that by having the user select a shipping option for the whole sale.
    itemsBeingShipped.length > 0 &&
    !itemsBeingShipped.every(
      saleItem =>
        saleItem.fulfillment_method_key ===
        itemsBeingShipped[0].fulfillment_method_key,
    )
  ) {
    return Status.NO_SHIPPING_OPTION;
  } else if (sale.paid_in_cash && sale.cash_amount) {
    return Status.IN_PROGRESS_CASH_TRANSACTION;
  } else if (!sale.payment_method && !isSaleTotalZero) {
    return Status.NO_PAYMENT;
  } else if (pathname.includes('payment')) {
    return Status.READY_FOR_COMPLETION;
  } else {
    return Status.NO_PAYMENT;
  }
}
