import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { UPCScanner } from 'lib/keyboard';
import { pubWithReturn, Event } from 'lib/events';
import { getReturnReceiptById } from 'reducers/returnReceipts';
import {
  addItemToReturn,
  deleteReturnReceipt,
  removeReceivedReturnUnit,
} from 'actions/asynchronous';
import {
  receiveReturnReceipt,
  showDialog,
  hideDialog,
} from 'actions/synchronous';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import Scrollable from 'components/shared/Scrollable';
import PackageOpenedDialog from 'components/returns/PackageOpenedDialog';
import { staticImageUrl } from 'lib/url';
import Icon from 'components/shared/Icon';
import Button, { ButtonStyles } from 'components/shared/Button';

class AddItemsToReturn extends Component {
  state = {
    isListeningForScans: true,
  };

  constructor(props) {
    super(props);
    this.scanner = new UPCScanner({
      callback: this.addItemToReturnByVariantUPC,
    });
  }

  componentDidMount() {
    pubWithReturn(Event.Return.PAGE_VIEW);
    this.scanner.listen();
  }

  componentWillUnmount() {
    this.scanner.stopListening();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isListeningForScans === prevState.isListeningForScans) {
      return;
    }

    if (this.state.isListeningForScans) {
      this.scanner.listen();
    } else {
      this.scanner.stopListening();
    }
  }

  addItemToReturnByVariantUPC = variantUPC => {
    pubWithReturn(Event.Return.ENTER_SEARCH, { text: variantUPC });

    const returnItem = this.props.returnReceipt.items.find(
      item => item.variant_upc === variantUPC,
    );

    if (returnItem) {
      this.determineHowToAddReturnItem(returnItem);
    } else {
      window.alert('This item is not in this sale');
    }
  };

  handleClickBackButton = () => {
    if (window.confirm('Are you sure that you want to cancel this return?')) {
      this.props.deleteReturnReceipt();
      pubWithReturn(Event.Return.BACK_TO_RETURNS_HOME);
      this.props.history.push(
        `/storefronts/${this.props.match.params.storefrontId}/returns`,
      );
    }
  };

  addItemToReturn = returnItem => {
    this.props.addItemToReturn(returnItem).then(() =>
      pubWithReturn(Event.Return.ADD_ITEM_TO_RETURN, {
        product_id: returnItem.product_id,
      }),
    );
  };

  // The rules for adding an item are as follows:
  // If the item is a "single return" item:
  //   If they have used their first try:
  //     Check if the item has been opened, mark if so, and then add normally.
  //     (later, the only return reasons will only be "defective" and "incorrect")
  //   If they haven't used their first try:
  //     Add normally.
  // If the item is none of the above:
  //   Add normally.
  determineHowToAddReturnItem = returnItem => {
    if (returnItem.single_return_variant && !returnItem.first_try_allowed) {
      return this.props.showDialog(
        <PackageOpenedDialog
          onRequestClose={this.props.hideDialog}
          onSelectChoice={({ isOpened }) => {
            this.props.hideDialog();

            if (isOpened) {
              this.props.onMarkLineItemAsOpened(returnItem);
            }

            this.addItemToReturn(returnItem);
          }}
        />,
      );
    }

    this.addItemToReturn(returnItem);
  };

  renderItem = ({ item, hasBeenReturned, isReturning }) => {
    const itemTitle = item.title.split(' - ');
    const handleOnClick = () => {
      if (isReturning) {
        this.props.removeItemFromReturn(item.id);
      } else {
        this.determineHowToAddReturnItem(item);
      }
    };

    return (
      <div
        key={`${item.title}-${item.size}-${item.id}`}
        className={classNames('pa1 ma1 shadow-4 relative', {
          'o-60': hasBeenReturned,
        })}
        style={{ flex: '0 45%' }}
        onClick={handleOnClick}
      >
        {!hasBeenReturned && (
          <Icon
            glyph={isReturning ? 'circle-checkmark' : 'circle'}
            color={isReturning ? 'black' : 'gray-6'}
            className="absolute pl0 pr0 f3 h3 w3"
            onClick={() => null}
            style={{
              top: '15px',
              left: '15px',
            }}
          />
        )}
        <img
          alt={item.title}
          src={staticImageUrl(item.image_url, { size: 150 })}
        />
        <div className="ml1 lh-title">
          {hasBeenReturned ? (
            <div className="f5">This item has been returned</div>
          ) : (
            <Fragment>
              <div className="f5 pb1">{itemTitle[0]}</div>
              <div className="gray-6 f6">{itemTitle[1]}</div>
              <div className="gray-6 f6">Size: {item.size}</div>
            </Fragment>
          )}
        </div>
      </div>
    );
  };

  renderItems() {
    const receipt = this.props.returnReceipt;
    let items = [];
    receipt.items.forEach(item => {
      items.push(this.renderItem({ item }));
    });
    receipt.received_return_units.forEach(item => {
      items.push(
        this.renderItem({
          item,
          isReturning: true,
        }),
      );
    });
    // all the tiles will stay stationary when adding/removing from return
    items = items.sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0;
    });

    return (
      <div className="ma2 relative">
        <div className="f3 mv2 pl1">Select the items to return.</div>
        <Scrollable>
          <div
            className="flex flex-row flex-wrap"
            style={{ paddingBottom: '80px' }}
          >
            {items}
            {this.props.returnReceipt.returned_items.map(item => {
              return this.renderItem({
                item,
                hasBeenReturned: true,
              });
            })}
          </div>
        </Scrollable>
      </div>
    );
  }

  render() {
    return (
      <div className="relative">
        <TopBar
          color="red-5"
          textColor="white"
          left={
            <IconWithLabel
              onClick={this.handleClickBackButton}
              icon="arrow-left"
              iconClassName="f5 pr1"
              label={'Back'}
            />
          }
        />
        {this.renderItems()}
        {this.props.returnReceipt?.received_return_units?.length > 0 && (
          <Button
            buttonStyle={ButtonStyles.SECONDARY}
            className="absolute center w-80"
            style={{ bottom: '20px', left: '10%' }}
            onClick={this.props.onSelectItemsMobile}
          >
            Select Return Reasons
          </Button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    returnReceipt: getReturnReceiptById(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { params } = ownProps.match;

  return {
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    receiveReturnReceipt: returnReceipt =>
      dispatch(receiveReturnReceipt(returnReceipt)),
    deleteReturnReceipt: () =>
      dispatch(deleteReturnReceipt(params.returnReceiptId)),
    addItemToReturn: item =>
      dispatch(
        addItemToReturn(params.storefrontId, params.returnReceiptId, item),
      ),
    removeItemFromReturn: id =>
      dispatch(removeReceivedReturnUnit(params.returnReceiptId, id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddItemsToReturn);
