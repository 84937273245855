import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchReceivedReturnUnits } from 'actions/asynchronous';
import Button from 'components/shared/Button';

class TransferQueueButton extends Component {
  static defaultProps = {
    color: 'blue-1',
    className: 'f4 mb2',
    size: 'full-width',
    style: {},
    outlined: false,
    onComplete: null,
  };

  state = {
    active: false,
  };

  handleClick = async event => {
    event.persist();
    const onCompleteFunction =
      this.props.onComplete || this.props.fetchReceivedReturnUnits;

    this.setState({ active: true });

    if (this.props.onClick) {
      await this.props.onClick();
    }

    await onCompleteFunction();

    this.setState({ active: false });
  };

  render() {
    return (
      <Button
        disabled={this.state.active}
        outlined={this.props.outlined}
        className={this.props.className}
        size={this.props.size}
        style={this.props.style}
        color={this.props.color}
        onClick={this.handleClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchReceivedReturnUnits: () =>
      dispatch(fetchReceivedReturnUnits(ownProps.match.params.storefrontId)),
  };
}

export default withRouter(
  connect(null, mapDispatchToProps)(TransferQueueButton),
);
