import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { showNotification } from 'actions/synchronous';
import { updateStorefrontConfiguration } from 'actions/asynchronous';
import { NotificationTypes } from 'reducers/notifications';
import { getStorefrontById } from 'reducers/storefronts';
import { isLocked, lock, unlock } from 'lib/session';
import Header from 'containers/Header';
import SubHeader from 'components/shared/SubHeader';
import ToggleSwitch from 'components/shared/ToggleSwitch';
import Scrollable from 'components/shared/Scrollable';

function FeatureControl({
  storefront,
  showNotification,
  updateStorefrontConfiguration,
  isMobile,
}) {
  return (
    <Fragment>
      <Header textColor="black" />
      <Scrollable>
        <div>
          <SubHeader>Toggle features for {storefront.name}</SubHeader>

          <ul
            className={classNames('ma3 pa0 list flex', {
              'flex-wrap': isMobile,
            })}
          >
            <li
              className={classNames('relative pa2 ba b--gray-9 db fl mr3', {
                'w-20': !isMobile,
                mb2: isMobile,
              })}
            >
              <h3 className="fw4 f4 mb1 mt0">Request to Try:</h3>
              <ToggleSwitch
                initialActiveState={storefront.request_to_try_enabled}
                onClick={async isActive => {
                  try {
                    await updateStorefrontConfiguration({
                      request_to_try_enabled: isActive,
                    });
                    return { didUpdate: true };
                  } catch (e) {
                    showNotification(
                      NotificationTypes.ERROR,
                      'An error occured. Please contact an engineer.',
                    );

                    return { didUpdate: false };
                  }
                }}
              />
            </li>
          </ul>
        </div>

        <div>
          <SubHeader>Toggle features for this device</SubHeader>

          <ul
            className={classNames('ma3 pa0 list flex', {
              'flex-wrap': isMobile,
            })}
          >
            <li
              className={classNames('relative pa2 ba b--gray-9 db fl mr3', {
                'w-30': !isMobile,
                mb2: isMobile,
              })}
            >
              <h3 className="fw4 f4 mb1 mt0">Lock Out Ambassadors:</h3>
              <ToggleSwitch
                initialActiveState={isLocked()}
                onClick={isActive => {
                  isActive ? lock() : unlock();
                  return { didUpdate: true };
                }}
              />
            </li>
          </ul>
        </div>
      </Scrollable>
    </Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    storefront: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ),
    isMobile: state.ui.isMobile,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    updateStorefrontConfiguration: configuration =>
      dispatch(
        updateStorefrontConfiguration(
          ownProps.match.params.storefrontId,
          configuration,
        ),
      ),
    showNotification: (key, message) =>
      dispatch(showNotification(key, message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureControl);
