import getConstants from 'lib/constants';

const EXPRESS = 'international_express';

export function orderTotalExceedsValueLimit({
  country,
  orderTotal,
  shippingOption,
}) {
  const valueLimit = getValueLimit(country, shippingOption);

  if (!orderTotal || !valueLimit) {
    return false;
  }

  // check orderTotal format
  const parsedOrderTotal = Number.parseFloat(orderTotal.replace(/[$,]+/g, ''));
  const parsedLimit = Number.parseFloat(valueLimit);

  return parsedOrderTotal > parsedLimit;
}

export function getOrderTotalWarning({ country, shippingOption }) {
  const valueLimit = getValueLimit(country, shippingOption);
  const parsedLimit = Number.parseInt(valueLimit, 10);

  const shippingConfiguration = {
    ...getConstants().international_shipping_configuration,
  };

  const countryName = shippingConfiguration[country].name;

  return `Your order total exceeds the $${parsedLimit} limit for ${
    shippingOption.description
  } to ${countryName}.`;
}

function getValueLimit(country, shippingOption) {
  const shippingConfiguration = {
    ...getConstants().international_shipping_configuration,
  };

  const config = shippingConfiguration[country];

  if (!config) {
    return false;
  }

  return shippingOption.key === EXPRESS
    ? config.value_limit_express
    : config.value_limit;
}
