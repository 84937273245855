import React, { Component, Fragment } from 'react';
import { array } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchReceivedReturnUnits,
  markReturnUnitAsDonated,
  moveReturnUnitToReturnToFloor,
  updateReturnUnitUpc,
} from 'actions/asynchronous';
import { showDialog, hideDialog } from 'actions/synchronous';
import NoItemsMessage from 'components/shared/NoItemsMessage';
import Button from 'components/shared/Button';
import TransferQueueButton from 'containers/returns-queue/TransferQueueButton';
import UpdateUpcDialog from 'components/returns-queue/UpdateUpcDialog';
import ReturnUnit from 'components/returns-queue/ReturnUnit';
import FormattedDateTime from 'components/shared/FormattedDateTime';
import ScrollableTable from 'components/shared/ScrollableTable';

class SpecialCaseQueue extends Component {
  static propTypes = {
    receivedReturnUnits: array.isRequired,
  };

  static defaultProps = {
    receivedReturnUnits: [],
  };

  render() {
    if (!this.props.receivedReturnUnits.length) {
      return <NoItemsMessage>No special case items</NoItemsMessage>;
    }

    const receivedReturnUnits = this.props.receivedReturnUnits
      // Units come sorted by ``created_at` currently, here we are applying
      // an extra layer of sorting, in this case, by return reason.
      .sort((a, b) => (a.return_reason < b.return_reason ? -1 : 1))
      .map(returnUnit => [
        <FormattedDateTime>{returnUnit.received_at}</FormattedDateTime>,
        returnUnit.order_number,
        <ReturnUnit
          imageUrl={returnUnit.item.image_url}
          title={returnUnit.item.title}
          size={returnUnit.item.size}
          color={returnUnit.item.color}
          upc={returnUnit.item.upc}
          source={returnUnit.item.source}
          returnReason={returnUnit.return_reason}
          description={returnUnit.description}
        />,
        returnUnit.sku_qoh,
        <Fragment>
          {returnUnit.incorrect_item ? (
            <Fragment>
              <Button
                className="f4 mb2"
                size="full-width"
                color="red-6"
                onClick={event => {
                  event.persist();
                  this.props.showDialog(
                    <UpdateUpcDialog
                      currentUpc={returnUnit.item.upc}
                      onRequestClose={this.props.hideDialog}
                      onFormSubmit={newUpc => {
                        this.props
                          .updateReturnUnitUpc(returnUnit.id, newUpc)
                          .then(() => {
                            alert('UPC Updated!');
                            this.props.hideDialog();
                            this.props.fetchReceivedReturnUnits();
                          });
                      }}
                    />,
                  );
                }}
              >
                Update Item UPC
              </Button>
              <TransferQueueButton
                outlined
                color="red-6"
                onClick={() =>
                  this.props.moveReturnUnitToReturnToFloor(returnUnit.id)
                }
              >
                Remove Item
              </TransferQueueButton>
            </Fragment>
          ) : (
            <TransferQueueButton
              onClick={() => this.props.markReturnUnitAsDonated(returnUnit.id)}
            >
              Mark as Donated
            </TransferQueueButton>
          )}
        </Fragment>,
      ]);

    const columns = [
      { heading: 'Time', width: '16%' },
      { heading: 'Order No.', width: '13%' },
      { heading: 'Item Returned', width: '38%' },
      { heading: 'Quantity', width: '10%' },
      { heading: '', width: '23%' },
    ];

    return (
      <ScrollableTable
        keys={this.props.receivedReturnUnits.map(unit => unit.id)}
        columns={columns}
        rows={receivedReturnUnits}
      />
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchReceivedReturnUnits: () =>
      dispatch(fetchReceivedReturnUnits(ownProps.match.params.storefrontId)),
    markReturnUnitAsDonated: id =>
      dispatch(markReturnUnitAsDonated(ownProps.match.params.storefrontId, id)),
    moveReturnUnitToReturnToFloor: id =>
      dispatch(
        moveReturnUnitToReturnToFloor(ownProps.match.params.storefrontId, id),
      ),
    updateReturnUnitUpc: (id, upc) =>
      dispatch(
        updateReturnUnitUpc(ownProps.match.params.storefrontId, id, upc),
      ),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(SpecialCaseQueue));
