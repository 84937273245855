import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, func, object } from 'prop-types';
import { showDialog, hideDialog } from 'actions/synchronous';
import { fetchSale, updateCustomer } from 'actions/asynchronous';
import EditDetailsDialog from 'components/customers/EditDetailsDialog';
import Icon from 'components/shared/Icon';

class CustomerBanner extends Component {
  static propTypes = {
    allowEditing: bool,
    customer: object.isRequired,
    showDialog: func.isRequired,
    hideDialog: func.isRequired,
    updateCustomer: func.isRequired,
    fetchSale: func.isRequired,
  };

  static defaultProps = {
    allowEditing: true,
  };

  handleEditFormSubmit = customerAttributes => {
    this.props
      .updateCustomer({
        ...customerAttributes,
        id: this.props.customer.id,
      })
      .then(this.props.fetchSale)
      .then(this.props.hideDialog);
  };

  handleCustomerClick = () => {
    if (!this.props.allowEditing) {
      return;
    }

    const editDetailsDialog = (
      <EditDetailsDialog
        customer={this.props.customer}
        onRequestClose={this.props.hideDialog}
        onFormSubmit={this.handleEditFormSubmit}
      />
    );

    this.props.showDialog(editDetailsDialog);
  };

  render() {
    const initials = [
      this.props.customer.first_name,
      this.props.customer.last_name,
    ]
      .filter(namePart => namePart)
      .reduce((initials, namePart) => initials + namePart[0], '');

    return (
      <div className="bb b--gray-9">
        <div className="border-box no-underline flex items-center bn outline-0 pa2 w-100 db bg-white h9 relative">
          {!!initials.length && (
            <div
              onClick={this.handleCustomerClick}
              className="br-100 w5 h5 mr2 flex items-center justify-center white bg-blue-4"
            >
              {initials}
            </div>
          )}
          <div onClick={this.handleCustomerClick}>
            <p className="ma0 flex flex-column items-start">
              {this.props.customer.full_name && (
                <span style={{ height: '1.2rem' }}>
                  {this.props.customer.full_name}
                </span>
              )}
              <span className="f6">{this.props.customer.email}</span>
            </p>
          </div>
          {this.props.allowEditing && (
            <Icon
              color="gray-6"
              glyph="circle-minus"
              className="absolute pr2 right-0"
              onClick={this.props.onRemoveCustomerClick}
              style={{ fontSize: 24 }}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    updateCustomer: customer => dispatch(updateCustomer(customer)),
    fetchSale: () => dispatch(fetchSale(ownProps.saleNumber)),
  };
}

export default connect(null, mapDispatchToProps)(CustomerBanner);
