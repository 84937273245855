import { combineReducers } from 'redux';
import activeRequests from 'reducers/activeRequests';
import associate from 'reducers/associate';
import notifications from 'reducers/notifications';
import storefronts from 'reducers/storefronts';
import ui from 'reducers/ui';
import sales from 'reducers/sales';
import returnReceipts from 'reducers/returnReceipts';
import orderPreview from 'reducers/orderPreview';
import receivedReturnUnits from 'reducers/receivedReturnUnits';
import inventoryTransfer from 'reducers/inventoryTransfer';
import orderSearch from 'reducers/orderSearch';
import cashFloats from 'reducers/cashFloats';

const rootReducer = combineReducers({
  sales,
  orderPreview,
  returnReceipts,
  activeRequests,
  associate,
  notifications,
  storefronts,
  ui,
  receivedReturnUnits,
  inventoryTransfer,
  orderSearch,
  cashFloats,
});

export default rootReducer;
