import React, { Fragment, useState } from 'react';
import Button, { ButtonStyles } from 'components/shared/Button';
import Dialog from 'components/shared/Dialog';

export default function DeleteInventoryTransferDialog({
  inventoryTransferId,
  onRequestClose,
  onFormSubmit,
}) {
  const [isDeleted, setIsDeleted] = useState(false);

  const onSubmit = async event => {
    event.preventDefault();
    await onFormSubmit(inventoryTransferId);
    setIsDeleted(true);
  };

  return (
    <Dialog
      simple
      height="auto"
      title={<span className="f4">Cancel Transfer</span>}
      onRequestClose={onRequestClose}
    >
      <form className="ph3 tc">
        {isDeleted && (
          <Fragment>
            <span className="f3 pv5 dib">
              This transfer has been cancelled.
            </span>

            <Button
              type="button"
              buttonStyle={ButtonStyles.SECONDARY}
              className="w-100"
              onClick={onRequestClose}
            >
              Done
            </Button>
          </Fragment>
        )}

        {!isDeleted && (
          <Fragment>
            <span className="f3 pv5 dib">Are you sure you want to cancel?</span>

            <Button
              buttonStyle={ButtonStyles.SECONDARY}
              className="w-100"
              onClick={onSubmit}
            >
              Yes. Cancel.
            </Button>
          </Fragment>
        )}
      </form>
    </Dialog>
  );
}
