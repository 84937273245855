export default function returnReceipts(state = {}, action) {
  switch (action.type) {
    case 'RECEIVE_RETURN_RECEIPT': {
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    }

    case 'REMOVE_RETURN_RECEIPT': {
      const { [action.payload]: _, ...withoutReturnReceipt } = state;
      return withoutReturnReceipt;
    }

    case 'SET_REFUND_METHOD_FOR_RETURN_RECEIPT': {
      const returnReceipt = state[action.payload.returnReceiptId];

      return {
        ...state,
        [returnReceipt.id]: {
          ...returnReceipt,
          refundMethod: action.payload.refundMethod,
        },
      };
    }

    default:
      return state;
  }
}

export function getReturnReceiptById(state, returnReceiptId) {
  return state[returnReceiptId];
}

export const Status = {
  NO_CUSTOMER: 'NO_CUSTOMER',
  NO_ITEMS: 'NO_ITEMS',
  NO_REFUND_METHOD: 'NO_REFUND_METHOD',
  MISSING_REASONS: 'MISSING_REASONS',
  READY_FOR_COMPLETION: 'READY_FOR_COMPLETION',
};

export function getReturnReceiptStatus(state, returnReceiptId, pathname) {
  const returnReceipt = getReturnReceiptById(state, returnReceiptId);
  const creditIsTheOnlyAvailableRefundMethod =
    returnReceipt.refund_methods.length === 1 &&
    returnReceipt.refund_methods[0].type === 'store_credit' &&
    returnReceipt.is_gift_return;

  if (!returnReceipt) {
    return null;
  } else if (returnReceipt.is_gift_return && !returnReceipt.customer) {
    return Status.NO_CUSTOMER;
  } else if (returnReceipt.received_return_units.length === 0) {
    return Status.NO_ITEMS;
  } else if (
    !returnReceipt.received_return_units.every(
      rru => rru.selected_return_reasons.length >= 1,
    )
  ) {
    return Status.MISSING_REASONS;
  } else if (
    !returnReceipt.refundMethod &&
    creditIsTheOnlyAvailableRefundMethod
  ) {
    return Status.READY_FOR_COMPLETION;
  } else if (!returnReceipt.refundMethod || !pathname.includes('refund')) {
    return Status.NO_REFUND_METHOD;
  } else {
    return Status.READY_FOR_COMPLETION;
  }
}

export function getIsReturnableForCash(state, returnReceiptId) {
  const returnReceipt = getReturnReceiptById(state, returnReceiptId);
  return returnReceipt.refund_methods.find(
    refundMethod => refundMethod.type === 'cash',
  );
}
