import React, { useEffect, useState } from 'react'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import getEnv from 'lib/env';
import { post } from 'lib/network'
import { retailApiUrl } from 'lib/url'

const stripePromise = loadStripe(`${getEnv().REACT_APP_STRIPE_PUBLISHABLE_KEY}`)

const StripePaymentWrapper = ({customerEmail, children}) => {
  const [clientSecret, setClientSecret] = useState()

  useEffect(() => {
    const getIntent = async () => {
      const stripeIntent = await post(retailApiUrl('stripe/create_setup_intent'), {
        customer_email: customerEmail,
      })
      setClientSecret(stripeIntent?.responseJson?.client_secret)
    }

    getIntent()
  }, [customerEmail])

  return (
    <>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          {children}
        </Elements>
      ) : (
        <p>Loading…</p>
      )}
    </>
  )
}

export default StripePaymentWrapper
