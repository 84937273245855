import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { pubWithSale, Event } from 'lib/events';

function SaleSplitShipmentInfo({
  match,
  shippingAddress,
  fulfillmentMethodName,
  allowEditing = true,
}) {
  return (
    <div className="flex justify-between f6 tl pa1 bb b--gray-9">
      <div className="w-60 mr2 lh-copy">
        <p className="gray-5 ma0">Shipping to:</p>
        <address className="gray-6 fs-normal">
          {shippingAddress.full_name}
          <br />
          {shippingAddress.street_address}
          <br />
          {shippingAddress.city}, {shippingAddress.region_code}{' '}
          {shippingAddress.postal_code}
          <br />
          {shippingAddress.country}
        </address>
        {allowEditing && (
          <Link
            to={`/storefronts/${match.params.storefrontId}/sales/${match.params.saleNumber}/address/add`}
            className="no-underline lh-copy h2 blue bb"
            onClick={() => pubWithSale(Event.Cart.EDIT_SALE_SHIPPING_ADDRESS)}
          >
            Edit
          </Link>
        )}
      </div>
      <div className="w-40 lh-copy">
        <p className="gray-5 ma0">Shipping Method:</p>
        <p className="gray-6 fs-normal ma0">{fulfillmentMethodName}</p>
        {allowEditing && (
          <Link
            to={`/storefronts/${match.params.storefrontId}/sales/${match.params.saleNumber}/shipping`}
            className="no-underline lh-copy h2 blue bb"
            onClick={() => pubWithSale(Event.Cart.EDIT_SALE_SHIPPING_OPTION)}
          >
            Edit
          </Link>
        )}
      </div>
    </div>
  );
}

export default withRouter(SaleSplitShipmentInfo);
