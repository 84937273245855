import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pubWithSale, Event } from 'lib/events';
import { isValidPhoneNumber, isValidChinesePhoneNumber } from 'lib/validation';
import { createAddressForSale } from 'actions/asynchronous';
import { getSaleByNumber, getSaleItemsBeingShipped } from 'reducers/sales';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import AddressForm from 'components/sales/mobile/AddressForm';
import logo from 'svg/logo.svg';
import { staticImageUrl } from 'lib/url';
import Scrollable from 'components/shared/Scrollable';

class CreateAddressForSale extends Component {
  static propTypes = {
    createAddressForSale: func.isRequired,
  };

  state = {
    formError: null,
  };

  componentDidMount() {
    pubWithSale(Event.SaleNewAddress.PAGE_VIEW);
  }

  addressNeedsUniqueId() {
    const url = window.location.pathname;
    const isEditPage = url.substr(url.lastIndexOf('/') + 1) === 'edit';

    return (
      isEditPage &&
      (this.props.sale.shipping_address.unique_id === '' ||
        !this.props.sale.shipping_address.unique_id)
    );
  }

  handleCreateAddressForSale = (address, saveForFutureUse) => {
    const { params } = this.props.match;

    if (
      (address.country === 'CN' &&
        (!address.phone || !isValidChinesePhoneNumber(address.phone))) ||
      (address.country !== 'US' &&
        (!address.phone || !isValidPhoneNumber(address.phone)))
    ) {
      return this.setState({ formError: 'Please enter a valid phone number.' });
    }

    pubWithSale(Event.SaleNewAddress.ADD_ADDRESS);

    if (saveForFutureUse) {
      pubWithSale(Event.SaleNewAddress.STORE_ADDRESS);
    }

    this.props
      .createAddressForSale(
        this.props.sale.customer.email,
        address,
        saveForFutureUse,
      )
      .then(() => {
        this.props.history.push(
          `/storefronts/${params.storefrontId}/sales/${params.saleNumber}/shipping`,
        );
      });
  };

  render() {
    const { params } = this.props.match;
    const salePath = `/storefronts/${params.storefrontId}/sales/${params.saleNumber}`;
    const customerHasOtherAddresses =
      this.props.sale.customer.addresses.length > 0;

    if (!this.props.sale.customer) {
      return <Redirect to={salePath} />;
    }

    return (
      <div>
        <TopBar
          left={
            <Link
              to={
                customerHasOtherAddresses
                  ? `${salePath}/address/add`
                  : `${salePath}/product`
              }
              onClick={() => {
                if (customerHasOtherAddresses) {
                  pubWithSale(Event.SaleNewAddress.BACK_TO_EXISTING_ADDRESS);
                } else {
                  pubWithSale(Event.SaleNewAddress.BACK_TO_SALE);
                }
              }}
              className="no-underline"
            >
              <IconWithLabel
                color="black"
                icon="arrow-left"
                iconClassName="f5 pr1"
                label={customerHasOtherAddresses ? 'Back' : 'Sale'}
              />
            </Link>
          }
          center={<img src={logo} alt="Welcome to Everlane" className="w16" />}
        />
        <Scrollable className="pa2">
          <div className="f4">Items to Ship</div>
          <div className="flex flex-row flex-wrap pb2 bb b--gray-9">
            {this.props.saleItemsBeingShipped.map(item => {
              const itemTitle = item.title.split(' - ');

              return (
                <div key={item.id} style={{ flex: '0 45%' }} className="ma1">
                  <img
                    alt={item.title}
                    src={staticImageUrl(item.image_url, { size: 150 })}
                  />
                  <div className="lh-title">
                    <div className="f5 pb1">{itemTitle[0]}</div>
                    <div className="gray-6 f6">{itemTitle[1]}</div>
                    <div className="gray-6 f6">Size: {item.size}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pv2">Please add a shipping address:</div>
          <AddressForm
            error={this.state.formError}
            onSubmit={this.handleCreateAddressForSale}
            existingAddress={
              this.props.sale && this.props.sale.shipping_address
            }
            needsUniqueId={this.addressNeedsUniqueId()}
          />
        </Scrollable>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params;

  return {
    sale: getSaleByNumber(state.sales, saleNumber),
    saleItemsBeingShipped: getSaleItemsBeingShipped(state.sales, saleNumber),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { saleNumber } = ownProps.match.params;

  return {
    createAddressForSale: (customerEmail, address, saveForFutureUse) =>
      dispatch(
        createAddressForSale(
          customerEmail,
          saleNumber,
          address,
          saveForFutureUse,
        ),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAddressForSale);
