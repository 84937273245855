import React from 'react';
import classNames from 'classnames';
import { formatTimestamp } from 'lib/time';

// Conveinience component for formatting timestamps in a friendly way
export default function FormattedDateTime({
  children,
  format = 'long',
  highlightIfPassed = false,
}) {
  return (
    <time
      className={classNames({
        red: highlightIfPassed && new Date(children) < Date.now(),
      })}
    >
      {formatTimestamp(children, format)}
    </time>
  );
}
