import React, { useState } from 'react';
import Button from 'components/shared/Button';
import classNames from 'classnames';

export default function ExpandableContent({
  expandedByDefault = false,
  buttonStyle,
  expandText = 'Show',
  collapseText = 'Hide',
  allowCollapsing = true,
  onExpand,
  isMobile,
  children,
}) {
  const [expanded, toggleContent] = useState(expandedByDefault);
  const handleToggleContent = () => {
    onExpand && onExpand();
    toggleContent(!expanded);
  };

  return (
    <div>
      <div
        className={classNames({
          db: expanded,
          dn: !expanded,
        })}
      >
        {children}
      </div>
      {(!expanded || allowCollapsing) && (
        <Button
          buttonStyle={buttonStyle}
          size="medium"
          className={classNames({
            col5: isMobile,
            col6: !isMobile,
          })}
          onClick={handleToggleContent}
        >
          {expanded ? collapseText : expandText}
        </Button>
      )}
    </div>
  );
}
