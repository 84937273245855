import React, { Component } from 'react';
import { func, string } from 'prop-types';
import Dialog from 'components/shared/Dialog';
import Button, { ButtonStyles } from 'components/shared/Button';
import Input from 'components/shared/Input';

const RESPONSE_MACRO = {
  PRICE_ADJUSTMENT: {
    label: 'Price Adjustment',
    content: `{NAME} is in need of a price adjustment on {{ITEM}} that was purchased within the last 7 days. Can you please issue store credit to their account in the amount of the price difference?`,
  },
  SWAP_CREDIT: {
    label: 'Swap Credit Refunds',
    content: `{NAME} has a store credit balance under their Everlane account, that they’d prefer to be swapped back to their original form of payment. Could you please process a refund for the total amount?`,
  },
  UNRECEVIED_ITEM: {
    label: 'Unreceived Item',
    content: `{NAME} had a missing item in their recent order. They did not receive the {{ITEM}}. Can we check where it is and re-ship it if need be?`,
  },
  MISC_RETURNS: {
    label: 'Misc. returns',
    content: `{NAME} is returning {{ITEMS}} to {STOREFRONT_NAME} and would like a refund to their original form of payment.`,
  },
  MISC: {
    label: 'Misc.',
    content: `{NAME} is here at {STOREFRONT_NAME} with us, looking for additional info on {{SCENARIO}}.`,
  },
};

const RESPONSE_GREETINGS = ['Hey CX', 'Hi CX', 'Hi CX friends'];
const RESPONSE_SALUTATIONS = [
  'Thanks for your help, CX!',
  'Thanks a bunch, CX!',
  'Thanks for your help on this one, CX!',
  'Thanks for helping us out, CX!',
];
const REACH_OUT_MESSAGE = `{NAME}, if there’s anything you’d like to add, please reply to this email. Otherwise, hold tight—our Customer Experience (CX) team will be reaching back out soon.`;

export default class StorefrontTicketDialog extends Component {
  static propTypes = {
    initialCustomerFullName: string,
    initialCustomerEmail: string,
    initialOrderNumber: string,
    storefrontName: string.isRequired,
    onRequestClose: func.isRequired,
  };

  state = {
    customer_full_name: this.props.initialCustomerFullName,
    customer_email: this.props.initialCustomerEmail,
    description: null,
    order_number: this.props.initialOrderNumber,
    responseMacro: null,
    greeting:
      RESPONSE_GREETINGS[Math.floor(Math.random() * RESPONSE_GREETINGS.length)],
    salutation:
      RESPONSE_SALUTATIONS[
        Math.floor(Math.random() * RESPONSE_SALUTATIONS.length)
      ],
  };

  componentDidUpdate(prevProp, prevState) {
    if (
      prevState.customer_full_name !== this.state.customer_full_name &&
      this.state.description
    ) {
      this.setState({
        description: this.getFormattedResponseMacro(
          RESPONSE_MACRO[this.state.responseMacro].content,
        ),
      });
    }
  }

  maybeReplaceVariablesInMessage(message) {
    let formattedMessage = message;

    if (this.state.customer_full_name) {
      formattedMessage = message.replace(
        /{NAME}/g,
        this.state.customer_full_name,
      );
    }

    if (this.props.storefrontName) {
      formattedMessage = formattedMessage.replace(
        /{STOREFRONT_NAME}/g,
        this.props.storefrontName,
      );
    }

    return formattedMessage;
  }

  getFormattedResponseMacro(messageContent) {
    return [
      `${this.state.greeting},`,
      this.maybeReplaceVariablesInMessage(messageContent),
      this.maybeReplaceVariablesInMessage(REACH_OUT_MESSAGE),
      this.state.salutation,
    ].join('\n\n');
  }

  onSubmit = event => {
    event.preventDefault();

    const remainingTemplateStrings =
      this.state.description.match(/{{([^}]+)}}/);

    if (remainingTemplateStrings) {
      return window.alert(
        `Please modify the following prefilled text: ${remainingTemplateStrings[0]}`,
      );
    }

    if (this.state.customer_full_name.trim().length === 0) {
      return window.alert('Please enter a name.');
    }

    this.props.onFormSubmit(this.state);
  };

  render() {
    return (
      <Dialog
        simple={false}
        onRequestClose={this.props.onRequestClose}
        title="New CX Ticket"
        height={null}
        width="75vw"
      >
        <div className="w-75 pv3 center">
          <form
            className="flex flex-column items-center justify-center h-100"
            onSubmit={this.onSubmit}
          >
            <div className="flex w-100 space-between mb4">
              <Input
                required
                className="w-50 mr2"
                value={this.state.customer_full_name}
                placeholder="Enter Customer Name"
                onChange={event =>
                  this.setState({ customer_full_name: event.target.value })
                }
                id="customerName"
                label="Customer Name"
              />

              <Input
                required
                type="email"
                className="w-50 ml2"
                value={this.state.customer_email}
                placeholder="Enter Customer Email"
                onChange={event =>
                  this.setState({ customer_email: event.target.value })
                }
                id="custmerEmail"
                label="Customer Email"
              />
            </div>

            <div className="flex w-100 space-between mb4">
              <Input
                required={false}
                className="w-50 mr2"
                value={this.state.order_number}
                placeholder="Order Number"
                onChange={event =>
                  this.setState({ order_number: event.target.value })
                }
                id="orderNumber"
                label="Order Number"
              />

              <div className="w-50 ml2">
                <label className="db f5 tl" style={{ marginBottom: '0.2rem' }}>
                  Prefilled Text
                </label>
                <select
                  className="ba b--gray-6 f4 h6 ph1 w-100 br0 bg-white sans-serif"
                  value={this.state.responseMacro || ''}
                  onChange={event => {
                    if (event.target.value.length) {
                      this.setState({
                        responseMacro: event.target.value,
                        description: this.getFormattedResponseMacro(
                          RESPONSE_MACRO[event.target.value].content,
                        ),
                      });
                    }
                  }}
                >
                  <option value="">---</option>
                  {Object.keys(RESPONSE_MACRO).map(responseKey => (
                    <option key={responseKey} value={responseKey}>
                      {RESPONSE_MACRO[responseKey].label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <p className="mt0 mb1 w-100 f5 gray-5">
              Please add a comment below describing the issue at hand.
              <br />
              Let us know specific dollar amounts or if any additional orders
              are affected.
              <br />
              <span className="fw5">
                Note: this response will also be sent to the customer.
              </span>
            </p>
            <textarea
              required
              className="w-100 h12 mb2 pa1 f5 lh-copy b--gray-6 gray-2"
              placeholder=""
              value={this.state.description}
              onChange={event =>
                this.setState({ description: event.target.value })
              }
            />

            <Button
              type="submit"
              buttonStyle={ButtonStyles.TERTIARY}
              size="full-width"
              className="f4"
            >
              Send Email
            </Button>
          </form>
        </div>
      </Dialog>
    );
  }
}
