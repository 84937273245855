import React, { useState } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import ProductSearchHit from 'components/product-search/ProductSearchHit';
import Button, { ButtonStyles } from 'components/shared/Button';
import { HITS_PER_PAGE } from 'components/product-search/algolia';

const ProductSearchHits = ({
  hits,
  refineNext,
  storefront,
  sale,
  upcProducts,
}) => {
  const handleShowMore = () => refineNext();
  // we can only view one product's inventory at a time
  const [expandedHitId, setExpandedHitId] = useState(null);

  const products = upcProducts ? upcProducts : hits;

  return (
    <div className="flex flex-wrap w-100">
      {products
        .filter((p) => typeof p !== 'undefined')
        .map((hit, index) => {
          return (
            <ProductSearchHit
              isExpanded={hit.objectID === expandedHitId}
              setExpanded={setExpandedHitId}
              index={index}
              product={hit}
              storefront={storefront}
              sale={sale}
              key={hit.objectID}
            />
          );
        })}
      {hits.length >= HITS_PER_PAGE && (
        <div className="flex w-100 justify-center mv3">
          <Button onClick={handleShowMore} buttonStyle={ButtonStyles.TERTIARY}>
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

export default connectInfiniteHits(ProductSearchHits);
