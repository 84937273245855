import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import { webSocketUrl } from 'lib/url';
import PageContent from 'containers/PageContent';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';

setDefaultBreakpoints([{ phone: 0 }, { tablet: 700 }, { desktop: 1400 }]);

export default function Root({ store }) {
  return (
    <ActionCableProvider url={webSocketUrl()}>
      <Provider store={store}>
        <BrowserRouter>
          <BreakpointProvider>
            <PageContent />
          </BreakpointProvider>
        </BrowserRouter>
      </Provider>
    </ActionCableProvider>
  );
}
