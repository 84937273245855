import Dialog from 'components/shared/Dialog';
import ReaderList from 'components/shared/ReaderList';

export default function StripeReaderListDialog({
  onRequestClose,
  stripeLocationId,
}) {
  return (
    <Dialog
      simple={false}
      color="blue-4"
      textColor="white"
      onRequestClose={onRequestClose}
      title="Find a nearby reader"
      height={null}
      width="60vw"
    >
      <ReaderList stripeLocationId={stripeLocationId} />
    </Dialog>
  );
}
