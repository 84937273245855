import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pubWithReturn, Event } from 'lib/events';
import {
  getReturnReceiptById,
  getReturnReceiptStatus,
  Status,
  getIsReturnableForCash,
} from 'reducers/returnReceipts';
import { getIsAssociateAllowedToAcceptCash } from 'reducers/associate';
import {
  addItemToReturn,
  deleteReturnReceipt,
  processReturnReceipt,
} from 'actions/asynchronous';
import { setRefundMethodForReturnReceipt } from 'actions/synchronous';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import RefundMethod from 'components/returns/RefundMethod';
import CTA from 'components/returns/CTA';

class AddRefundMethodToReturn extends Component {
  componentDidMount() {
    pubWithReturn(Event.ReturnRefundMethod.PAGE_VIEW);
  }

  handleClickRefundMethod = refundMethod => {
    if (refundMethod.type === 'store_credit') {
      pubWithReturn(Event.ReturnRefundMethod.SELECT_STORE_CREDIT);
    } else {
      pubWithReturn(Event.ReturnRefundMethod.SELECT_ORIGINAL_PAYMENT);
    }

    this.props.setRefundMethodForReturnReceipt(refundMethod);
  };

  handleCTAClick = () => {
    const { storefrontId, returnReceiptId } = this.props.match.params;

    if (this.props.returnReceiptStatus === Status.READY_FOR_COMPLETION) {
      pubWithReturn(Event.Cart.COMPLETE_RETURN_MOBILE, {
        storefrontId,
        timestamp: new Date(),
      });
      this.props
        .processReturnReceipt(this.props.returnReceipt.refundMethod)
        .then(() => {
          this.props.history.push(
            `/storefronts/${storefrontId}/signature/success?returnNumber=${returnReceiptId}`,
          );
        });
    }
  };

  render() {
    const { params } = this.props.match;
    const { returnReceipt } = this.props;
    const refundText =
      this.props.isReturnableForCash &&
      !this.props.isAssociateAllowedToAcceptCash
        ? 'This is a cash refund and needs a lead to continue.'
        : 'Select a Refund Method';

    return (
      <div>
        <TopBar
          color="red-5"
          textColor="white"
          left={
            <Link
              to={`/storefronts/${params.storefrontId}/returns/${params.returnReceiptId}/items`}
              className="no-underline"
              onClick={() =>
                pubWithReturn(Event.ReturnRefundMethod.BACK_TO_ADD_ITEMS)
              }
            >
              <IconWithLabel
                icon="arrow-left"
                iconClassName="f5 pr1"
                label="Add Items"
              />
            </Link>
          }
        />
        <div className="f3 ph3 mv2">{refundText}</div>
        <div className="flex flex-wrap ma2">
          {returnReceipt.refund_methods.map(refundMethod => (
            <RefundMethod
              key={refundMethod.type}
              refundMethod={refundMethod}
              isActive={refundMethod === returnReceipt.refundMethod}
              onClick={() => this.handleClickRefundMethod(refundMethod)}
              canShowCashOption={
                this.props.isAssociateAllowedToAcceptCash &&
                refundMethod.type === 'cash'
              }
            />
          ))}
        </div>
        {returnReceipt.refundMethod && (
          <CTA
            className="absolute center w-80 pa2 "
            style={{ bottom: '20px', left: '10%', height: 'auto' }}
            returnReceipt={this.props.returnReceipt}
            returnReceiptStatus={this.props.returnReceiptStatus}
            onClick={this.handleCTAClick}
            location={this.props.location}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    returnReceipt: getReturnReceiptById(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
    returnReceiptStatus: getReturnReceiptStatus(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
      ownProps.location.pathname,
    ),
    isReturnableForCash: getIsReturnableForCash(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
    isAssociateAllowedToAcceptCash: getIsAssociateAllowedToAcceptCash(
      state.associate,
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { params } = ownProps.match;
  return {
    setRefundMethodForReturnReceipt: refundMethod =>
      dispatch(
        setRefundMethodForReturnReceipt(params.returnReceiptId, refundMethod),
      ),
    deleteReturnReceipt: () =>
      dispatch(deleteReturnReceipt(params.returnReceiptId)),
    addItemToReturn: item =>
      dispatch(
        addItemToReturn(params.storefrontId, params.returnReceiptId, item),
      ),
    processReturnReceipt: refundMethod =>
      dispatch(processReturnReceipt(params.returnReceiptId, refundMethod)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddRefundMethodToReturn);
