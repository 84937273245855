import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UPCScanner } from 'lib/keyboard';
import { pubWithReturn, Event } from 'lib/events';
import { getReturnReceiptById } from 'reducers/returnReceipts';
import { addItemToReturn, deleteReturnReceipt } from 'actions/asynchronous';
import {
  receiveReturnReceipt,
  showDialog,
  hideDialog,
} from 'actions/synchronous';
import TopBar from 'components/shared/TopBar';
import IconWithLabel from 'components/shared/IconWithLabel';
import Scrollable from 'components/shared/Scrollable';
import BigSearchField from 'components/shared/BigSearchField';
import OrderInfo from 'components/returns/OrderInfo';
import ReturnUnit from 'components/returns/ReturnUnit';
import PackageOpenedDialog from 'components/returns/PackageOpenedDialog';

class AddItemsToReturn extends Component {
  state = {
    isListeningForScans: true,
  };

  constructor(props) {
    super(props);
    this.scanner = new UPCScanner({
      callback: this.addItemToReturnByVariantUPC,
    });
  }

  componentDidMount() {
    pubWithReturn(Event.Return.PAGE_VIEW);
    this.scanner.listen();
  }

  componentWillUnmount() {
    this.scanner.stopListening();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isListeningForScans === prevState.isListeningForScans) {
      return;
    }

    if (this.state.isListeningForScans) {
      this.scanner.listen();
    } else {
      this.scanner.stopListening();
    }
  }

  addItemToReturnByVariantUPC = variantUPC => {
    pubWithReturn(Event.Return.ENTER_SEARCH, { text: variantUPC });

    const returnItem = this.props.returnReceipt.items.find(
      item => item.variant_upc === variantUPC,
    );

    if (returnItem) {
      this.determineHowToAddReturnItem(returnItem);
    } else {
      window.alert('This item is not in this sale');
    }
  };

  handleClickBackButton = () => {
    if (window.confirm('Are you sure that you want to cancel this return?')) {
      this.props.deleteReturnReceipt();
      pubWithReturn(Event.Return.BACK_TO_RETURNS_HOME);
      this.props.history.push(
        `/storefronts/${this.props.match.params.storefrontId}/returns`,
      );
    }
  };

  addItemToReturn = returnItem => {
    this.props.addItemToReturn(returnItem).then(() =>
      pubWithReturn(Event.Return.ADD_ITEM_TO_RETURN, {
        product_id: returnItem.product_id,
      }),
    );
  };

  // The rules for adding an item are as follows:
  // If the item is a "single return" item:
  //   If they have used their first try:
  //     Check if the item has been opened, mark if so, and then add normally.
  //     (later, the only return reasons will only be "defective" and "incorrect")
  //   If they haven't used their first try:
  //     Add normally.
  // If the item is none of the above:
  //   Add normally.
  determineHowToAddReturnItem = returnItem => {
    if (returnItem.single_return_variant && !returnItem.first_try_allowed) {
      return this.props.showDialog(
        <PackageOpenedDialog
          onRequestClose={this.props.hideDialog}
          onSelectChoice={({ isOpened }) => {
            this.props.hideDialog();

            if (isOpened) {
              this.props.onMarkLineItemAsOpened(returnItem);
            }

            this.addItemToReturn(returnItem);
          }}
        />,
      );
    }

    this.addItemToReturn(returnItem);
  };

  render() {
    return (
      <div>
        <TopBar
          color="red-5"
          textColor="white"
          left={
            <IconWithLabel
              onClick={this.handleClickBackButton}
              icon="arrow-left"
              iconClassName="f5 pr1"
              label="Returns Home"
            />
          }
          center={
            <span className="f4">
              {this.props.returnReceipt.is_gift_return
                ? 'Add Items to Gift Return'
                : 'Add Items to Standard Return'}
            </span>
          }
        />
        <BigSearchField
          placeholderText="Scan item barcode or type UPC to add to return…"
          onSubmit={this.addItemToReturnByVariantUPC}
          onClearClick={() => pubWithReturn(Event.Return.CLEAR_SEARCH)}
          onBlur={() => this.setState({ isListeningForScans: true })}
          onFocus={() => this.setState({ isListeningForScans: false })}
          buttonColor="red-5"
        />

        <Scrollable>
          <OrderInfo
            orderNumber={this.props.returnReceipt.order_number}
            completedAt={this.props.returnReceipt.completed_at}
            locationName={this.props.returnReceipt.location_name}
            orderPayoutSummary={this.props.returnReceipt.order_payout_summary}
          />
          {this.props.returnReceipt.items.map((item, index) => (
            <ReturnUnit
              key={index}
              item={item}
              onAddToReturnReceipt={this.determineHowToAddReturnItem}
            />
          ))}
          {this.props.returnReceipt.returned_items.map((item, index) => (
            <ReturnUnit
              disabled
              key={index}
              item={item}
              onAddToReturnReceipt={() => null}
            />
          ))}
        </Scrollable>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    returnReceipt: getReturnReceiptById(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { params } = ownProps.match;
  return {
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    receiveReturnReceipt: returnReceipt =>
      dispatch(receiveReturnReceipt(returnReceipt)),
    deleteReturnReceipt: () =>
      dispatch(deleteReturnReceipt(params.returnReceiptId)),
    addItemToReturn: item =>
      dispatch(
        addItemToReturn(params.storefrontId, params.returnReceiptId, item),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddItemsToReturn);
