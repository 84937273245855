import React, { Component } from 'react';
import { func, number, oneOf, bool } from 'prop-types';
import Paginate from 'react-paginate';
import classNames from 'classnames';
import Icon from 'components/shared/Icon';

export default class Pagination extends Component {
  static propTypes = {
    limit: number.isRequired,
    totalCount: number.isRequired,
    onPageChange: func.isRequired,
    width: oneOf(['partial', 'full']),
    isMobile: bool,
  };

  paginationWidth = {
    partial: 'w-70',
    full: 'w-100',
  }[this.props.width];

  state = {
    page: 1,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.props.onPageChange({ page: this.state.page });
    }
  }

  render() {
    return (
      <Paginate
        key={this.state.page}
        containerClassName={classNames(
          'flex items-center justify-center list ma0 pa0 gray-6 bg-white bt b--gray-9',
          this.paginationWidth,
          {
            'fixed bottom-0 h10': !this.props.isMobile,
            h6: this.props.isMobile,
          },
        )}
        pageClassName="pa2 dib"
        activeClassName="pa2 dib gray-2"
        nextClassName="dib pa1"
        previousClassName="dib pa1"
        breakClassName="gray-9 dib"
        pageCount={Math.ceil(this.props.totalCount / this.props.limit)}
        disableInitialCallback={true}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        initialPage={this.state.page - 1}
        onPageChange={({ selected }) => {
          this.setState({ page: selected + 1 });
        }}
        previousLabel={
          <Icon
            color={this.state.page > 1 ? 'gray-6' : 'gray-9'}
            glyph="arrow-left"
            className="relative f5"
            style={{ top: '1px' }}
          />
        }
        nextLabel={
          <Icon
            color={
              this.limit * this.state.page < this.props.totalCount
                ? 'gray-6'
                : 'gray-9'
            }
            glyph="arrow-right"
            className="relative f5"
            style={{ top: '1px' }}
          />
        }
      />
    );
  }
}
