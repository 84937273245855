import React, { Component } from 'react';
import { func, object } from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/shared/Icon';

export default class CategoryNav extends Component {
  static propTypes = {
    onSubcategoryClick: func,
    categoryCounts: object,
  };

  state = {
    categories: [
      { name: 'female', title: 'Women', isOpen: false },
      { name: 'male', title: 'Men', isOpen: false },
      { name: 'unisex', title: 'Unisex', isOpen: false },
    ],
    selectedSubcategory: null,
    selectedCategory: null,
  };

  getSubcategories = category => {
    const subcategories = Object.keys(
      this.props.categoryCounts[category.name],
    ).filter(mainCategory => mainCategory !== 'total');

    return subcategories;
  };

  handleCategoryClick = (category, subcategories = []) => {
    if (subcategories.length > 0) {
      let updatedCategories = this.state.categories.map(cat => {
        const modifiedCat = cat;
        if (cat.name === category.name) {
          modifiedCat.isOpen = !cat.isOpen;
        }

        return modifiedCat;
      });

      this.setState({ categories: updatedCategories });
    }
  };

  handleSubcategoryClick = (selectedCategory, selectedSubcategory) => {
    this.setState({ selectedSubcategory, selectedCategory });
    this.props.onSubcategoryClick(selectedCategory, selectedSubcategory);
  };

  renderCategories() {
    const sections = this.state.categories.map((category, index) => {
      const subcategories = this.getSubcategories(category);
      const count = this.props.categoryCounts[category.name]
        ? this.props.categoryCounts[category.name].total
        : 0;

      const handleClick = () =>
        this.handleCategoryClick(category, subcategories);

      return (
        <li key={`${category}-${index}`}>
          <div
            className="h-100 pv2 ph3 bb b--gray-9 fw5 f4 relative"
            onClick={handleClick}
          >
            <span>{category.title}</span>
            <span className="pl2 red-6">{count}</span>
            {count > 0 && (
              <Icon
                color="black"
                glyph="arrow-right"
                className="absolute"
                style={{
                  transition: 'transform 250ms',
                  transform: category.isOpen
                    ? 'rotate(270deg)'
                    : 'rotate(90deg)',
                  right: '20px',
                  top: '20px',
                }}
              />
            )}
          </div>
          {category.isOpen &&
            subcategories.length > 0 &&
            this.renderSubcategories(category.name, subcategories)}
        </li>
      );
    });

    return <ul className="pl0 mv0 overflow-y-scroll vh-100">{sections}</ul>;
  }

  renderSubcategories(category, subcategories) {
    if (!subcategories.length) {
      return;
    }

    const subcategoryItems = ['All', ...subcategories].map(
      (subcategory, index) => {
        const subcategoryClassNames = classNames('flex h5 f4 ph3', {
          'bg-gray-9':
            this.state.selectedSubcategory === subcategory &&
            this.state.selectedCategory === category,
        });

        const number =
          subcategory === 'All'
            ? this.props.categoryCounts[category].total
            : this.props.categoryCounts[category][subcategory].length;

        const handleClick = () =>
          this.handleSubcategoryClick(category, subcategory);

        return (
          <li
            key={`subcategory-${index}`}
            className={subcategoryClassNames}
            onClick={handleClick}
          >
            <span className="w-90 tl mv1">{subcategory}</span>
            <span className="w-10 tr mv1">{number}</span>
          </li>
        );
      },
    );
    return <ul className="h-100 pl0 bb b--gray-9">{subcategoryItems}</ul>;
  }

  render() {
    return (
      <nav className="fl w-25 h-100 br b--gray-9">
        <div
          className={classNames('h-100 pv2 ph3 bb b--gray-9 fw5 f4', {
            'bg-gray-9': !this.state.selectedSubcategory,
          })}
          onClick={this.handleSubcategoryClick}
        >
          <span>All Items</span>
          <span className="pl2 red-6">{this.props.categoryCounts.total}</span>
        </div>
        {this.renderCategories()}
      </nav>
    );
  }
}
