import React, { Component, Fragment } from 'react';
import Button, { ButtonStyles } from 'components/shared/Button';
import { connect } from 'react-redux';
import { get, post } from 'lib/network';
import { retailApiUrl } from 'lib/url';
import {
  showDialog,
  hideDialog,
  startRequest,
  finishRequest,
  showNotification,
} from 'actions/synchronous';
import InventoryTransferDialog from 'components/returns-queue/InventoryTransferDialog';
import { NotificationTypes } from 'reducers/notifications';
import { pub, Event } from 'lib/events';
import InventoryTransferItems from 'components/inventory-transfers/InventoryTransferItems';
import SearchField from 'components/inventory-transfers/SearchField';

export class ViewTransfer extends Component {
  state = {
    inventoryTransfer: {},
    scanError: null,
  };

  componentDidMount() {
    this.fetchInventoryTransfer();
  }

  fetchInventoryTransfer = async (query, queryType) => {
    this.props.startRequest();

    try {
      const { response, responseJson } = await get(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${this.props.match.params.id}`,
        ),
      );

      if (response.ok) {
        this.setState({ inventoryTransfer: responseJson });
      } else {
        this.props.showError(responseJson.message);
      }
    } catch (e) {
      this.props.showError(e.message);
    } finally {
      this.props.finishRequest();
    }
  };

  createInventoryTransferItem = async upc => {
    this.props.startRequest();
    try {
      const { response, responseJson } = await post(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${this.state.inventoryTransfer.id}/inventory_transfer_items/increment`,
        ),
        { upc },
      );

      if (response.ok) {
        this.fetchInventoryTransfer();
      } else {
        this.setState({ scanError: 'There was an error. Please scan again.' });
        showNotification(NotificationTypes.ERROR, responseJson.message);
      }
    } catch (err) {
      showNotification(NotificationTypes.ERROR, err.message);
    } finally {
      this.props.finishRequest();
    }
  };

  removeInventoryTransferItem = async upc => {
    try {
      const { response, responseJson } = await post(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${this.state.inventoryTransfer.id}/inventory_transfer_items/decrement`,
        ),
        { upc },
      );

      if (response.ok) {
        this.fetchInventoryTransfer();
      } else {
        showNotification(NotificationTypes.ERROR, responseJson.message);
      }
    } catch (err) {
      showNotification(NotificationTypes.ERROR, err.message);
    }
  };

  completeInventoryTransfer = async email => {
    try {
      const { response, responseJson } = await post(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${this.state.inventoryTransfer.id}/complete`,
        ),
        { email_to: email },
      );

      if (response.ok) {
        await this.fetchInventoryTransfer();

        showNotification(
          NotificationTypes.INFO,
          'Inventory transfer has been completed.',
        );

        pub(Event.OutboundInventoryTransfers.COMPLETE);
      } else {
        showNotification(NotificationTypes.ERROR, responseJson.message);
      }
    } catch (err) {
      showNotification(NotificationTypes.ERROR, err.message);
    }
  };

  handleScannedItem = async upc => {
    if (!this.state.inventoryTransfer) {
      this.setState({ scanError: 'There was an error. Please scan again.' });
      return;
    }

    this.setState({ scanError: null });
    this.createInventoryTransferItem(upc);
  };

  showCompleteTransferModal = () => {
    this.props.showDialog(
      <InventoryTransferDialog
        onRequestClose={this.props.hideDialog}
        onFormSubmit={async email => {
          await this.completeInventoryTransfer(email);
          this.props.hideDialog();
        }}
      />,
    );
  };

  render() {
    return (
      <Fragment>
        <div className="pa2 pl3 bb b--gray-9">
          {this.state.inventoryTransfer.number}
        </div>

        {this.state.inventoryTransfer.items?.length > 0 && (
          <InventoryTransferItems
            items={this.state.inventoryTransfer.items}
            canDelete={
              this.state.inventoryTransfer.is_editable &&
              this.state.inventoryTransfer.state !== 'scheduled'
            }
            onDeleteClick={this.removeInventoryTransferItem}
          />
        )}

        {!!this.state.inventoryTransfer.number &&
          this.state.inventoryTransfer.items?.length === 0 && (
            <div className="flex flex-column bg-gray-9 flex-auto pb12">
              <div className="tc gray-6 flex flex-grow-1 flex-column justify-center">
                Scan your first item.
              </div>
            </div>
          )}

        {this.state.inventoryTransfer.state === 'in_progress' && (
          <div className="bb b--gray-9 w-100 absolute bottom-0 shadow-2 bg-white">
            {this.state.scanError && (
              <p className="red ph3">{this.state.scanError}</p>
            )}

            <SearchField
              className="bt"
              placeholderText="Scan item UPC..."
              onSubmit={this.handleScannedItem}
              onClearClick={this.fetchInventoryTransfer}
              isLoading={this.props.isLoading}
              debounceAmount={0} // Allows for rapid inupt succession
              shouldRenderIcons
              clearOnSubmit
              autoFocus
            />

            {this.state.inventoryTransfer.items?.length > 0 && (
              <div className="pa3">
                <Button
                  onClick={this.showCompleteTransferModal}
                  buttonStyle={ButtonStyles.SECONDARY}
                  size="full-width"
                  className="f5"
                >
                  Complete {this.state.inventoryTransfer.number}
                </Button>
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showError: message =>
      dispatch(showNotification(NotificationTypes.ERROR, message)),
  };
}

export default connect(null, mapDispatchToProps)(ViewTransfer);
