import React from 'react';
import PageWithHeader from 'components/shared/PageWithHeader';
import SubHeader from 'components/shared/SubHeader';
import { getQueryParam } from 'lib/url';

export default function NotFound(props) {
  return (
    <PageWithHeader
      title="Not Found"
      onBackArrowClick={() => {
        const storefrontId = getQueryParam('storefrontId');
        if (storefrontId) {
          props.history.push(`/storefronts/${storefrontId}/customers`);
        } else {
          props.history.push('/');
        }
      }}
      backArrowLabel="Home"
    >
      <SubHeader>
        This page cannot be found or is no longer available.
      </SubHeader>
      <div className="pa3">
        Some possible reasons that this could happen:
        <br />
        <ul>
          <li>
            You are trying to view a sale but that sale has been completed
          </li>
          <li>
            You are trying to view a return but that return has been completed
          </li>
          <li>The URL is malformed or typed incorrectly</li>
        </ul>
      </div>
    </PageWithHeader>
  );
}
