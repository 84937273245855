import React, { Component, Fragment } from 'react';
import { string, func, bool, object, number } from 'prop-types';
import classNames from 'classnames';
import Button, { ButtonStyles } from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import { pubWithSale, Event } from 'lib/events';

function debounce(callback, amountOfTimeInMs) {
  let preventCalls = false;

  return (...args) => {
    if (!preventCalls) {
      callback(...args);
    }

    preventCalls = true;

    setTimeout(() => {
      preventCalls = false;
    }, amountOfTimeInMs);
  };
}

export default class BigSearchField extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  static propTypes = {
    autoFocus: bool,
    value: string,
    className: string,
    initialInput: string,
    placeholderText: string,
    onSubmit: func.isRequired,
    onClearClick: func,
    isLoading: bool,
    isMobile: bool,
    style: object,
    iconColor: string,
    buttonColor: string,
    appendButtonColor: string,
    shouldRenderAppendButtons: bool,
    shouldRenderIcons: bool,
    clearOnSubmit: bool,
    debounceAmount: number,
    buttonText: string,
  };

  static defaultProps = {
    placeholderText:
      'Search for an existing customer by name or email, or enter a new email to create a new customer...',
    isLoading: false,
    isMobile: false,
    style: {},
    clearOnSubmit: false,
    debounceAmount: 7000,
    buttonText: 'Search',
    buttonColor: 'white',
  };

  state = {
    input: this.props.initialInput || '',
    isFocused: false,
    hasSubmitted: !!this.props.initialInput || false,
  };

  handleInput = event => {
    this.setState({
      input: event.target.value,
      hasSubmitted: false,
    });
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleSubmit = event => {
    this.setState({ hasSubmitted: true });
    this.props.onSubmit(this.state.input);

    if (this.props.clearOnSubmit) {
      this.setState({ input: '', hasSubmitted: false });
    }
  };

  debouncedHandleSubmit = debounce(
    this.handleSubmit,
    this.props.debounceAmount,
  );

  handleClearClick = event => {
    event.preventDefault();

    this.setState({
      input: '',
      hasSubmitted: false,
    });

    if (this.props.onClearClick) {
      this.props.onClearClick();
    }
  };

  // The button for the search field changes its display based on the current state
  //
  // Whilist waiting for XHR request: no button needed!
  // User has submitted their search: an "x" icon that clears the search upon click
  // User has started typing a query: a "search" button that will submit the request
  // User has not yet typed a query: a magnifying glass icon
  renderButton() {
    const iconClassName = `absolute center-v right-0 pa4 ${this.props.iconColor}`;
    const shouldRenderIcons =
      this.props.shouldRenderIcons || this.props.isMobile;

    if (this.state.hasSubmitted) {
      return (
        <Icon
          glyph="x"
          color="gray-5"
          className={iconClassName}
          onClick={this.handleClearClick}
        />
      );
    } else if (this.state.input.length) {
      return (
        <Button
          buttonStyle={!shouldRenderIcons && ButtonStyles.PRIMARY}
          onClick={this.debouncedHandleSubmit}
          color={this.props.buttonColor}
          size="medium"
          className={classNames('absolute center-v right-0', {
            'gray-6 bg-transparent': shouldRenderIcons,
          })}
        >
          {shouldRenderIcons ? (
            <Icon
              glyph="arrow-right"
              color="gray-5"
              className={iconClassName}
            />
          ) : (
            this.props.buttonText
          )}
        </Button>
      );
    }

    return (
      <Icon
        glyph="magnify"
        color="gray-5"
        className={classNames(iconClassName, 'fw7')}
      />
    );
  }

  renderQuickAppendButtons() {
    const values = this.props.isMobile
      ? ['@gmail.com', '@yahoo.com']
      : ['@', '.com', '@gmail.com', '@yahoo.com'];

    return (
      <div className="flex mt3 ml2" style={{ justifyContent: 'space-evenly' }}>
        {values.map(value => {
          return (
            <Button
              type="button"
              className={`bg-${this.props.appendButtonColor} white f5 mr2 br4 highlight-on-click`}
              style={{
                border: '1px solid white',
                height: '40px',
                flex: 1,
              }}
              onClick={() => {
                pubWithSale(Event.SaleAuth.QUICK_APPEND, { value });
                this.setState({
                  input: `${this.state.input}${value}`,
                });
                this.input && this.input.current.focus();
              }}
            >
              {value}
            </Button>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <form
          onSubmit={event => {
            event.preventDefault();
            this.debouncedHandleSubmit();
          }}
          className={classNames(
            'bb b--gray-9 relative dib w-100',
            this.props.className,
          )}
          style={this.props.style}
        >
          {!this.props.isLoading && this.renderButton()}
          <input
            ref={this.input}
            autoFocus={this.props.autoFocus}
            autoCapitalize="off"
            autoCorrect="off"
            className={classNames(
              'input-reset db bn sans-serif input-invisible outline-0 h9 w-90 ph3 f4',
              this.props.className,
            )}
            type="text"
            placeholder={this.props.placeholderText}
            value={this.state.input}
            onChange={this.handleInput}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </form>
        {this.props.shouldRenderAppendButtons &&
          this.renderQuickAppendButtons()}
      </Fragment>
    );
  }
}
