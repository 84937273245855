import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { post } from 'lib/network';
import { apiUrl, retailApiUrl } from 'lib/url';
import { pub, Event } from 'lib/events';
import {
  showNotification,
  showDialog,
  hideDialog,
  startRequest,
  finishRequest,
} from 'actions/synchronous';
import { createSaleItem, createSaleWithItem } from 'actions/asynchronous';
import Button, { ButtonStyles } from 'components/shared/Button';
import { NotificationTypes } from 'reducers/notifications';
import GiftCardPurchaseDialog from 'components/sales/GiftCardPurchaseDialog';
import WarehouseDialog from 'components/sales/WarehouseDialog';
import PrintLabelDialog from 'components/returns-queue/PrintLabelDialog';

const RETAIL_GC_UPC = '0-00000-55806-8'; // retail only

const ProductButtons = ({
  variant,
  storefront,
  product,
  showDialog,
  hideDialog,
  createSaleWithItem,
  createSaleItem,
  showNotification,
  sale,
  isMobile,
  shouldAddToSale,
  onAddMatchingVariant,
  startRequest,
  finishRequest,
  location,
  match,
  history,
}) => {
  const [hasBeenRequestedToTry, requestToTry] = useState(false);
  const [hasBeenWaitlisted, waitlist] = useState(false);

  useEffect(() => {
    if (shouldAddToSale) {
      addItemFromWarehouse(storefront.warehouse_id);
      onAddMatchingVariant(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAddToSale]);

  const isRetailGiftCard = variant && variant.upc === RETAIL_GC_UPC;

  const addItemFromWarehouse = async warehouseId => {
    if (isRetailGiftCard) {
      showDialog(
        <GiftCardPurchaseDialog
          isVariable={true}
          variant={variant}
          warehouseId={warehouseId}
          onAddItem={attrs =>
            createSaleItem({ ...attrs, saleNumber: sale.number })
          }
          onRequestClose={hideDialog}
        />,
      );

      return;
    }

    const creationFunction = sale ? createSaleItem : createSaleWithItem;
    try {
      const response = await creationFunction({
        saleNumber: sale ? sale.number : null,
        storefrontId: storefront.id,
        warehouseId,
        variantId: variant.variant_id,
        productId: variant.product_id,
        price: variant.lowest_price,
      });

      pub(Event.Sale.ADD_ITEM_TO_SALE, {
        sale_number: response.sale.number,
        product_id: variant.product_id,
      });

      // These buttons can appear on the stock check page, and if an item gets
      // added via that view, we want to redirect to the newly created sale page.
      if (location.pathname.includes('stock-check')) {
        history.push(
          `/storefronts/${storefront.id}/sales/${response.sale.number}`,
        );
      }
    } catch (err) {
      showNotification(
        NotificationTypes.ERROR,
        'An error occurred while adding this item. This sale is likely deleted. Please create a new sale to continue.',
      );
    }
  };

  const handleAddToSaleViaShipment = () => {
    return addItemFromWarehouse(variant.primary_warehouse_id);
  };

  const handleAddToSaleViaStorefront = warehouseId => {
    return addItemFromWarehouse(warehouseId);
  };

  // "Add to Sale" or "Start New Sale"
  const handleClickCTA = () => {
    if (!variant) {
      return;
    }

    const selectedInventory = variant.storefront_counts.find(
      storefrontCount => storefrontCount.storefront_id === storefront.id,
    );
    const warehouseId = storefront.warehouse_id;

    if (selectedInventory.available_quantity < 1 && !isRetailGiftCard) {
      showDialog(
        <WarehouseDialog
          isMobile={isMobile}
          variant={variant}
          storefront={storefront}
          onChooseOnline={handleAddToSaleViaShipment}
          onChooseStorefront={() => handleAddToSaleViaStorefront(warehouseId)}
          onRequestClose={hideDialog}
        />,
      );
      return;
    }

    addItemFromWarehouse(warehouseId);
  };

  const handleRequestToTry = async () => {
    try {
      startRequest();
      const { response } = await post(
        retailApiUrl(`/storefronts/${storefront.id}/storefront_tryon_requests`),
        {
          variant_id: variant.variant_id,
          product_id: variant.product_id,
        },
      );

      if (response.ok) {
        requestToTry(true);
        setTimeout(() => requestToTry(false), 2000);
      } else {
        showNotification(
          NotificationTypes.ERROR,
          'Something went wrong. Please try again.',
        );
      }

      finishRequest();
    } catch (err) {
      finishRequest();
      showNotification(NotificationTypes.ERROR, err.message);
    }
  };

  const handleWaitlist = async () => {
    if (sale && sale.customer) {
      const { response, responseJson } = await post(
        apiUrl('waitlist_reservations'),
        {
          waitlist_reservation: {
            variant_id: variant.variant_id,
            product_id: variant.product_id,
            email: sale.customer.email,
          },
          create_visitor_if_necessary: true,
        },
      );

      if (response.ok) {
        waitlist(true);
      } else {
        showNotification(
          NotificationTypes.ERROR,
          `An error occurred while waitlisting: ${responseJson.message}`,
        );
      }
    } else {
      history.push({
        pathname: `/storefronts/${match.params.storefrontId}/customers/waitlist`,
        state: {
          saleNumber: sale && sale.number,
          variant,
          product,
        },
      });
    }
  };

  const handlePrintLabel = () => {
    showDialog(
      <PrintLabelDialog
        title={product.name}
        size={variant.size}
        price={product.price}
        upc={variant.upc}
        onRequestClose={hideDialog}
        onFormSubmit={() => window.print()}
      />,
    );
  };

  return (
    <Fragment>
      <Button
        disabled={!variant}
        buttonStyle={ButtonStyles.SECONDARY}
        className="mb1"
        onClick={handleClickCTA}
      >
        {sale ? 'Add Item' : 'Start New Sale'}
      </Button>
      {variant && variant.orderable_state === 'waitlistable' && (
        <Button
          disabled={!variant}
          buttonStyle={ButtonStyles.TERTIARY}
          className="mb1"
          onClick={handleWaitlist}
        >
          {hasBeenWaitlisted ? 'Waitlisted' : 'Waitlist'}
        </Button>
      )}
      {!sale && storefront.request_to_try_enabled && (
        <Button
          disabled={!variant}
          buttonStyle={ButtonStyles.TERTIARY}
          className="mb1"
          onClick={handleRequestToTry}
        >
          {hasBeenRequestedToTry ? 'Requested' : 'Request To Try'}
        </Button>
      )}
      {!sale && !isMobile && (
        <Button
          disabled={!variant}
          buttonStyle={ButtonStyles.QUATERNARY}
          onClick={handlePrintLabel}
        >
          Print Label
        </Button>
      )}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return { isMobile: state.ui.isMobile };
}

function mapDispatchToProps(dispatch) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    createSaleWithItem: ({
      storefrontId,
      variantId,
      productId,
      warehouseId,
      price,
    }) =>
      dispatch(
        createSaleWithItem(storefrontId, {
          variantId,
          productId,
          warehouseId,
          price,
        }),
      ),
    createSaleItem: ({
      warehouseId,
      productId,
      variantId,
      price,
      unitId,
      unitType,
      saleNumber,
    }) =>
      dispatch(
        createSaleItem(saleNumber, {
          warehouseId,
          productId,
          variantId,
          price,
          unitId,
          unitType,
        }),
      ),
    showNotification: (key, message) =>
      dispatch(showNotification(key, message)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductButtons),
);
