import React, { Component, Fragment } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'css/react_dates_overrides.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { get, post } from 'lib/network';
import {
  startRequest,
  finishRequest,
  showNotification,
} from 'actions/synchronous';
import { apiUrl, retailApiUrl } from 'lib/url';

import { getIsLoading } from 'reducers/activeRequests';
import Pagination from 'components/shared/Pagination';
import BigSearchField from 'components/shared/BigSearchField';
import ItemsTable from 'components/sales/ItemsTable';
import SendReceiptDialog from 'components/sales/SendReceiptDialog';
import { DateRangePicker } from 'react-dates';
import { getReturnItems } from 'lib/getReturnItems';
import { getSaleItems } from 'lib/getSaleItems';
import Icon from 'components/shared/Icon';
import Checkbox from 'components/shared/Checkbox';
import Button, { ButtonStyles } from 'components/shared/Button';
import { NotificationTypes } from 'reducers/notifications';
import { handleSearchOrders } from 'lib/searchOrders';

class ReturnsAndSalesHistory extends Component {
  state = {
    orders: [],
    returnReceipts: [],
    page: 1,
    totalSalesCount: null,
    totalReturnsCount: null,
    showReceiptDialog: false,
    itemId: '',
    startDate: moment(),
    endDate: moment(),
    focusedInput: null,
    showCashOnly: false,
    showTodayOnly: true,
  };

  limit = 40;

  componentDidUpdate(prevProps, prevState) {
    const isSalesHistoryPage = window.location.href.includes('sales_history');

    if (
      prevProps.location !== this.props.location ||
      prevState.page !== this.state.page ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.showCashOnly !== this.state.showCashOnly
    ) {
      isSalesHistoryPage ? this.fetchOrders() : this.fetchReturns();
    }
  }

  componentDidMount() {
    this.fetchOrders();
  }

  fetchReturns = async query => {
    if (!this.state.startDate || !this.state.endDate) {
      return this.setState({
        returnReceipts: [],
        totalReturnsCount: null,
      });
    }

    this.props.startRequest();

    const data = {
      page: this.state.page,
      limit: this.limit,
      start_date: this.state.startDate || '',
      end_date: this.state.endDate || '',
    };

    if (this.state.showCashOnly) {
      data.cash_only = true;
    }

    if (query) {
      data.query = query;
    }

    const { response, responseJson } = await get(
      retailApiUrl(
        `storefronts/${this.props.match.params.storefrontId}/storefront_returns_history`,
      ),
      data,
    );

    if (response.ok) {
      const { return_receipts, metadata } = responseJson;
      this.setState({
        returnReceipts: return_receipts,
        totalReturnsCount: metadata.count,
      });
    }

    this.props.finishRequest();
  };

  fetchOrders = async (query, queryType) => {
    if (!this.state.startDate || !this.state.endDate) {
      return this.setState({
        orders: [],
        totalSalesCount: null,
        orderTotalForRange: null,
        uptForRange: null,
        aovForRange: null,
      });
    }

    this.props.startRequest();

    const data = {
      page: this.state.page,
      limit: this.limit,
      start_date: this.state.startDate || '',
      end_date: this.state.endDate || '',
      query_type: queryType,
    };

    if (this.state.showCashOnly) {
      data.cash_only = true;
    }

    if (query) {
      data.query = query;
    }

    const { response, responseJson } = await get(
      retailApiUrl(
        `storefronts/${this.props.match.params.storefrontId}/storefront_sales_history`,
      ),
      data,
    );

    if (response.ok) {
      const { orders, metadata } = responseJson;
      this.setState({
        orders,
        totalSalesCount: metadata.count,
        orderTotalForRange: metadata.order_total_for_range,
        uptForRange: metadata.upt_for_range,
        aovForRange: metadata.aov_for_range,
      });
    }

    this.props.finishRequest();
  };

  handleSendReceipt = async (email, type) => {
    this.props.startRequest();
    await post(retailApiUrl(`orders/${this.state.itemId}/send_receipt`), {
      email,
      type,
    });
    this.props.finishRequest();
    this.setState({
      showReceiptDialog: false,
      emailToSendReceiptTo: '',
      itemId: '',
    });
  };

  handleSendReturnReceipt = async email => {
    this.props.startRequest();
    await post(
      apiUrl(`return_receipts/${this.state.itemId}/send_return_receipt`),
      { email },
    );
    this.props.finishRequest();
    this.setState({
      showReceiptDialog: false,
      emailToSendReceiptTo: '',
      itemId: '',
    });
  };

  handleCancelOrder = async orderNumber => {
    if (window.confirm('Are you sure you want to cancel this order?')) {
      this.props.startRequest();

      const { response } = await post(
        retailApiUrl(`orders/${orderNumber}/cancel`),
      );

      this.props.finishRequest();

      if (response.ok) {
        alert('Order cancelled successfully!');
      } else {
        alert('This order could not be cancelled.');
      }

      this.fetchOrders();
    }
  };

  handlePageChange = ({ page }) => {
    this.setState({ page });
  };

  handleToggleCashFilter = () => {
    this.setState(prevState => {
      return { showCashOnly: !prevState.showCashOnly };
    });
  };

  handleSearch = (label, query, queryType) => {
    this.fetchOrders(query, queryType);
  };

  render() {
    const isSalesHistoryPage = window.location.href.includes('sales_history');

    const returnItems = getReturnItems(this.state.returnReceipts);
    const saleItems = getSaleItems(this.state.orders);

    const items = isSalesHistoryPage ? saleItems : returnItems;
    const totalItems = isSalesHistoryPage
      ? this.state.totalSalesCount
      : this.state.totalReturnsCount;

    const hasPagination = totalItems > this.limit;

    return (
      <div>
        <div>
          {isSalesHistoryPage && (
            <div className="flex-date-picker w-100 h-100">
              <BigSearchField
                className="b--none mr2"
                buttonColor="blue-4"
                placeholderText="Search for a sale or order by order number, full name, or email…"
                onSubmit={query =>
                  handleSearchOrders(
                    query,
                    this.props.showError,
                    this.handleSearch,
                  )
                }
                onClearClick={() => this.fetchOrders()}
                isLoading={this.props.isLoading}
              />
            </div>
          )}
        </div>
        <div className="flex-date-picker w-100 h6">
          <div className="gray-5 pr4 ml3">Filters:</div>
          <Icon color="gray-5" glyph="clock" />
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId=""
            endDate={this.state.endDate}
            endDateId=""
            onDatesChange={({ startDate, endDate }) =>
              this.setState({ startDate, endDate })
            }
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            isOutsideRange={() => false}
            minimumNights={0}
          />
          <Checkbox
            id="today-only-filter"
            label="Today Only"
            fontSize="f5"
            checked={this.state.showTodayOnly}
            onChange={() => {
              let startDate = null;
              let endDate = null;

              if (this.state.showTodayOnly) {
                this.setState({ startDate, endDate, showTodayOnly: false });
              } else {
                startDate = endDate = moment();
                this.setState({ startDate, endDate, showTodayOnly: true });
              }
            }}
            className="nowrap mr2"
          />
          <Checkbox
            id="cash-filter"
            label="Cash Only"
            fontSize="f5"
            checked={this.state.showCashOnly}
            onChange={this.handleToggleCashFilter}
          />

          {!!this.state.orderTotalForRange && (
            <Fragment>
              <div
                className='kpis f5 mr3 ml-auto flex mr2 pa1 dib lh-copy'
              >
                <div className="gray-6 dib mr2">
                  <span>Total ($):{' '}</span>{' '}
                  <span className="green-5 fw6">
                    ${this.state.orderTotalForRange} ({this.state.totalSalesCount} orders)
                  </span>
                </div>

                <div className="gray-6 mr2 gray-6 dib mr1">
                  <span>UPT:{' '}</span>{' '}
                  <span className="blue-5 fw6">
                    {this.state.uptForRange} units
                  </span>
                </div>

                <div className="gray-6 gray-6 dib">
                  <span>AOV:{' '}</span>{' '}
                  <span className="red-5 fw6">
                    ${this.state.aovForRange}
                  </span>
                </div>
              </div>

              <div className="ma2 border-box">
                <Button
                  size="no-size"
                  buttonStyle={ButtonStyles.SECONDARY}
                  className="f5 h3 w4"
                  onClick={() => {
                    this.setState({
                      startDate: null,
                      endDate: null,
                      showTodayOnly: false,
                    });
                  }}
                >
                  X
                </Button>
              </div>
            </Fragment>
          )}
        </div>

        {this.state.showReceiptDialog && (
          <SendReceiptDialog
            onRequestClose={() => this.setState({ showReceiptDialog: false })}
            onSendReceipt={(email, type) => {
              if(isSalesHistoryPage) {
                this.handleSendReceipt(email, type)
              } else {
                this.handleSendReturnReceipt(email)
              }
            }
            }
            type={this.state.showReceiptDialog}
            initialEmail={this.state.receiptEmail}
          />
        )}

        <ItemsTable
          currentPage={this.state.page}
          height={hasPagination ? '566px' : 'auto'}
          items={items}
          onCancel={this.handleCancelOrder}
          isSalesHistoryPage={isSalesHistoryPage}
          onSendReceipt={(item, { type }) =>
            this.setState({
              showReceiptDialog: type,
              receiptEmail: item.customer_email,
              itemId: item.id,
            })
          }
        />

        {hasPagination && (
          <Pagination
            width="full"
            totalCount={totalItems}
            onPageChange={this.handlePageChange}
            limit={this.limit}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoading: getIsLoading(state.activeRequests),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showError: message =>
      dispatch(showNotification(NotificationTypes.ERROR, message)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnsAndSalesHistory);
