import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Icon from 'components/shared/Icon';
import BigSearchField from 'components/shared/BigSearchField';

const CustomerEmailScreen = ({
  handleBackClick,
  headerText,
  subheaderText,
  backgroundColor,
  handleSubmit,
  handleClear,
  isMobile,
}) => {
  return (
    <div className="relative vh-100">
      {handleBackClick && (
        <Icon
          glyph="arrow-left"
          onClick={handleBackClick}
          className="absolute left-2 top-2"
        />
      )}
      <div
        className={`flex flex-column items-center justify-center bg-${backgroundColor} pb7 h-100`}
      >
        <div className="serif white tc lh-title f2 pb3 ph5">{headerText}</div>
        <div className={classNames('white', { pb7: !isMobile, pb4: isMobile })}>
          {subheaderText}
        </div>
        <BigSearchField
          autoFocus
          shouldRenderAppendButtons
          shouldRenderIcons={false}
          appendButtonColor={`${backgroundColor}`}
          className={`bg-${backgroundColor} white white-placeholder`}
          style={isMobile ? { width: '80%' } : { width: '50%' }}
          buttonText="Enter"
          buttonColor="gray-9"
          placeholderText="Email Address"
          onClearClick={handleClear}
          iconColor="white"
          onSubmit={handleSubmit}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { isMobile: state.ui.isMobile };
}

export default connect(mapStateToProps)(CustomerEmailScreen);
