import { createStore, applyMiddleware, compose as reduxCompose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from 'reducers';

const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose;

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunkMiddleware)),
);

export default store;
