import React, { Component, Fragment } from 'react';
import { bool, func, oneOf, shape, string, object } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pubWithSale, Event } from 'lib/events';
import {
  createOrder,
  deleteSaleItem,
  deleteSale,
  fetchOrderPreview,
  fetchSale,
  adjustNumberOfBags,
  changeItemSource,
  removeCustomerFromSale,
  createFreeShippingCoupon,
  setIsCurbsideOnSale,
  cancelRetailDiscountAdjustments,
} from 'actions/asynchronous';
import { showDialog, hideDialog, setChangeIssued } from 'actions/synchronous';
import { Status, getSaleStatus, getSaleByNumber } from 'reducers/sales';
import AddCustomerLink from 'components/shared/AddCustomerLink';
import CustomerBanner from 'components/shared/CustomerBanner';
import SaleSplitShipmentInfo from 'components/sales/SaleSplitShipmentInfo';
import Dialog from 'components/shared/Dialog';
import AddAdjustmentOverrideDialog from 'containers/sales/AddAdjustmentOverrideDialog';
import TopBar from 'components/shared/TopBar';
import SaleItem from 'components/sales/SaleItem';
import Scrollable from 'components/shared/Scrollable';
import Icon from 'components/shared/Icon';
import IconWithLabel from 'components/shared/IconWithLabel';
import FadeAndHighlight from 'components/shared/transitions/FadeAndHighlight';
import CTA from 'components/sales/CTA';
import OrderSummary from 'components/shared/OrderSummary';
import { shouldRefetchOrderPreview } from 'lib/sale';
import { getStorefrontById } from 'reducers/storefronts';
import { formatSaleForReaderDisplay, getTerminal } from 'lib/stripe';

class Sale extends Component {
  static propTypes = {
    sale: shape({
      number: string.isRequired,
      payment_method: object,
    }).isRequired,
    saleStatus: oneOf(Object.keys(Status)).isRequired,
    deleteSale: func.isRequired,
    createOrder: func.isRequired,
    deleteSaleItem: func.isRequired,
    uniqueId: string,
    allowEditing: bool,
    onBack: func,
    canApplyDiscountOverride: bool.isRequired,
    printerIpAddress: string,
    setIsCurbsideOnSale: func.isRequired,
    cancelRetailDiscountAdjustments: func.isRequired,
  };

  static defaultProps = {
    allowEditing: true,
  };

  state = {
    isLoading: false,
    isOrderSummaryExpanded: false,
  };

  terminal = null;
  reader = null;

  async componentDidMount() {
    this.fetchOrderPreview();

    this.terminal = await getTerminal();
    this.reader = this.terminal.getConnectionStatus() === 'connected';
  }

  async componentWillUnmount() {
    const terminal = await getTerminal();
    terminal.clearReaderDisplay();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      shouldRefetchOrderPreview(prevProps.sale, this.props.sale) &&
      this.props.sale.state !== 'completed'
    ) {
      this.fetchOrderPreview();
    }

    if (!this.props.sale.sale_items.length && this.reader) {
      this.terminal?.clearReaderDisplay();
    }

    if (
      (this.props.sale.sale_items.length || prevProps.sale.sale_items.length) &&
      this.props.orderPreview.adjustments.length
    ) {
      if (this.reader) {
        const payload = formatSaleForReaderDisplay({
          saleItems: this.props.sale.sale_items,
          orderPreview: this.props.orderPreview,
        });

        await this.terminal.setReaderDisplay(payload);
      }
    }
  }

  fetchOrderPreview() {
    this.setState({ isLoading: true });
    this.props
      .fetchOrderPreview()
      .then(() => this.setState({ isLoading: false }));
  }

  showErrorDialog = ({ message, subtext, onClose = this.props.hideDialog }) => {
    this.props.showDialog(
      <Dialog
        color="red"
        textColor="white"
        title="An Error Occured"
        onRequestClose={onClose}
      >
        <div className="tc">
          <span className="dib mb3 f3">{message}</span>
          <br />
          <span className="gray-6">{subtext}</span>
        </div>
      </Dialog>,
    );
  };

  handleDeleteSale = () => {
    if (window.confirm('Are you sure you want to cancel this sale?')) {
      pubWithSale(Event.Cart.TRASH_SALE);

      this.props.history.push(
        `/storefronts/${this.props.match.params.storefrontId}/sales`,
      );

      this.props.deleteSale();
    }
  };

  handleDeleteSaleItem = saleItem => {
    pubWithSale(Event.Cart.REMOVE_ITEM_FROM_SALE, {
      product_id: saleItem.product_id,
    });

    this.props.deleteSaleItem(saleItem.id);
  };

  handleStripeTerminalPayment = () => {
    const { storefrontId, saleNumber } = this.props.match.params;
    this.props.history.push(
      `/storefronts/${storefrontId}/sales/${saleNumber}/stripe`,
    );
  };

  handleCreateOrder = async () => {
    const { storefrontId, saleNumber } = this.props.match.params;
    const uniqueId = this.props.sale.shipping_address
      ? this.props.sale.shipping_address.unique_id
      : '';

    const handleError = message => {
      pubWithSale(Event.Cart.CHARGE_SALE_PAYMENT, {
        outcome: 'failure',
      });

      this.showErrorDialog({
        message: message || 'Something went wrong.',
        subtext: 'The order was not completed and the card was not charged.',
        onClose: () => {
          this.props.hideDialog();
          this.props.fetchSale();
        },
      });
    };

    try {
      const { response, responseJson } = await this.props.createOrder({
        uniqueId,
      });

      if (response.ok) {
        pubWithSale(Event.Cart.CHARGE_SALE_PAYMENT, {
          outcome: 'success',
        });

        this.props.history.push(
          `/storefronts/${storefrontId}/signature/success?saleNumber=${saleNumber}&orderId=${responseJson?.order?.id}`,
        );
      } else {
        handleError(responseJson.message);
      }
    } catch (e) {
      handleError();
    }
  };

  handleCTAClick = () => {
    const { storefrontId, saleNumber } = this.props.match.params;

    if (this.shouldPromptForStripeTerminal()) {
      this.handleStripeTerminalPayment();
      return;
    }

    if (
      this.props.saleStatus === Status.READY_FOR_COMPLETION ||
      this.props.saleStatus === Status.IN_PROGRESS_CASH_TRANSACTION
    ) {
      this.handleCreateOrder();

      if (this.props.sale.free_shipping) {
        this.props.createFreeShippingCoupon(this.props.sale.customer.id);
      }
    } else if (this.props.saleStatus === Status.NO_CUSTOMER) {
      this.props.history.push(
        `/storefronts/${storefrontId}/customers/availability?saleNumber=${saleNumber}`,
      );
    } else {
      // let routing figure out which page they need to be on
      if (this.props.isMobile) {
        this.props.history.push(
          `/storefronts/${storefrontId}/sales/${saleNumber}`,
        );
        this.props.onBack();
      } else {
        this.props.history.push(this.props.match.url);
      }
    }
  };

  handleToggleOrderSummary = () => {
    this.setState(prevState => ({
      isOrderSummaryExpanded: !prevState.isOrderSummaryExpanded,
    }));
  };

  handleChangeItemSource = (itemId, warehouseId) => {
    this.props.changeItemSource(itemId, warehouseId).then(() => {
      pubWithSale(Event.Cart.SELECT_SALE_ITEM_SOURCE, {
        warehouse_id: warehouseId,
      });
    });
  };

  handleRemoveCustomerFromSale = () => {
    this.props.history.push(`${this.props.match.url}/product`);
    this.props.removeCustomerFromSale().then(() => {
      pubWithSale(Event.Cart.REMOVE_CUSTOMER_FROM_SALE);
    });
  };

  shouldPromptForStripeTerminal() {
    return (
      this.props.saleStatus === Status.NO_PAYMENT &&
      this.props.location.pathname.includes('payment')
    );
  }

  itemHasBundleApplied = saleItem => {
    return (
      saleItem.bundled_item ||
      saleItem.discountLabels?.some(
        discountLabel => discountLabel.label === 'Discount - Bundle',
      )
    );
  };

  getBundleDiscountDescription = () => {
    const saleItemsInBundles = this.props.sale.sale_items.filter(saleItem =>
      this.itemHasBundleApplied(saleItem),
    );

    const [mensItems, womensItems] = saleItemsInBundles.reduce(
      ([mensItems, womensItems], saleItem) => {
        if (saleItem.sku.startsWith('M')) {
          return [[...mensItems, saleItem], womensItems];
        } else if (saleItem.sku.startsWith('F')) {
          return [mensItems, [...womensItems, saleItem]];
        } else {
          return [mensItems, womensItems];
        }
      },
      [[], []],
    );

    let mensDescription = '';
    if (mensItems.length) {
      const totalDiscount = mensItems.reduce(
        (total, item) => total + Number(item.price.replace('$', '')),
        0,
      );

      mensDescription = `Men's Bundle Applied (${mensItems.length} for $${totalDiscount})`;
    }

    let womensDescription = '';
    if (womensItems.length) {
      const totalDiscount = womensItems.reduce(
        (total, item) => total + Number(item.price.replace('$', '')),
        0,
      );

      womensDescription = `Women's Bundle Applied (${womensItems.length} for $${totalDiscount})`;
    }

    return (
      <Fragment>
        {mensDescription}
        {mensDescription && womensDescription && <br />}
        {womensDescription}
      </Fragment>
    );
  };

  renderSaleItemSection(saleItems, header) {
    const firstSaleItem = saleItems[0];

    return (
      <div className="bg-white">
        {saleItems.length > 0 && (
          <div className="ph1">
            <span className="bb b--gray-9 db h4 tl f5 fw5 lh-double">
              {header}
            </span>
          </div>
        )}
        {/*
          Only renders the shipping info if there is a shipping address and
          at least one item that is shipped from "Online"
        */}
        {this.props.sale.shipping_address &&
          firstSaleItem &&
          firstSaleItem.source === 'Online' && (
            <SaleSplitShipmentInfo
              fulfillmentMethodName={firstSaleItem.fulfillment_method_name}
              shippingAddress={this.props.sale.shipping_address}
              allowEditing={this.props.allowEditing}
            />
          )}
        <FadeAndHighlight>
          {saleItems.map((saleItem, index) => (
            <SaleItem
              key={saleItem.id}
              id={saleItem.id}
              title={saleItem.title}
              price={saleItem.price}
              imageUrl={saleItem.image_url}
              size={saleItem.size}
              source={saleItem.source}
              upc={saleItem.upc}
              warehouseId={saleItem.warehouse_id}
              restockDate={saleItem.variant_restock_date}
              orderableState={saleItem.variant_orderable_state}
              availableSources={saleItem.available_sources}
              originalPrice={saleItem.original_price}
              allowEditing={this.props.allowEditing}
              isLastItem={index === saleItems.length - 1}
              isFinalSale={saleItem.final_sale}
              onChangeSource={this.handleChangeItemSource}
              onDelete={() => this.handleDeleteSaleItem(saleItem)}
            />
          ))}
        </FadeAndHighlight>
      </div>
    );
  }

  renderHangTagIconForPromoDialog = () => {
    return this.props.canApplyDiscountOverride ? (
      <Icon
        glyph="hangtag"
        style={this.props.isMobile ? { fontSize: '1.2em', marginRight: '8px' } : {}}
        onClick={() => {
          this.props.showDialog(
            <AddAdjustmentOverrideDialog
              isMobile={this.props.isMobile}
              onRequestClose={this.props.hideDialog}
              orderTotal={this.props.orderPreview.total}
              saleNumber={this.props.sale.number}
            />
          )
        }}
      />
    ) : null
  }

  renderCTA = () => {
    return (
      <CTA
        rounded={false}
        isLoading={this.state.isLoading}
        location={this.props.location}
        sale={this.props.sale}
        saleStatus={this.props.saleStatus}
        orderTotal={this.props.orderPreview.total}
        shouldPromptForStripeTerminal={this.shouldPromptForStripeTerminal()}
        onClick={this.handleCTAClick}
        onIssueChange={this.props.setChangeIssued}
        isMobile={this.props.isMobile}
        printerIpAddress={this.props.printerIpAddress}
      />
    );
  };

  renderOrderSummary() {
    if (
      !this.props.isMobile ||
      (this.props.isMobile && this.state.isOrderSummaryExpanded)
    ) {
      return (
        <OrderSummary
          {...this.props.orderPreview}
          toggleOrderSummary={
            this.props.isMobile && this.handleToggleOrderSummary
          }
          numberOfBags={this.props.sale.bag_count}
          allowEditing={this.props.allowEditing}
          onAdjustNumberOfBags={this.props.adjustNumberOfBags}
          onSetIsCurbsideOnSale={this.props.setIsCurbsideOnSale}
          isCurbside={this.props.sale.is_curbside}
          ctaButton={this.props.allowEditing && this.renderCTA()}
          showCancelRetailDiscountsButton={true}
          cancelRetailDiscountAdjustments={this.props.cancelRetailDiscountAdjustments}
        />
      );
    }

    // collapsed order summary on mobile
    return (
      <div className="absolute bottom-0 left-0 w-100 bg-white gray-5">
        <div
          onClick={this.handleToggleOrderSummary}
          className="w-100 h3 f6 lh-solid bt b--gray-9 flex items-center ph2 relative bg-gray-9 gray-2"
        >
          <span>Expand Order Summary</span>
          <Icon
            glyph="arrow-right"
            className="absolute top-0 bottom-0 right-0"
            color="gray-2"
            style={{ transform: 'rotate(270deg)', width: '35px' }}
          />
        </div>
        <div className="w-100 h4 f4 fw5 lh-solid bt b--gray-9 flex items-center justify-between ph2">
          <span>Total:</span>
          <span>{this.props.orderPreview.total}</span>
        </div>
        {this.renderCTA()}
      </div>
    );
  }

  render() {
    const onlineItems = this.props.sale.sale_items.filter(
      saleItem => saleItem.source === 'Online',
    );

    const inStoreItems = this.props.sale.sale_items.filter(
      saleItem => saleItem.source !== 'Online',
    );

    const saleHasBundleApplied = this.props.sale.sale_items.some(saleItem =>
      this.itemHasBundleApplied(saleItem),
    );

    const saleReward = this.props.orderPreview?.rewards?.find(reward => reward.eligible_for_reward)
    const saleHasRewardApplied = saleReward && saleReward.reward_object

    let bundleDiscountDescription = this.getBundleDiscountDescription();

    return (
      <div
        className="relative w-100 tc"
        style={{ height: document.documentElement.clientHeight }} // 🙈
      >
        <TopBar
          color="blue-4"
          textColor="white"
          center={
            <span>Cart&nbsp;&nbsp;({this.props.sale.sale_items.length})</span>
          }
          right={
            <Fragment>
              {this.props.isMobile && (
                this.renderHangTagIconForPromoDialog()
              )}
              {this.props.allowEditing && (
                <Icon
                  glyph="trash-can"
                  onClick={this.handleDeleteSale}
                  style={this.props.isMobile ? { fontSize: '1.2em' } : {}}
                />
              )}
            </Fragment>
          }
          left={
            this.props.isMobile ? (
              <IconWithLabel
                icon="arrow-left"
                iconClassName="f5 pr1"
                label="Back"
                onClick={this.props.onBack}
              />
            ) : (
              this.renderHangTagIconForPromoDialog()
            )
          }
        />
        {this.props.sale.customer ? (
          <CustomerBanner
            saleNumber={this.props.sale.number}
            customer={this.props.sale.customer}
            onRemoveCustomerClick={this.handleRemoveCustomerFromSale}
            allowEditing={this.props.allowEditing}
          />
        ) : (
          <AddCustomerLink
            storefrontId={this.props.match.params.storefrontId}
            saleNumber={this.props.sale.number}
            onClick={() =>
              pubWithSale(Event.Cart.ADD_CUSTOMER_TO_SALE, {
                location: 'secondary CTA',
              })
            }
          />
        )}
         {saleHasRewardApplied && (
            <div className="bg-red-5 white pv1 ph2 f5 bb b--gray-9 flex items-center">
            <Icon glyph="gift" className="f5 pr1" color="white" />{' '}
            Reward Received! {saleReward.reward_object.display_name}
          </div>
          )}
        {saleHasBundleApplied && (
          <div className="green pv1 ph2 f5 bb b--gray-9 flex items-center">
            <Icon glyph="checkmark" className="f6 pr1" color="green" />{' '}
            {bundleDiscountDescription}
          </div>
        )}
        <Scrollable>
          {inStoreItems.length > 0 &&
            this.renderSaleItemSection(inStoreItems, 'Items from In-Store')}
          {onlineItems.length > 0 &&
            this.renderSaleItemSection(onlineItems, 'Items to Ship')}
        </Scrollable>
        {this.renderOrderSummary()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params;

  return {
    orderPreview: state.orderPreview,
    sale: getSaleByNumber(state.sales, saleNumber),
    saleStatus: getSaleStatus(
      state.sales,
      saleNumber,
      ownProps.location.pathname,
      state.orderPreview.total,
    ),
    uniqueId: state.uniqueId || null,
    staticContext: null,
    isMobile: state.ui.isMobile,
    canApplyDiscountOverride: !!state.associate.roles.includes('retail_config'),
    printerIpAddress: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ).printer_ip_addresses,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { saleNumber } = ownProps.match.params;

  return {
    deleteSale: () => dispatch(deleteSale(saleNumber)),
    createOrder: params => dispatch(createOrder(saleNumber, params)),
    deleteSaleItem: id => dispatch(deleteSaleItem(saleNumber, id)),
    changeItemSource: (itemId, warehouseId) =>
      dispatch(changeItemSource(saleNumber, itemId, warehouseId)),
    fetchOrderPreview: () => dispatch(fetchOrderPreview(saleNumber)),
    fetchSale: () => dispatch(fetchSale(saleNumber)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    removeCustomerFromSale: () => dispatch(removeCustomerFromSale(saleNumber)),
    adjustNumberOfBags: newNumberOfBags =>
      dispatch(adjustNumberOfBags(saleNumber, newNumberOfBags)),
    cancelRetailDiscountAdjustments: () => {
      dispatch(cancelRetailDiscountAdjustments(saleNumber)).then(() => {
        dispatch(fetchOrderPreview(saleNumber))
      }
    )},
    createFreeShippingCoupon: customerId =>
      dispatch(createFreeShippingCoupon(saleNumber, customerId)),
    setChangeIssued: () => dispatch(setChangeIssued(saleNumber)),
    setIsCurbsideOnSale: isCurbside =>
      dispatch(setIsCurbsideOnSale(saleNumber, isCurbside)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sale));
