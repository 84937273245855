import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { pubWithSale, Event } from 'lib/events';
import { getSaleByNumber } from 'reducers/sales';
import { getStorefrontById } from 'reducers/storefronts';
import IconWithLabel from 'components/shared/IconWithLabel';
import TabBar from 'components/shared/TabBar';
import TopBar from 'components/shared/TopBar';
import Product from 'components/sales/Product';
import Scrollable from 'components/shared/Scrollable';
import ItemSummary from 'components/sales/mobile/ItemSummary';

function OnlineCart({ isMobile, storefront, sale, onContinueMobile }) {
  const onlineCartItemCount = sale.customer?.cart?.line_items.length;
  const [isMobileSummaryExpanded, setIsMobileSummaryExpanded] = useState(false);

  useEffect(() => {
    pubWithSale(Event.OnlineCart.PAGE_VIEW);
  }, [sale]);

  return (
    <Fragment>
      <TopBar
        color="blue-4"
        textColor="white"
        center="Online Cart"
        left={
          <Link
            to={`/storefronts/${storefront.id}/sales/${sale.number}`}
            className="no-underline flex"
          >
            <IconWithLabel
              color="white"
              icon="arrow-left"
              iconClassName="f5 pr1"
              label="Sale"
            />
          </Link>
        }
      />
      <TabBar
        borderColor="blue-1"
        tabs={[
          {
            title: 'Search for Items',
            to: `/storefronts/${storefront.id}/sales/${sale.number}/product`,
            notificationCount: 0,
            showNotificationCount: false,
          },
          {
            title: 'Online Cart',
            to: `/storefronts/${storefront.id}/sales/${sale.number}/online-cart`,
            notificationCount: onlineCartItemCount || 0,
            showNotificationCount: !!onlineCartItemCount,
          },
        ]}
      />
      <div className={classNames('pv1 pl1', { pr1: isMobile, pr3: !isMobile })}>
        {!onlineCartItemCount && (
          <div>
            This customer does not have anything in their online shopping cart.
          </div>
        )}

        <Scrollable className="flex flex-wrap w-100">
          {sale.customer?.cart?.line_items.map((lineItem, index) => {
            const variant = lineItem.product.variants.find(
              variant => variant.variant_id === lineItem.variant_id,
            );

            return (
              <Product
                isMobile={isMobile}
                width="partial"
                key={lineItem.id}
                storefront={storefront}
                id={lineItem.product.id}
                saleNumber={sale.number}
                imageUrl={lineItem.product.flat_or_primary_image_url}
                name={lineItem.product.name}
                category={lineItem.product.category}
                price={lineItem.product.price}
                variants={lineItem.product.variants}
                ctaText="Add To Sale"
                initialSelectedVariantId={variant?.variant_id}
                sale={sale}
                product={lineItem.product}
              />
            );
          })}
        </Scrollable>
      </div>

      {isMobile && sale && (
        <ItemSummary
          sale={sale}
          onContinue={onContinueMobile}
          toggleMobileSummary={() =>
            setIsMobileSummaryExpanded(!isMobileSummaryExpanded)
          }
          isExpanded={isMobileSummaryExpanded}
        />
      )}
    </Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params;
  return {
    storefront: getStorefrontById(
      state.storefronts,
      ownProps.match.params.storefrontId,
    ),
    sale: getSaleByNumber(state.sales, saleNumber),
    isMobile: state.ui.isMobile,
  };
}

export default connect(mapStateToProps)(OnlineCart);
