const INITIAL_UI_STATE = {
  isSidebarVisible: false,
  currentDialog: null,
  isMobile: null,
};

export default function ui(state = INITIAL_UI_STATE, action) {
  switch (action.type) {
    case 'SHOW_SIDEBAR': {
      return { ...state, isSidebarVisible: true };
    }

    case 'HIDE_SIDEBAR': {
      return { ...state, isSidebarVisible: false };
    }

    case 'SHOW_DIALOG': {
      return { ...state, currentDialog: action.payload };
    }

    case 'HIDE_DIALOG': {
      return { ...state, currentDialog: null };
    }

    case 'DETECT_MOBILE': {
      return { ...state, isMobile: action.payload };
    }

    default:
      return state;
  }
}
