import React, { Component } from 'react';
import classNames from 'classnames';
import Icon from 'components/shared/Icon';
// Credit card icons via: https://github.com/muffinresearch/payment-icons/tree/master/svg/flat
import visa from 'svg/visa.svg';
import americanexpress from 'svg/amex.svg';
import discover from 'svg/discover.svg';
import mastercard from 'svg/mastercard.svg';

export default class CreditCard extends Component {
  constructor(props) {
    super(props);

    this.buttonRemove = React.createRef();
  }

  handleClick = event => {
    if (event.target === this.buttonRemove || this.props.onClick == null) {
      return;
    }
    this.props.onClick(event);
  };

  render() {
    const computedClassName = classNames(
      this.props.className,
      'relative ba ma1 pa1 h14 flex items-center',
      {
        [`b--${this.props.selectedBorderColor} bw1 bg-gold-9`]:
          this.props.isActive,
        'b--gray-6': !this.props.isActive,
      },
    );

    const logo = {
      Visa: <img src={visa} alt="Visa" className="mr1 w4" />,
      MasterCard: <img src={mastercard} alt="MasterCard" className="mr1 w4" />,
      'American Express': (
        <img src={americanexpress} alt="AmericanExpress" className="mr1 w4" />
      ),
      Discover: <img src={discover} alt="AmericanExpress" className="mr1 w4" />,
    }[this.props.brand];

    return (
      <div
        className={computedClassName}
        style={{
          width: '13rem',
          padding: this.props.isActive && '0 0.4rem', // border width when active is wide enough to cause overflow
        }}
        onClick={this.handleClick}
      >
        <div>
          {this.props.isRemovable && (
            <Icon
              innerRef={this.buttonRemove}
              glyph="x"
              className="absolute f5 pointer"
              style={{ top: '0.5rem', right: '0.3rem' }}
              color="gray-6"
              onClick={this.props.onRemove}
            />
          )}
          <div className="fw5 mb1">{this.props.fullName}</div>
          <div className="flex items-center mb1">
            {logo} •••• •••• •••• {this.props.lastDigits}
          </div>
          <div className="f5 fw3 gray-6">{this.props.expirationDate}</div>
        </div>
      </div>
    );
  }
}
