import { getAssociateAuthToken } from 'lib/tokens';
import logOut, { softLogOut } from 'lib/session';
import { objectToQueryParams } from 'lib/url';

const AUTHENTICATION_HEADER = 'X-E-Token';

const ERROR_TYPES_THAT_INVALIDATE_ASSOCIATE = [
  'RetailUser::Authenticate::DisabledRetailUser',
  'RetailUser::Authenticate::InvalidRetailUserToken',
  'RetailUser::Authenticate::NoUnlockPermission',
];

const UNAUTHORIZED_MESSAGE =
  'That action was not completed. Please sign back in and try again.';

function handleUnauthorizedResponse(responseType) {
  if (responseType === 'UNAUTHORIZED') {
    softLogOut();
  } else if (ERROR_TYPES_THAT_INVALIDATE_ASSOCIATE.includes(responseType)) {
    logOut();
  }
}

export async function post(url, data) {
  const request = new Request(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: {
      [AUTHENTICATION_HEADER]: getAssociateAuthToken(),
      'Content-Type': 'application/json',
    },
  });

  const response = await fetch(request);
  const contentTypeHeader = response.headers.get('Content-Type');
  let responseJson = {};

  if (contentTypeHeader && contentTypeHeader.includes('application/json')) {
    responseJson = await response.json();
  }

  if (response.status === 401) {
    handleUnauthorizedResponse(responseJson.type || responseJson.code);
    responseJson.message = UNAUTHORIZED_MESSAGE;
  }

  return { response, responseJson };
}

export async function patch(url, data) {
  const request = new Request(url, {
    method: 'PATCH',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: {
      [AUTHENTICATION_HEADER]: getAssociateAuthToken(),
      'Content-Type': 'application/json',
    },
  });

  const response = await fetch(request);
  let responseJson = {};

  if (response.headers.get('Content-Type').includes('application/json')) {
    responseJson = await response.json();
  }

  if (response.status === 401) {
    handleUnauthorizedResponse(responseJson.type || responseJson.code);
    responseJson.message = UNAUTHORIZED_MESSAGE;
  }

  return { response, responseJson };
}

export async function httpDelete(url) {
  const request = new Request(url, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      [AUTHENTICATION_HEADER]: getAssociateAuthToken(),
      'Content-Type': 'application/json',
    },
  });

  const response = await fetch(request);
  let responseJson = {};

  if (response.headers.get('Content-Type').includes('application/json')) {
    responseJson = await response.json();
  }

  if (response.status === 401) {
    handleUnauthorizedResponse(responseJson.type || responseJson.code);
    responseJson.message = UNAUTHORIZED_MESSAGE;
  }

  return { response, responseJson };
}

export async function get(url, params) {
  const fullUrl = params ? url + objectToQueryParams(params) : url;

  const request = new Request(fullUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      [AUTHENTICATION_HEADER]: getAssociateAuthToken(),
      'Content-Type': 'application/json',
    },
  });

  const response = await fetch(request);
  let responseJson = {};

  if (response.headers.get('Content-Type').includes('application/json')) {
    responseJson = await response.json();
  }

  if (response.status === 401) {
    handleUnauthorizedResponse(responseJson.type || responseJson.code);
    responseJson.message = UNAUTHORIZED_MESSAGE;
  }

  return { response, responseJson };
}
