import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import ProductButtons from 'components/product-search/ProductButtons';
import VariantSelectionBox from 'components/sales/VariantSelectionBox';
import InventoryCountBubble from 'components/sales/InventoryCountBubble';
import { dedupeMultipleUpcVariants } from 'lib/multiUpc';

const ProductInventory = ({
  inventory,
  storefront,
  sale,
  isMobile,
  isUPCSearch,
  matchingVariant,
  product,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [hasMatchingVariantBeenAdded, addMatchingVariant] = useState(false);

  useEffect(() => {
    addMatchingVariant(false);
    setSelectedVariant(matchingVariant);
  }, [matchingVariant]);

  // Autoselects the variant if there is only one, e.g. totes with "ONE SIZE"
  useEffect(() => {
    if (inventory.variants?.length === 1) {
      setSelectedVariant(inventory.variants[0]);
    }
  }, [inventory.variants]);

  const handleClickVariant = variant => {
    if (variant !== selectedVariant) {
      setSelectedVariant(variant);
    } else {
      setSelectedVariant(null);
    }
  };

  if (!inventory.variants) {
    return null;
  }

  const variants = dedupeMultipleUpcVariants(inventory.variants)

  return (
    <div
      className={classNames('flex justify-between', {
        'flex-column': isMobile,
      })}
    >
      <div
        className={classNames('flex flex-wrap', {
          'w-70': !isMobile && !isUPCSearch,
          'w-60': isUPCSearch && !isMobile,
          'mh2 mb2': isMobile,
        })}
      >
        {variants.map((variant, index) => (
          <VariantSelectionBox
            isMobile={isMobile}
            key={index}
            index={index}
            variant={variant}
            storefront={storefront}
            isActive={selectedVariant && variant.upc === selectedVariant.upc}
            onClick={() => handleClickVariant(variant)}
          />
        ))}
      </div>
      {isMobile && selectedVariant && (
        <InventoryCountBubble
          isMobile
          variant={selectedVariant}
          storefront={storefront}
        />
      )}
      <div
        className={classNames('flex flex-column', {
          mr4: !isMobile && !isUPCSearch,
          mh2: isMobile,
        })}
      >
        <ProductButtons
          variant={selectedVariant}
          product={product}
          storefront={storefront}
          sale={sale}
          shouldAddToSale={
            sale &&
            isUPCSearch &&
            selectedVariant &&
            !hasMatchingVariantBeenAdded
          }
          onAddMatchingVariant={addMatchingVariant}
        />
      </div>
    </div>
  );
};

export default ProductInventory;
