import React from 'react';
import TinyPill from 'components/shared/TinyPill';

export default function RestockRequestItem({
  title,
  color,
  imageUrl,
  size,
  sku,
  quantityOnHand,
  restockCount,
}) {
  return (
    <div className="pv2 flex items-center">
      <img
        alt={title}
        src={imageUrl}
        style={{ height: 140, width: 140 }}
        className="pr3"
      />
      <div>
        {title}
        <br />
        <span className="gray-5">SKU:</span> {sku}
        <br />
        <span className="gray-5">Color:</span> {color}
        <br />
        <span className="gray-5">Size:</span> {size}
        <br />
        <span className="gray-5">Quantity On Hand:</span> {quantityOnHand}
        <br />
        {restockCount && (
          <div className="pt1">
            <TinyPill textColor="white" color="gray-2">
              Restock Quantity: {restockCount}
            </TinyPill>
          </div>
        )}
      </div>
    </div>
  );
}
