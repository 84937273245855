import React, { useRef } from 'react';
import classNames from 'classnames';
import { useCurrentBreakpointName } from 'react-socks';

export default function TopBar({
  left,
  center,
  right,
  className,
  color = 'gray-8',
  textColor = 'black',
}) {
  const breakpointName = useCurrentBreakpointName();
  const isMobile = breakpointName.includes('phone');
  const leftEl = useRef();
  const rightEl = useRef();

  const fontSize = isMobile ? 'f5' : 'f4';
  const newClassName = classNames(
    'flex items-center justify-between pv2 h9',
    className,
    textColor,
    fontSize,
    {
      [`bg-${color}`]: color,
    },
  );

  return (
    <div className={newClassName}>
      <div
        ref={leftEl}
        style={{ flex: 1 }}
        className={classNames('tl', {
          ml1: isMobile,
          ml3: !isMobile,
        })}
      >
        {left}
      </div>
      <div className="tc truncate">{center}</div>
      <div
        ref={rightEl}
        style={{ flex: 1 }}
        className={classNames('tr', {
          mr1: isMobile,
          mr3: !isMobile,
        })}
      >
        {right}
      </div>
    </div>
  );
}
