import React, { Component } from 'react';
import { number, object, string, any, oneOfType } from 'prop-types';
import classNames from 'classnames';

// Create a scrollable section of content that fills up all available vertical height.
export default class Scrollable extends Component {
  static propTypes = {
    height: number,
    Component: oneOfType([object, string]),
    className: string,
    children: any,
  };

  static defaultProps = {
    Component: 'div',
  };

  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  componentDidMount() {
    // The height of this component is determined by the dom nodes surrounding it, as well as its
    // own dom node. React doesn't know the dimensions or placement of its own dom node until it has
    // rendered for the first time.

    // Here, we force ourselves to re-render once we finish the first rendering cycle.
    // This second update will result in the height being computed correctly, since we now own a dom
    // node to inspect.
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    // Similarly to the issue described in `componentDidMount`, we need to recalculate our height
    // whenever we get passed new props.

    if (prevProps !== this.props) {
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    // Note that componentDidUpdate will likely be called before a page transition as state
    // gets updated around the page to prepare for navigation. We will unmount on navigation, but
    // remember! We have a timeout set to forceUpdate ourselves in 10 miliseconds. By clearing this
    // timeout before unmounting, we avoid a loud React warning about trying to `forceUpdate` an
    // unmounted component.

    if (this.timeout) {
      clearTimeout(this.timeOut);
    }
  }

  getComputedHeight() {
    if (!this.element.current) {
      return 0;
    }

    const allSiblings = Array.from(this.element.current.parentElement.children);

    const siblingsAfterMe = allSiblings.slice(
      allSiblings.indexOf(this.element.current) + 1,
    );

    const heightOfSiblingsAfterMe = siblingsAfterMe.reduce(
      (totalHeight, child) => totalHeight + child.offsetHeight,
      0,
    );

    const distanceFromTopOfScreen = this.element.current.getBoundingClientRect()
      .top;
    return (
      document.documentElement.clientHeight -
      distanceFromTopOfScreen -
      heightOfSiblingsAfterMe
    );
  }

  render() {
    return (
      <this.props.Component
        ref={this.element}
        style={{ height: this.props.height || this.getComputedHeight() }}
        className={classNames(
          this.props.className,
          'overflow-y-scroll touch-momentum-scroll',
        )}
      >
        {this.props.children}
      </this.props.Component>
    );
  }
}
