import React, { Component, Fragment } from 'react';
import { shape, string, func, oneOf } from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { pubWithSale, Event } from 'lib/events';
import { patch } from 'lib/network';
import { retailApiUrl } from 'lib/url';
import { NotificationTypes } from 'reducers/notifications';
import { getSaleByNumber, getSaleStatus, Status } from 'reducers/sales';
import { getStorefrontById } from 'reducers/storefronts';
import { createNewStorefrontTicket, fetchSale } from 'actions/asynchronous';
import { showDialog, hideDialog, showNotification } from 'actions/synchronous';
import SplitScreen from 'components/shared/SplitScreen';
import AddProductToSale from 'containers/sales/AddProductToSale';
import AddPaymentToSale from 'containers/sales/AddPaymentToSale';
import AddAddressToSale from 'containers/sales/AddAddressToSale';
import AddAddressToSaleMobile from 'containers/sales/mobile/AddAddressToSale';
import CreateAddressForSale from 'containers/sales/CreateAddressForSale';
import CreateAddressForSaleMobile from 'containers/sales/mobile/CreateAddressForSale';
import AddShippingOptionToSale from 'containers/sales/AddShippingOptionToSale';
import AddShippingOptionToSaleMobile from 'containers/sales/mobile/AddShippingOptionToSale';
import Sale from 'containers/sales/Sale';
import ShippingConfirmation from 'containers/sales/ShippingConfirmation';
import StorefrontTicketCTA from 'components/shared/StorefrontTicketCTA';
import StorefrontTicketDialog from 'components/shared/StorefrontTicketDialog';
import CaptureStripeTerminalPayment from 'containers/sales/CaptureStripeTerminalPayment';
import OnlineCart from 'containers/sales/OnlineCart';
import StripeConnectionIndicator from 'components/shared/StripeConnectionIndicator';
import StripeReaderListDialog from 'components/shared/StripeReaderListDialog';

class SaleInterface extends Component {
  static propTypes = {
    fetchSale: func.isRequired,
    sale: shape({
      number: string,
    }),
    saleStatus: oneOf(Object.keys(Status)),
    match: shape({
      path: string,
    }).isRequired,
    storefrontName: string.isRequired,
    stripeLocationId: string,
  };

  state = {
    isViewingSummary: false,
    isMakingPayment: false,
  };

  async componentDidMount() {
    const { saleNumber, storefrontId } = this.props.match.params;

    if (!this.props.sale) {
      this.props.fetchSale();
    } else {
      if (this.props.sale.state === 'completed') {
        return this.props.history.push(
          `/storefronts/${storefrontId}/signature/success?saleNumber=${saleNumber}`,
        );
      }

      pubWithSale(Event.Sale.PAGE_VIEW);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.sale && !prevProps.sale) {
      patch(retailApiUrl(`sales/${this.props.match.params.saleNumber}`), {
        paused: false,
      });

      pubWithSale(Event.Sale.PAGE_VIEW);
    }
  }

  determinePathBasedOnStatus() {
    const mostAppropriateAddressPage =
      this.props.sale &&
      this.props.sale.customer &&
      this.props.sale.customer.addresses.length > 0
        ? `${this.props.match.url}/address/add`
        : `${this.props.match.url}/address/new`;

    return {
      [Status.NO_ITEMS]: `${this.props.match.url}/product`,
      [Status.NO_CUSTOMER]: `${this.props.match.url}/product`,
      [Status.NO_ADDRESS]: mostAppropriateAddressPage,
      [Status.NO_SHIPPING_OPTION]: `${this.props.match.url}/shipping`,
      [Status.NO_PAYMENT]: `${this.props.match.url}/payment`,
      [Status.READY_FOR_COMPLETION]: `${this.props.match.url}/payment`,
    }[this.props.saleStatus];
  }

  handleViewSummary = () => {
    this.setState({ isViewingSummary: true });
  };

  handleUnviewSummary = () => {
    this.setState({ isViewingSummary: false });
  };

  handlePromptReader = () => {
    this.setState({ isMakingPayment: true });
  };

  handleCompletePayment = () => {
    this.setState({ isMakingPayment: false });
  };

  renderMainPane() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}/product`}
          render={routeProps => {
            if (!this.props.isMobile) {
              return <AddProductToSale {...routeProps} />;
            }

            // for mobile
            return this.state.isViewingSummary ? (
              <Sale {...routeProps} onBack={this.handleUnviewSummary} />
            ) : (
              <AddProductToSale
                {...routeProps}
                onContinueMobile={this.handleViewSummary}
              />
            );
          }}
        />
        <Route
          path={`${this.props.match.path}/payment`}
          render={routeProps => {
            if (!this.props.isMobile) {
              return <AddPaymentToSale {...routeProps} />;
            }

            // for mobile
            return this.state.isViewingSummary ? (
              <Sale
                {...routeProps}
                onBack={this.handleUnviewSummary}
                isMakingPayment={this.state.isMakingPayment}
              />
            ) : (
              <AddPaymentToSale
                {...routeProps}
                onContinue={this.handleViewSummary}
              />
            );
          }}
        />
        <Route
          path={`${this.props.match.path}/address/add`}
          component={
            this.props.isMobile ? AddAddressToSaleMobile : AddAddressToSale
          }
        />

        <Route
          path={`${this.props.match.path}/address/new`}
          component={
            this.props.isMobile
              ? CreateAddressForSaleMobile
              : CreateAddressForSale
          }
        />

        <Route
          path={`${this.props.match.path}/address/edit`}
          component={CreateAddressForSale}
        />

        <Route
          path={`${this.props.match.path}/shipping`}
          component={
            this.props.isMobile
              ? AddShippingOptionToSaleMobile
              : AddShippingOptionToSale
          }
        />

        <Route
          path={`${this.props.match.path}/shipping-complete`}
          component={ShippingConfirmation}
        />

        <Route
          path={`${this.props.match.path}/stripe`}
          component={CaptureStripeTerminalPayment}
        />

        <Route
          path={`${this.props.match.path}/online-cart`}
          render={routeProps => {
            return this.state.isViewingSummary ? (
              <Sale {...routeProps} onBack={this.handleUnviewSummary} />
            ) : (
              <OnlineCart
                {...routeProps}
                onContinueMobile={this.handleViewSummary}
              />
            );
          }}
        />

        <Redirect to={this.determinePathBasedOnStatus()} />
      </Switch>
    );
  }

  renderSidePane() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}/stripe`}
          render={props => <Sale {...props} allowEditing={false} />}
        />

        <Route component={Sale} />
      </Switch>
    );
  }

  renderStorefrontTicketCTA() {
    if (this.props.isMobile) {
      return null;
    }

    return (
      <StorefrontTicketCTA
        onClick={() => {
          this.props.showDialog(
            <StorefrontTicketDialog
              onRequestClose={this.props.hideDialog}
              storefrontName={this.props.storefrontName}
              initialCustomerEmail={
                this.props.sale.customer && this.props.sale.customer.email
              }
              initialCustomerFullName={
                this.props.sale.customer && this.props.sale.customer.full_name
              }
              initialSaleNumber={this.props.sale.number}
              onFormSubmit={formData =>
                this.props.createNewStorefrontTicket(formData)
              }
            />,
          );
        }}
      />
    );
  }

  renderStripeConnectionIndicator() {
    return (
      <StripeConnectionIndicator
        onClick={() =>
          this.props.showDialog(
            <StripeReaderListDialog
              onRequestClose={this.props.hideDialog}
              stripeLocationId={this.props.stripeLocationId}
            />,
          )
        }
      />
    );
  }

  render() {
    if (!this.props.sale) {
      return null;
    }

    const height = document.documentElement.clientHeight;
    const content = this.props.isMobile ? (
      <div className="relative w-100" style={{ height }}>
        {this.renderMainPane()}
      </div>
    ) : (
      <SplitScreen
        mainPane={this.renderMainPane()}
        sidePane={this.renderSidePane()}
      />
    );

    return (
      <Fragment>
        {this.renderStorefrontTicketCTA()}
        {this.renderStripeConnectionIndicator()}

        {this.props.location.pathname.includes('address') ||
        this.props.location.pathname.includes('shipping')
          ? this.renderMainPane()
          : content}
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const storefront = getStorefrontById(
    state.storefronts,
    ownProps.match.params.storefrontId,
  );

  return {
    sale: getSaleByNumber(state.sales, ownProps.match.params.saleNumber),
    saleStatus: getSaleStatus(
      state.sales,
      ownProps.match.params.saleNumber,
      ownProps.location.pathname,
      state.orderPreview.total,
    ),
    storefrontName: storefront.name,
    stripeLocationId: storefront.stripe_location_id,
    isMobile: state.ui.isMobile,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { saleNumber } = ownProps.match.params;
  return {
    fetchSale: () => dispatch(fetchSale(saleNumber)),
    createNewStorefrontTicket: data =>
      dispatch(
        createNewStorefrontTicket(ownProps.match.params.storefrontId, data),
      ),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    showErrorNotification: message =>
      dispatch(showNotification(NotificationTypes.ERROR, message)),
    showInfoNotifcation: message =>
      dispatch(showNotification(NotificationTypes.INFO, message)),
    showWarningNotification: message =>
      dispatch(showNotification(NotificationTypes.WARNING, message)),
    showSuccessNotification: message =>
      dispatch(showNotification(NotificationTypes.SUCCESS, message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleInterface);
