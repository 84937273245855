import React, { Component } from 'react';
import { pub, Event } from 'lib/events';
import Header from 'containers/Header';
import NoItemsMessage from 'components/shared/NoItemsMessage';
import SubHeader from 'components/shared/SubHeader';
import SalesTable from 'components/sales/SalesTable';
import { getIncompleteSales } from 'reducers/sales';
import { connect } from 'react-redux';
import { getIsLoading } from 'reducers/activeRequests';
import { fetchSales, startNewSale, deleteSale } from 'actions/asynchronous';

class IncompleteSales extends Component {
  componentDidMount() {
    this.props.fetchSales();
    pub(Event.SalesHome.PAGE_VIEW);

    this.pollingInterval = setInterval(this.props.fetchSales, 30000);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.storefrontId !==
      this.props.match.params.storefrontId
    ) {
      this.props.fetchSales();
      clearInterval(this.pollingInterval);
      this.pollingInterval = setInterval(this.props.fetchSales, 30000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  handleDeleteSale = saleNumber => {
    pub(Event.SalesHome.TRASH_SALE);
    if (window.confirm('Are you sure you want to cancel this sale?')) {
      this.props.deleteSale(saleNumber);
    }
  };

  handleStartNewSale = () => {
    pub(Event.SalesHome.START_SALE);

    this.props.startNewSale().then(response => {
      if (response?.sale) {
        this.props.history.push(
          `${this.props.match.url}/${response.sale.number}/product`,
        );
      }
    });
  };

  render() {
    return (
      <div>
        <Header
          ctaText={this.props.isLoading ? 'Loading...' : 'Start New Sale'}
          onCtaClick={this.handleStartNewSale}
          ctaDisabled={this.props.isLoading}
          textColor="white"
        />
        <SubHeader>Incomplete Sales</SubHeader>

        {this.props.sales.length === 0 ? (
          <NoItemsMessage>
            There are no incomplete sales. You can start a new sale above.
          </NoItemsMessage>
        ) : (
          <SalesTable
            storefrontId={this.props.match.params.storefrontId}
            sales={this.props.sales}
            onTrashCanClick={this.handleDeleteSale}
            isLoading={this.props.isLoading}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sales: getIncompleteSales(state.sales),
    isLoading: getIsLoading(state.activeRequests),
    uniqueId: state.uniqueId,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const storefrontId = ownProps.match.params.storefrontId;

  return {
    fetchSales: () => dispatch(fetchSales(storefrontId)),
    startNewSale: () => dispatch(startNewSale(storefrontId)),
    deleteSale: saleNumber => dispatch(deleteSale(saleNumber)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteSales);
