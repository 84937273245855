import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pubWithSale, pubWithReturn, Event } from 'lib/events';
import {
  addCustomerToSale,
  addCustomerToReturnReceipt,
} from 'actions/asynchronous';
import {
  showNotification,
  startRequest,
  finishRequest,
} from 'actions/synchronous';
import { NotificationTypes } from 'reducers/notifications';
import SignUpForm from 'components/customers/SignUpForm';
import {
  getQueryParam,
  objectToQueryParams,
  apiUrl,
  retailApiUrl,
} from 'lib/url';
import { post } from 'lib/network';

class SignUpCard extends Component {
  saleNumber = getQueryParam('saleNumber');
  returnNumber = getQueryParam('returnNumber');
  rootPath = `/storefronts/${this.props.match.params.storefrontId}`;

  componentDidMount() {
    if (this.saleNumber) {
      pubWithSale(Event.SaleNewUserAuth.PAGE_VIEW);
    } else if (this.returnNumber) {
      pubWithReturn(Event.ReturnNewUserAuth.PAGE_VIEW);
    }
  }

  setNewCustomer = async customerId => {
    if (this.props.isAK) {
      this.props.onSetNewCustomer();

      // grant free express shipping
      post(apiUrl(`free_shipping_coupons`), {
        user_id: customerId,
        is_expedited: true,
      });
    }
  };

  handleCreateCustomer = async customer => {
    if (customer.email_optout === false) {
      if (this.saleNumber) {
        pubWithSale(Event.SaleNewUserAuth.OPT_IN);
      } else if (this.returnNumber) {
        pubWithReturn(Event.ReturnNewUserAuth.OPT_IN);
      }
    } else {
      if (this.saleNumber) {
        pubWithSale(Event.SaleNewUserAuth.OPT_OUT);
      } else if (this.returnNumber) {
        pubWithReturn(Event.ReturnNewUserAuth.OPT_OUT);
      }
    }

    var createdCustomer;
    const showCustomerCreationErorrMessage = () => {
      this.props.showNotification(
        NotificationTypes.ERROR,
        'An error occurred while creating this customer. Please try again.',
      );
    };

    try {
      this.props.startRequest();
      const { response, responseJson } = await post(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/storefront_users`,
        ),
        { user: customer },
      );

      if (!response.ok) {
        return showCustomerCreationErorrMessage();
      } else {
        createdCustomer = responseJson;
      }
    } catch (e) {
      return showCustomerCreationErorrMessage();
    } finally {
      this.props.finishRequest();
    }

    if (this.saleNumber) {
      await this.props.addCustomerToSale(customer.email, this.saleNumber);
      await this.setNewCustomer(createdCustomer.id);

      pubWithSale(Event.SaleNewUserAuth.SIGN_UP);

      this.props.history.push({
        pathname: `${this.rootPath}/customers/success`,
        search: objectToQueryParams({
          email: customer.email,
          saleNumber: this.saleNumber,
        }),
      });
    } else if (this.returnNumber) {
      await this.props.addCustomerToReturnReceipt(
        customer.email,
        this.returnNumber,
      );
      await this.setNewCustomer(createdCustomer.id);

      pubWithReturn(Event.ReturnNewUserAuth.SIGN_UP);

      this.props.history.push({
        pathname: `${this.rootPath}/customers/success`,
        search: objectToQueryParams({
          email: customer.email,
          returnNumber: this.returnNumber,
        }),
      });
    } else {
      this.props.history.push(`${this.rootPath}/customers/success`);
    }
  };

  handleBackClick = () => {
    if (this.saleNumber && getQueryParam('email')) {
      pubWithSale(Event.SaleNewUserAuth.BACK_TO_EMAIL_ENTRY);
      this.props.history.push({
        pathname: `${this.rootPath}/customers/availability`,
        search: objectToQueryParams({ saleNumber: this.saleNumber }),
      });
    } else if (this.saleNumber && !getQueryParam('email')) {
      pubWithSale(Event.SaleAuth.RETURN_TO_SALE);
      this.props.history.push({
        pathname: `${this.rootPath}/sales/${this.saleNumber}`,
        search: objectToQueryParams({ saleNumber: this.saleNumber }),
      });
    } else if (this.returnNumber) {
      pubWithReturn(Event.ReturnNewUserAuth.BACK_TO_EMAIL_ENTRY);
      this.props.history.push({
        pathname: `${this.rootPath}/customers/availability`,
        search: objectToQueryParams({ returnNumber: this.returnNumber }),
      });
    } else {
      this.props.history.push({
        pathname: `${this.rootPath}`,
      });
    }
  };

  render() {
    const encodedEmail = getQueryParam('email');

    let decodedEmail;
    try {
      decodedEmail = decodeURIComponent(window.atob(encodedEmail));
    } catch (e) {
      decodedEmail = encodedEmail;
    }

    return (
      <SignUpForm
        email={decodedEmail}
        onCreateCustomer={this.handleCreateCustomer}
        handleBackClick={this.handleBackClick}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { storefrontId } = ownProps.match.params;
  return {
    isMobile: state.ui.isMobile,
    isAK: storefrontId === '23',
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addCustomerToSale: (customerEmail, saleNumber) =>
      dispatch(addCustomerToSale(customerEmail, saleNumber)),
    addCustomerToReturnReceipt: (customerEmail, returnReceiptId) =>
      dispatch(addCustomerToReturnReceipt(customerEmail, returnReceiptId)),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showNotification: (key, message) =>
      dispatch(showNotification(key, message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCard);
